import React from 'react';
import { Text, Picker, TextInput, CheckBox, View, StyleSheet } from 'react-native';
import moment from 'moment';
import _ from 'lodash';
// import { Button } from 'react-native-elements';
// import logo from './logo.svg';
import './App.css';
// import QuestionList from './QuestionList';
import PkQuizRankingLive from './PkQuizRankingLive';
import PkQuizRankingList from './PkQuizRankingList';
import PkQuizPlayerJoinedList from './PkQuizPlayerJoinedList';
// import Header from './Header';

import QuizDataBrowsing from './QuizDataBrowsing';  //2020.06.09
import { TargetList } from './EventJoinedListBrowsing'; //2021.03.02

import firebase from 'firebase';
import "firebase/auth";
import 'firebase/firestore';    //2020.12.11

// import console = require('console');
// import QuestionItem from './QuestionItem';

import quizBg1 from './html/images/home.png';
import quizBg2 from './html/images/page-bg.png';

import quizJoinFooterLeft from './html/images/footer-left.png';
import quizJoinFooterRight from './html/images/footer-right.png';

import quizWinnerHeader from './html/images/header.png';
import quizWinnerLeft from './html/images/winner-left.png';
import quizWinnerRight from './html/images/ranking-right.png';


import emptyImage from './html/images/empty_transparent.png';
// import emptyImage from './html/images/empty_white.png';

// var htmlFrontPage = require('./html/Index.html');
// var htmlJoinedPage = require('./html/JoinPlayer.html');


import Button from '@material-ui/core/Button';
import EventJoinedListBrowsing from './EventJoinedListBrowsing';    //2021.01.05
import { Grid } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
// import { Hidden } from '@material-ui/core';
// import { lightGreen } from '@material-ui/core/colors';


let isTestPhase = false;
let quizSubjectArray = [];
let quizGradeArray = [];
let quizQSetArray = [];
// let quizQsCheckbox = [];
let checkBoxArray = [];
let totalQSet = "";
let isMaxQtyReached = false;
let isFuncDone = false;
let isLoadingDone = false;
let loadingCount = 0;

// let playerListArray = [];
let resultColumn1 = [4, 6, 8, 10, 12, 14];
let resultColumn2 = [5, 7, 9, 11, 13, 15];

//2021.03.02
let organizer = {
  DisplayName: '',
  Name: '',
  Identity: '',
};
let eventCode = '';

var dbLiveQuiz;
var dbCommon;
var dbQsLib;
var dbLogs;
var dbFireStore;
var dbQuizBank;

const StageMode = {
  Begin: 'begin',
  CreatingRoom: 'creatingRoom',
  RoomCreated: 'roomCreated',
  QuizStarted: 'quizStarted',
  QuizEnded: 'quizEnded',
  Admin: 'admin',
  Start: 'start',
  Html: 'html',
  HtmlJoinedPlayers: 'htmlJoin',
  HtmlRanking: 'htmlRanking',
  HtmlResult: 'htmlResult',

  AdminSelect: 'adminSelect',
  DataBrowsing: 'dataBrowsing',
  JoinedListBrowsing: 'joinedListBrowsing'  //2021.01.05
}
let stageStat = StageMode.Start;
// let stageStat = StageMode.JoinedListBrowsing    //2021.01.05 - testing only
// let stageStat = StageMode.DataBrowsing;

//2021.10.29
let GradeGroupPairs = [
  { Grade: '1', GroupId: 1, Name: 'Standard 1' },
  { Grade: '2', GroupId: 2, Name: 'Standard 2' },
  { Grade: '3', GroupId: 3, Name: 'Standard 3' },
  { Grade: '4', GroupId: 4, Name: 'Standard 4' },
  { Grade: '5', GroupId: 5, Name: 'Standard 5' },
  { Grade: '6', GroupId: 6, Name: 'Standard 6' },

  { Grade: 'A', GroupId: 7, Name: 'Group A' },
  { Grade: 'B', GroupId: 8, Name: 'Group B' },
  { Grade: 'C', GroupId: 9, Name: 'Group C' },
  { Grade: 'D', GroupId: 10, Name: 'Group D' },

  { Grade: 'F1', GroupId: 11, Name: 'Form 1' },
  { Grade: 'F2', GroupId: 12, Name: 'Form 2' },
  { Grade: 'F3', GroupId: 13, Name: 'Form 3' },
  { Grade: 'F4', GroupId: 14, Name: 'Form 4' },
  { Grade: 'F5', GroupId: 15, Name: 'Form 5' },
  { Grade: 'F6', GroupId: 16, Name: 'Form 6' },

  { Grade: 'F5_2020', GroupId: 18, Name: 'Form 5 (Batch 2020)' },

  { Grade: 'N2', GroupId: 25, Name: 'N2 (3-4 years old)' },
  { Grade: 'K1', GroupId: 26, Name: 'K1 (4-5 years old)' },
  { Grade: 'K2', GroupId: 27, Name: 'K2 (5-6 years old)' },
];

//2021.11.10
let OrganizerPairs = [
  { Id: 1, Identity: 'simeducationgroup', Label: 'SIM EDUCATION GROUP', ProfileFS: 'SIM' },
  { Id: 2, Identity: 'smartnewton', Label: 'Smart Newton', ProfileFS: 'SMARTNEWTON' },
  { Id: 3, Identity: 'thecolinslearningcentre', Label: "The Colin's Learning Centre", ProfileFS: 'COLIN' },
  { Id: 4, Identity: 'jiaxuetuitioncenter', Label: '佳学教育 JiaXue Tuition Center', ProfileFS: 'PTSMC' },
  { Id: 5, Identity: 'megaeducationcenter', Label: 'MEGA EDUCATION CENTER', ProfileFS: 'MEGA' },
  { Id: 6, Identity: 'littleoppy', Label: 'Little Oppy', ProfileFS: 'LITTLEOPPY' },
  { Id: 7, Identity: 'ikeyedutech', Label: 'iKEY TEAM', ProfileFS: 'IKEYTEAM' },
];

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      LastUpdateDate: '2023-02-13 10:55:00',   //'2022-01-05 14:55:00',    //'2021-08-16 17:55:00',    //'2021-07-06 18:45:00',   //'2021-04-21 18:15:00',
      isRedirect: false,
      redirectStage: StageMode.CreatingRoom,

      isAdminMode: false,
      adminCode: window.location.href.includes('localhost') ? '8271653' : '',
      playerQty: 0,
      isDataLoaded: false,
      stageModeText: 'fetching data',
      debugText: '',
      NumbersInput: '',
      qsQtySet: [],
      qsQtyDivide: 10,

      isPlayerListLoaded: false,
      RankingList: [],

      roomId: '_none',
      roomCode: '_none',
      // _isQuizCompleted: false,
      _isQuizEnded: false,
      _isQuizStarted: false,

      isSubjectPicked: false,
      isGradePicked: false,
      // quizSubjectArray: [],

      quizSubject: 0,
      quizGrade: 0,
      quizQSet: 0,
      quizQSetPrevious: 0,
      quizMaxQty: 0,
      quizQty: 0,
      quizQuestions: '_none',
      quizDurationForEach: 90,
      quizTotalDuration: 0,
      quizTotalDurationText: '',
      quizQsCheckbox: [],

      //2021.01.19
      quizRoomTitle: '',
      quizDate: '',

      countdownStartTime: 0,
      countdownEndTime: 0,
      countdownTimer: 0,
      countdownText: '00:00:00',

      todayDT: '',

      QuizStartDT: '',
      QuizEndDT: '',
      RoomState: '',
      RoomId: '',

      //2021.07.16
      QuizDateStart: '',
      QuizDateEnd: '',
      QuizTimeStart: '',
      QuizTimeEnd: '',
      toggleEditDateRange: false,
      QuizQuestionSetName: '',

      //2020.11.13
      isCustomQuizTime: false,
      toggleEndTimeInputUi: false,
      EndTimeInput: '',

      //2020.11.25
      toggleStartTimeInputUi: false,
      StartTimeInput: '',
      StartTimeInputUi_disabled: false,
      EndTimeInputUi_disabled: false,

      toggleCustomTotalDurationInputUi: false,
      CustomTotalDuration: 0,
      CustomTotalDurationInput_Min: 0,
      CustomTotalDurationInput_Hour: 0,
      CustomTotalDurationInput_Sec: 0,  //2020.12.31

      //2020.12.01
      toggleUseCustomQuizEndTime: false,


      //2020.11.20
      toggleQuizDateInputUi: false,
      QuizDateInput: moment().format('YYYY-MM-DD'),

      // //2020.11.20
      // toggleStartTimeInputUi: false,
      // StartTimeInput: '',

      //2020.11.24
      Btn_MakeRoom_Disabled: false,

      //2021.08.06
      HideEventListEntrance: true,

      //2021.08.13
      EventList: [],
      EventCode: '',
      EventName: '',
      EventOrganizer: '',
    };

    const firebaseConfig = {
      apiKey: "AIzaSyCrcClX5GAEmH4ro4tdrx22VCBs85kuNWw",
      authDomain: "ikeyedutech-student.firebaseapp.com",
      databaseURL: "https://ikeyedutech-student-5ea3c-pkquiz.firebaseio.com/",
      projectId: "ikeyedutech-student",
      storageBucket: "ikeyedutech-student.appspot.com",
      messagingSenderId: "838670185333",
      appId: "1:838670185333:web:794fd3a5dec95ada"
    };
    // firebase.initializeApp(firebaseConfig);

    // init
    const app1 = firebase.initializeApp(firebaseConfig);

    const firebaseConfig_Common = firebaseConfig;
    firebaseConfig_Common.databaseURL = "https://ikeyedutech-student.firebaseio.com/";
    const app2 = firebase.initializeApp(firebaseConfig_Common, 'app2');

    const firebaseConfig_Question = firebaseConfig;
    firebaseConfig_Question.databaseURL = "https://ikeyedutech-student-5ea3c-quizquestions.firebaseio.com/";
    const app3 = firebase.initializeApp(firebaseConfig_Question, 'app3');

    //2020.07.15
    const firebaseConfig_Logs = firebaseConfig;
    firebaseConfig_Logs.databaseURL = "https://ikeyedutech-student-a28c5-logs.firebaseio.com/";
    const app4 = firebase.initializeApp(firebaseConfig_Logs, 'app4');

    //2020.09.30
    const firebaseConfig_QuizBank_QuestionSet = firebaseConfig;
    firebaseConfig_QuizBank_QuestionSet.databaseURL = "https://ikey-quiz-bank-question-set.firebaseio.com/";
    const app5 = firebase.initializeApp(firebaseConfig_QuizBank_QuestionSet, 'app5');

    // Get the default database instance for app1
    dbLiveQuiz = firebase.database();

    // Get a database instance for app2, app3
    dbCommon = firebase.database(app2);
    dbQsLib = firebase.database(app3);

    //Logs DB   //2020.07.15
    dbLogs = firebase.database(app4);

    //FireStore   //2020.12.11
    dbFireStore = firebase.firestore(app1);

    //QuizBank - QuestionSet  //2021.09.30
    dbQuizBank = firebase.database(app5);
  }

  componentDidMount = async () => {

    return null;

    //2021.04.07
    //Check Last Update & todo Auto-reload webpage if needed.
    // let updateNeeded = await this.CheckLastUpdate();
    window.addEventListener("focus", this.CheckLastUpdate);
    // if (updateNeeded)
    //   return null;

    this.intervalID = setInterval(
      () => {
        if (this.state._isQuizStarted === true && this.state._isQuizEnded === false) {
          this.tick();
        }
        if (!isLoadingDone) {
          loadingCount += 1;
          if (loadingCount === 0) {
            this.setState({ stageModeText: 'fetching data' });
          }
          else if (loadingCount === 1) {
            this.setState({ stageModeText: 'fetching data.' });
          }
          else if (loadingCount === 2) {
            this.setState({ stageModeText: 'fetching data..' });
          }
          else if (loadingCount === 3) {
            this.setState({ stageModeText: 'fetching data...' });
            loadingCount = 0;
          }
        }
      },
      1000
    );
    // this.CheckTestPhase();
    // this.GetSubjectList();
    // this.GetGradeList();
    // // this.GetQSetList();
    // this.setState({
    //   stageModeText: 'ready'
    // })

    //forcing synchronous execution.
    // this.CheckTestPhase(null,
    //   function () {
    //     this.GetSubjectList(null,
    //       function () {
    //         this.GetGradeList(null,
    //           function () {
    //             this.setState({
    //               stageModeText: 'ready'
    //             });
    //           }
    //         );
    //       }
    //     );
    //   }
    // );

    // this.CheckTestPhase().then(()=>{
    //   this.GetSubjectList().then(()=>{
    //     this.GetGradeList().then(()=>{
    //       this.setState({
    //         stageModeText: 'ready'
    //       });
    //     });
    //   });
    // });

    this.setState({
      todayDT: moment(new Date().getTime()).format('YYYYMMDD')
    });

    //forcing synchronous execution.
    // isFuncDone = false;
    // this.waitUntil(this.checkIsFuncDone(), this.CheckTestPhase(), 100, 500,
    //   () => {
    //     isFuncDone = false;
    //     this.waitUntil(this.checkIsFuncDone(), this.GetSubjectList(), 100, 2000,
    //       () => {
    //         isFuncDone = false;
    //         this.waitUntil(this.checkIsFuncDone(), this.GetGradeList(), 100, 2000,
    //           () => {
    //             isFuncDone = false;
    //             isLoadingDone = true;
    //             this.setState({
    //               stageModeText: 'ready',
    //               isDataLoaded: true,
    //             }, () => {
    //               if (this.state.isRedirect) {
    //                 setTimeout(() => {
    //                   this.SetStage(this.state.redirectStage);
    //                 }, 1000);
    //               }
    //             });
    //             // this.SetStage(StageMode.DataBrowsing);    //temp 2020.06.09
    //           })
    //       })
    //   });

    //2020.12.28
    // await this.CheckTestPhase()
    await this.GetSubjectList();
    await this.GetGradeList();
    await this.LoadAllEvents();   //2021.08.13
    isLoadingDone = true;
    this.setState({
      stageModeText: 'ready',
      isDataLoaded: true,
    }, () => {

      //2021.01.29
      let redirectRequired = sessionStorage.getItem('redirectRequired');
      if (redirectRequired !== null) {
        sessionStorage.removeItem('redirectRequired');
        this.SetStage(redirectRequired);
      }

      if (this.state.isRedirect) {
        setTimeout(() => {
          this.SetStage(this.state.redirectStage);
        }, 1000);
      }
    });
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalID);
    this.DisableNodeListening_QuizState();
  }

  LoadAllEvents = async () => {
    let eventList = [];
    await dbFireStore
      .collection("LiveQuiz_UpcomingEvents")
      .get()
      .then((querySnapshot) => {
        let data = [];
        if (querySnapshot !== null) {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
        }
        if (data.length > 0) {
          eventList = data;
          // console.log(JSON.stringify(data));
        }
      });
    this.setState({
      EventList: eventList,
    });
  }

  //2021.04.07
  CheckLastUpdate = async () => {

    let _last_LU_Check = localStorage.getItem('iKEY_Quiz_Admin_Build_Last_LU_Check', '2020-11-30 00:00:00');
    let _last_LU_Check_DT = null;
    console.log('Last Build Check = ' + _last_LU_Check);

    let proceedToCheckLastUpdate = true;

    //only check for new build if current time is X minutes over than last check time.
    // _last_LU_Check_DT = moment(_last_LU_Check).add(1, 'minutes');
    _last_LU_Check_DT = moment(_last_LU_Check, 'YYYY-MM-DD HH:mm:ss').add(10, 'seconds');

    if (_last_LU_Check_DT !== null)
      if (moment() < _last_LU_Check_DT)
        proceedToCheckLastUpdate = false;

    // console.log('Next Build Check = ' + _last_LU_Check_DT.format('YYYY-MM-DD HH:mm:ss'));

    if (proceedToCheckLastUpdate) {

      let _lastUpdate_fetched = '';

      await dbCommon.ref('Version/LiveQuiz/Web/Admin/LastUpdate')
        .once('value', snapshot => {
          if (snapshot.exists()) {
            _lastUpdate_fetched = String(snapshot.val());
          }
        });

      if (_lastUpdate_fetched.length > 0) {
        //Auto-Reload the webpage if found WebApp's LU is older than fetched LU.
        if (moment(this.state.LastUpdateDate, 'YYYY-MM-DD HH:mm:ss') < moment(_lastUpdate_fetched, 'YYYY-MM-DD HH:mm:ss')) {
          let confirmReload = window.confirm('A New Build is available, advice user to click OK to reload this page now.\n* New Build last updated on ' + _lastUpdate_fetched);
          if (confirmReload) {
            if (window.location.href.includes('localhost'))
              window.location.reload();
            else
              window.location.href = "https://quiz.ikeyedutech.com.my/";
          }
        }
      }

      localStorage.setItem("iKEY_Quiz_Admin_Build_Last_LU_Check", moment().format('YYYY-MM-DD HH:mm:ss'));
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  checkIsFuncDone() {
    return isFuncDone;
  }
  /*
    funcCond : function to call to check whether a condition is true
    readyAction : function to call when the condition was true
    checkInterval : interval to poll <optional>
    timeout : timeout until the setTimeout should stop polling (not 100% accurate. It was accurate enough for my code, but if you need exact milliseconds, please refrain from using Date <optional>
    timeoutfunc : function to call on timeout <optional>
  */
  waitUntil(funcCond, readyAction, checkInterval, timeout, timeoutfunc) {
    if (checkInterval === null) {
      checkInterval = 100; // checkinterval of 100ms by default
    }
    var start = +new Date(); // use the + to convert it to a number immediatly
    if (timeout === null) {
      timeout = Number.POSITIVE_INFINITY; // no timeout by default
    }
    var checkFunc = function () {
      var end = +new Date(); // rough timeout estimations by default

      if (end - start > timeout) {
        if (timeoutfunc) { // if timeout function was defined
          timeoutfunc(); // call timeout function
        }
      } else {
        if (funcCond) { // if condition was met
          readyAction(); // perform ready action function
        } else {
          setTimeout(checkFunc, checkInterval); // else re-iterate
        }
      }
    };
    checkFunc(); // start check function initially
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  tick() {
    this.setState({
      countdownTimer: this.state.countdownTimer + 1
    }, () => {
      //update ui
      var minutes = ((this.state.quizTotalDuration - this.state.countdownTimer) / 60).toFixed(3).split('.')[0];
      var seconds = ((this.state.quizTotalDuration - this.state.countdownTimer) % 60).toFixed(0);
      this.setState({
        //countdownText: moment(this.state.countdownEndTime - this.state.countdownTimer).format('mm:ss')
        countdownText:
          (minutes.length < 2 ? '0' + minutes : minutes) + ' : '
          + (seconds.length < 2 ? '0' + seconds : seconds),
      }, () => {

        // if (Date.now() > (new Date(this.state.QuizEndDT)).getTime()) {
        //   window.alert("TimeOut " + Date.now() + " / " + this.state.QuizEndDT);
        // }


        // window.alert(
        //   "TimeOut " + Date.now().toString() 
        //   + " / " + this.state.QuizEndDT.toString() 
        //   + " / " + this.state.QuizStartDT.toString() 
        //   + " / " + this.state.RoomState.toString() 
        //   + " / " + this.state.countdownTimer.toString() 
        // );


        // if (
        //   // (
        //   //   ( Date.now() > (new Date(this.state.QuizEndDT)) ) ||
        //   //   ((this.state.quizTotalDuration - this.state.countdownTimer) < 0)
        //   // ) 
        //   (
        //     ( Date.now() > (new Date(this.state.QuizEndDT)).getTime())
        //   ) 
        //   && !this.state._isQuizEnded
        //  )
        // let result = Date.now() > (new Date(this.state.QuizEndDT)).getTime();
        // window.alert("Tick : " + result.toString());

        // let result = (this.state.quizTotalDuration - this.state.countdownTimer) < 0;
        // window.alert("Tick : " + result.toString());

        // let qEndDT = (new Date(this.state.QuizEndDT)).getTime();
        // let qNowDR = Date.now();
        let qEndDT = moment(this.state.QuizEndDT);
        let qNowDR = moment();
        let remainSec = this.state.quizTotalDuration - this.state.countdownTimer;
        let cdTimer = this.state.countdownTimer;

        let _debugText =
          "Timer : End (" + qEndDT.format('h:mm:ss a')
          + ") / Now (" + qNowDR.format('h:mm:ss a')
          + ") / CD (" + cdTimer.toFixed(0)
          + ") / Left (" + remainSec.toFixed(0)
          // + ") / Diff (" + ((qEndDT - qNowDR) / 1000)
          + ") "
          // + ") / QuizTotalDuration (" + this.state.quizTotalDuration.toString() + ")"
          ;
        // window.alert(_debugText);
        this.setState({
          debugText: _debugText
        });

        // window.alert(this.state.QuizEndDT);
        // window.alert(_debugText);

        // if (this.state.QuizEndDT == null) {
        //   this.FB_Get_PkLive();          
        // }

        // let _elapsedSeconds = this.state.quizTotalDuration - remainSec;

        if (
          (
            (qNowDR > qEndDT)
            // ||
            // ((this.state.quizTotalDuration - this.state.countdownTimer) < 0)
            // (this.state.countdownTimer >= this.state.quizTotalDuration)
            ||
            (remainSec <= 0)
          )
          && !this.state._isQuizEnded
        ) {
          this.setState({
            // _isQuizEnded: true,
            countdownText: 'Time Out',
          }, () => {
            if (stageStat === StageMode.QuizStarted) {
              setTimeout(this.StopQuiz(), 1500);
            }
          })
        }
      });
    });
  }

  CheckTestPhase() {
    // firebase.database().ref('_TestPhase').once('value')
    //   .then(function (dataSnapshot) {
    //     // handle read data.
    //     var phase = dataSnapshot.val().toString();
    //     if (phase === 'true') {
    //       isTestPhase = true;
    //     }
    //     else if (phase === 'false') {
    //       isTestPhase = false;
    //     }
    //   }, () => {
    //     isFuncDone = true;
    //   });
  }

  SetStage(_stageMode) {
    stageStat = _stageMode;
    // if (_stageMode === StageMode.Begin) {
    //   this.setState({ stageModeText: 'ready' });
    // }
    // else {
    //   // this.setState({ stageModeText: "StageMode : " + _stageMode });
    //   this.setState({ stageModeText: 'ready' });
    // }
    this.SetStageModeText('ready');
    window.scrollTo(0, 0);
  }

  //2021.01.29
  SetStageModeText = (_text) => {
    this.setState({ stageModeText: _text });
  }

  CreateRoom() {
    this.SetStage(StageMode.CreatingRoom);

    var timeTicks = new Date().getTime().toString();
    this.setState({ roomId: timeTicks });

    //Get Info from Firebase
    // this.GetSubjectList();

    //Display Available Settings

  }

  async GetSubjectList() {
    await firebase.database().ref('pkquiz-questions/_Subjects').once('value')
      .then(function (dataSnapshot) {
        // handle read data.
        var subjects = dataSnapshot.val().toString();
        var subjectArray = subjects.split(';');
        quizSubjectArray = [];
        quizSubjectArray.push('Select Subject');
        subjectArray.map((data) => {
          if (data.length > 0) {
            quizSubjectArray.push(data);
          }
          return null;
        })
      }, () => {
        isFuncDone = true;
      });
  }

  SetPickedSubject(_subject) {
    this.setState({
      quizSubject: _subject,
      isSubjectPicked: true,

      //2020.12.28
      quizGrade: 0,
      quizQSet: [],
      quizQSetPrevious: 0,
      quizMaxQty: 0,
      qsQtySet: [],
      // quizQSetArray: [],
      isGradePicked: false,
      QuizQuestionSetName: '',
    }, () => {
      this.CheckPickState();
    });
  }

  async GetGradeList() {
    await firebase.database().ref('pkquiz-questions/_Grade').once('value')
      .then(function (dataSnapshot) {
        // handle read data.
        var grades = dataSnapshot.val().toString();
        var gradeArray = grades.split(';');
        quizGradeArray = [];
        quizGradeArray.push('Select Grade');
        gradeArray.map((data) => {
          if (data.length > 0) {
            quizGradeArray.push(data);
          }
          return null;
        })
      }, () => {
        isFuncDone = true;
      });
  }

  SetPickedGrade(_grade) {
    console.log('Grade (key) = ' + _grade);
    let pickedGrade = String(quizGradeArray[_grade]).toLowerCase().replace('standard', '').replace(' ', '');
    console.log('Picked Grade = ' + pickedGrade);

    this.setState({
      quizGrade: _grade,
      isGradePicked: true,

      //2020.12.28
      quizQSet: [],
      quizQSetPrevious: 0,
      quizMaxQty: 0,
      qsQtySet: [],
      // quizQSetArray: [],
      QuizQuestionSetName: '',

      //2021.08.13
      EventCode: '',
      EventName: '',
      EventOrganizer: '',
    }, async () => {
      await this.CheckPickState();
    });
  }

  async CheckPickState() {
    if (this.state.isSubjectPicked === true
      && this.state.isGradePicked === true) {
      // alert("CheckPickState");
      await this.GetQSetList();
    }
    // alert(this.state.quizGrade);
  }

  async GetQSetList() {
    // var _quizQSetArray = [];
    var loc = 'pkquiz-questions/' + this.GetSubject() + '/' + quizGradeArray[this.state.quizGrade] + '/TotalSet';
    await firebase.database().ref(loc).once('value')
      .then(function (dataSnapshot) {
        // handle read data.
        totalQSet = parseInt(dataSnapshot.val().toString(), 10);
        // totalQSet = dataSnapshot.val().toString();
        quizQSetArray = [];
        quizQSetArray.push('Select Question Set');
        for (var i = 0; i < totalQSet; i++) {
          quizQSetArray.push(i + 1);
        }
      })
      .catch(error => {
        this.setState({
          // debugText: "TotalQSet - Data Not Found.", //error.toString()

          //2020.12.28
          quizQSet: 0,
          isGradePicked: false,
        });
        quizQSetArray = [];
        // alert("TotalQSet - Data Not Found.");
      })
      .then(() => {
        this.setState({
          quizQSet: this.state.quizQSet,
          // quizQSetArray: _quizQSetArray,
          isGradePicked: true,
          debugText: 'quizQSet : ' + totalQSet.toString()
        })
      })
      .then(async () => {
        // alert(_quizQSetArray.length);
        // alert(JSON.stringify(_quizQSetArray));
        await this.GetQSetTotalQnQty();
      });
  }

  async GetQSetTotalQnQty() {
    //
    var loc = 'pkquiz-questions/' + this.GetSubject()
      + '/' + quizGradeArray[this.state.quizGrade]
      + '/' + quizQSetArray[this.state.quizQSet] + '/TotalQuestion';
    await firebase.database().ref(loc).once('value')
      .then(function (dataSnapshot) {
        // handle read data.
        totalQSet = parseInt(dataSnapshot.val().toString(), 10);
        // totalQSet = dataSnapshot.val().toString();
      })
      .catch(error => {
        this.setState({
          debugText: "TotalQnQty - Data Not Found." //error.toString()
        })
      })
      .then(() => {
        this.setState({
          quizMaxQty: totalQSet,
          // debugText: 'quizMaxQty : ' + totalQSet

          //2021.08.13
          // EventCode: '',
          // EventName: '',
          // EventOrganizer: '',
        })
      })
      .then(() => {
        this.DrawSetQuizQtySlicesUi();
      });
  }

  SetQuizQty = (_qty) => {
    // var qty = parseInt(_qty, 10);

    //2020.12.31
    let isNumber = (/^-?\d+\.?\d*$/).test(_qty);
    let qty = isNumber ? parseInt(_qty) : 0;

    if (qty > this.state.quizMaxQty) {
      qty = this.state.quizMaxQty;
    }
    else if (qty < 0) {
      qty = 0;
    }
    isMaxQtyReached = false;
    this.CountQuizDuration(qty);
  }

  SetQuizDurationForEach(_durationForEach) {

    //2020.12.31
    let isNumber = (/^-?\d+\.?\d*$/).test(_durationForEach);
    let duration = isNumber ? parseInt(_durationForEach) : 90;

    this.setState({
      // quizDurationForEach: parseInt(_durationForEach, 10)
      quizDurationForEach: duration,
    }, () => {
      this.CountQuizDuration(this.state.quizQty);
    });
  }

  CountQuizDuration(_quizQty) {
    this.setState({
      quizQty: _quizQty,
      // quizTotalDuration: (_quizQty * this.state.quizDurationForEach),

      //2020.11.25
      quizTotalDuration: this.state.toggleCustomTotalDurationInputUi ? this.state.CustomTotalDuration : (_quizQty * this.state.quizDurationForEach),
    }, () => {
      this.setState({
        quizTotalDurationText: this.state.quizTotalDuration + ' s (' + this.GetDurationInText(this.state.quizTotalDuration) + ')', //', added extra 10s)',
      }, () => {
        this.ValidateQuestionCheckbox();

        //2020.11.25
        this.RefreshEndTimeInput();
      })
    });
  }

  GetDurationInText(_totalSeconds) {
    var minutes = (_totalSeconds / 60).toFixed(3).split('.')[0];
    var seconds = (_totalSeconds % 60).toString();
    return minutes + ' min ' + seconds + ' sec';
  }

  SetQuestionCheckbox(_value) {
    let _quizQsCheckbox = this.state.quizQsCheckbox;
    if (isMaxQtyReached || _quizQsCheckbox[_value]) {
      // this.state.quizQsCheckbox[_value] = false;  //!this.state.quizQsCheckbox[_value];   
      _quizQsCheckbox[_value] = false;
    }
    else {
      // this.state.quizQsCheckbox[_value] = true;
      _quizQsCheckbox[_value] = true;
    }
    this.setState({
      // quizQsCheckbox: this.state.quizQsCheckbox
      quizQsCheckbox: _quizQsCheckbox,
    }, () => {
      this.ValidateQuestionCheckbox();
    });
  }

  ValidateQuestionCheckbox() {
    var quizQuestionsText = '';
    var maxQty = 0;
    isMaxQtyReached = false;
    var i = 0;

    let _quizQsCheckbox = this.state.quizQsCheckbox;

    for (i = 0; i < this.state.quizMaxQty; i++) {
      if (this.state.quizQsCheckbox[i] === true && maxQty < this.state.quizQty) {
        quizQuestionsText += (i + 1).toString() + ';';
        maxQty += 1;
        if (maxQty >= this.state.quizQty) {
          isMaxQtyReached = true;
        }
      }
    }

    if (isMaxQtyReached) {
      maxQty = 0;
      quizQuestionsText = '';
      for (i = 0; i < this.state.quizMaxQty; i++) {
        if (this.state.quizQsCheckbox[i] === true && maxQty < this.state.quizQty) {
          quizQuestionsText += (i + 1).toString() + ';';
          maxQty += 1;
        }
        else {
          // this.state.quizQsCheckbox[i] = false;
          _quizQsCheckbox[i] = false;
        }
      }
    }

    this.setState({
      quizQsCheckbox: _quizQsCheckbox,
      quizQuestions: quizQuestionsText,
      debugText: 'Quiz : ' + quizQuestionsText
    }, () => {
      this.GenerateQuestionCheckbox();
    });
  }

  CheckBoxState(_key) {
    var isDisable = false;
    if (isMaxQtyReached && !this.state.quizQsCheckbox[_key]) {
      isDisable = true;
    }
    return isDisable;
  }

  GenerateQuestionCheckbox() {
    checkBoxArray = [];
    for (var i = 0; i < this.state.quizMaxQty; i++) {
      checkBoxArray.push(false);
      if (this.state.quizQsCheckbox[i] !== null) {
        checkBoxArray[i] = this.state.quizQsCheckbox[i];
      }
    }
    return (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        {
          checkBoxArray.map((data, key) =>
            <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', width: 45 }}>
              <CheckBox
                style={{ flex: 1, }}
                value={this.state.quizQsCheckbox[key]} onValueChange={() => this.SetQuestionCheckbox(key)}
                disabled={this.CheckBoxState(key)}
              />
              <Text style={{ flex: 2, color: '#fff', fontWeight: 'bold' }}> {key + 1}</Text>
            </View>
          )
        }
      </Grid>
    );
  }

  PickFirstNumbers(_qty) {
    let _quizQsCheckbox = this.state.quizQsCheckbox;
    for (var i = 0; i < this.state.quizMaxQty; i++) {
      if (i < _qty) {
        // this.state.quizQsCheckbox[i] = true;
        _quizQsCheckbox[i] = true;
      }
      else {
        // this.state.quizQsCheckbox[i] = false;
        _quizQsCheckbox[i] = false;
      }
    }
    this.setState({
      // quizQsCheckbox: this.state.quizQsCheckbox
      quizQsCheckbox: _quizQsCheckbox,
    }, () => {
      this.ValidateQuestionCheckbox();
    });
  }

  RandomPickNumbers() {
    var maxQty = 0;
    var randomNumber = 0;

    let _quizQsCheckbox = this.state.quizQsCheckbox;

    for (var i = 0; i < this.state.quizMaxQty; i++) {
      // this.state.quizQsCheckbox[i] = false;
      _quizQsCheckbox[i] = false;
    }

    //special iKey icon question.
    if (
      (
        (quizSubjectArray[this.state.quizSubject] === "Colin's BM")   //2021.01.19
        || (quizSubjectArray[this.state.quizSubject] === "Science & Mathematics")
        || (quizSubjectArray[this.state.quizSubject] === "Mathematics")
      ) && (quizGradeArray[this.state.quizGrade] === "U")
    ) {
      // this.state.quizQsCheckbox[this.state.quizMaxQty - 1] = true;
      _quizQsCheckbox[this.state.quizMaxQty - 1] = true;
      maxQty = 1;
      // this.setState({
      //   quizQsCheckbox: this.state.quizQsCheckbox,
      // });
    }

    do {
      randomNumber = this.getRandomIntegerBetweenRange(0, this.state.quizMaxQty);  //(Math.random() * this.state.quizQty);
      if (this.state.quizQsCheckbox[randomNumber] === false) {
        // this.state.quizQsCheckbox[randomNumber] = true;
        _quizQsCheckbox[randomNumber] = true;
        maxQty += 1;
      }
    } while (maxQty < this.state.quizQty);

    this.setState({
      // quizQsCheckbox: this.state.quizQsCheckbox
      quizQsCheckbox: _quizQsCheckbox,
    }, () => {
      this.ValidateQuestionCheckbox();
    });
  }

  UnselectAll() {
    let _quizQsCheckbox = this.state.quizQsCheckbox;
    for (var i = 0; i < this.state.quizMaxQty; i++) {
      // this.state.quizQsCheckbox[i] = false;
      _quizQsCheckbox[i] = false;
    }
    this.setState({
      // quizQsCheckbox: this.state.quizQsCheckbox
      quizQsCheckbox: _quizQsCheckbox,
    }, () => {
      this.ValidateQuestionCheckbox();
    });
  }

  getRandomIntegerBetweenRange(min, max) {
    var x = parseInt(((Math.random() * ((max - min) + 1)) + min), 10);
    return x;
  }

  GetSubject() {
    var _subject = String(quizSubjectArray[this.state.quizSubject]);
    if (_subject.includes(" & ")) {
      _subject = _subject.replace(" & ", "");
    }
    else if (_subject === "Colin's BM") {    //2021.01.19
      _subject = "SEC_BM_COLIN";
    }
    return _subject;
  }

  //2021.07.19
  Delay = ms => new Promise(res => setTimeout(res, ms));

  MakeRoom = async () => {
    var isPass = true;
    var alertText = '';

    if (this.state.quizQty <= 0) {
      isPass = false
      alertText += '【Total Questions】is not set.\n';
    }
    if (this.state.quizDurationForEach <= 0) {
      isPass = false
      alertText += '【Duration per Question】is not set.\n';
    }
    if (!isMaxQtyReached) {
      isPass = false
      alertText += '【Questions】selected are not enough.\n';
    }

    if (!isPass) {
      window.confirm(`${'Unable to Make Room'}\n\n${alertText}`);
    }
    else {
      let _duration = this.state.quizTotalDuration;
      if (this.state.toggleCustomTotalDurationInputUi) {
        if (this.state.CustomTotalDuration !== _duration) {
          _duration = this.state.CustomTotalDuration;
        }
      }

      //2020.12.31
      let _durationForEach = this.state.toggleCustomTotalDurationInputUi ?
        Number((this.state.quizTotalDuration / this.state.quizQty).toFixed(2))
        :
        this.state.quizDurationForEach;


      this.setState({
        quizTotalDuration: _duration,
        quizDurationForEach: _durationForEach,

        //2021.07.19
        QuizDateStart: this.state.QuizDateStart !== '' ? this.state.QuizDateStart : this.state.QuizDateInput,
        QuizDateEnd: this.state.QuizDateEnd !== '' ? this.state.QuizDateEnd : this.state.QuizDateInput,
        // QuizTimeStart: this.state.QuizStartDT === '' || this.state.QuizStartDT === '_none' ? '00:00:00'
        //   : moment(this.state.QuizStartDT).format('HH:mm:ss'),
        // QuizTimeEnd: this.state.QuizEndDT === '' || this.state.QuizEndDT === '_none' ? '23:59:00'
        //   : moment(this.state.QuizEndDT).format('HH:mm:ss'),
        QuizTimeStart: this.state.StartTimeInput === '' || !this.state.StartTimeInput.includes(':') ? '00:00:00'
          : this.state.StartTimeInput + ':00',
        QuizTimeEnd: this.state.EndTimeInput === '' || !this.state.EndTimeInput.includes(':') ? '23:59:00'
          : this.state.EndTimeInput + ':00',
      });
      await this.Delay(500);
      // }, async () => {

      //2021.04.06
      this.GetOrganizer();

      //Update PK-Room-Detail
      await this.FB_Update_PkRoomDetail(
        this.state.quizTotalDuration, this.state.quizDurationForEach, quizGradeArray[this.state.quizGrade], this.state.quizQty, this.state.quizQSet, this.GetSubject(), this.state.quizQuestions,
        // this.state.QuizDateStart !== '' ? this.state.QuizDateStart : this.state.QuizDateInput,
        // this.state.QuizDateEnd !== '' ? this.state.QuizDateEnd : this.state.QuizDateInput,
        // this.state.QuizStartDT !== '' ? this.state.QuizStartDT === '_none' ? this.state.QuizDateInput + ' 00:00:00' : moment(this.state.QuizStartDT).format('hh:mm A') : '00:00:00',
        // this.state.QuizEndDT !== '' ? this.state.QuizEndDT === '_none' ? this.state.QuizDateInput + ' 23:59:00' : moment(this.state.QuizEndDT).format('hh:mm A') : '23:59:00'
        // this.state.QuizStartDT === '' || this.state.QuizStartDT === '_none' ? '00:00:00' : moment(this.state.QuizStartDT).format('HH:mm:ss'),
        // this.state.QuizEndDT === '' || this.state.QuizEndDT === '_none' ? '23:59:00' : moment(this.state.QuizEndDT).format('HH:mm:ss')
        // this.state.QuizDateStart,
        // this.state.QuizDateEnd,
        // this.state.QuizTimeStart,
        // this.state.QuizTimeEnd
      );


      // //2020.11.24
      // let _quizDate =
      //   this.state.toggleQuizDateInputUi ?
      //     this.state.QuizDateInput
      //     :
      //     moment().format('YYYY-MM-DD')
      //   ;

      // //2021.07.16
      // let _quizDate = this.state.QuizDateInput;

      // //new 2020.11.25
      // let _startTime =
      //   this.state.isCustomQuizTime ?
      //     this.state.toggleStartTimeInputUi ?
      //       _quizDate + ' ' + this.state.StartTimeInput + ':00'
      //       : '_none'
      //     : '_none';

      // //new 2020.11.13
      // let _endTime =
      //   this.state.isCustomQuizTime ?
      //     this.state.toggleEndTimeInputUi ?
      //       // this.state.toggleUseCustomQuizEndTime ?  //2020.12.01  //2020.12.16 - disabled
      //       //   '_none'
      //       //   :
      //       _quizDate + ' ' + this.state.EndTimeInput + ':00'
      //       : '_none'
      //     : '_none';


      // //2020.12.31
      // let _endTime = _quizDate + ' ' + this.state.EndTimeInput + ':00';
      // _endTime = this.state.isCustomQuizTime && !this.state.toggleEndTimeInputUi ? '_none' : _endTime;
      // // _endTime = this.state.toggleEndTimeInputUi ? _endTime : '_none';

      // //testing
      // alert("Start : " + _startTime + "\nEnd : " + _endTime);
      // this.setState({
      //   Btn_MakeRoom_Disabled: false
      // });
      // return false;


      // //2021.03.06 - default to infinite time if found bad conversion (Invalid Date) in time format.
      // _startTime = _startTime.toLowerCase().includes('invalid') ? '_none' : _startTime;
      // _endTime = _endTime.toLowerCase().includes('invalid') ? '_none' : _endTime;

      //2021.04.17 - "_none" unstable on time checking on quiz side, so force it with timestamp.
      // _startTime = _startTime === '_none' ? moment(_quizDate + ' 00:00:00').format('YYYY-MM-DD HH:mm:ss') : _startTime;
      // _endTime = _endTime === '_none' ? moment(_quizDate + ' 23:59:00').format('YYYY-MM-DD HH:mm:ss') : _endTime;


      //2021.07.19
      let _startTime = this.state.QuizDateStart + ' ' + this.state.QuizTimeStart;
      let _endTime = this.state.QuizDateEnd + ' ' + this.state.QuizTimeEnd;


      // //2021.07.16
      // this.setState({
      //   QuizStartDT: _startTime,
      //   QuizEndDT: _endTime,
      //   // QuizDateStart: '',
      //   // QuizDateEnd: '',
      // });

      //Update PK-Live
      await this.FB_Update_PkLive(
        _startTime, _endTime, this.state.roomId, (this.state.isCustomQuizTime ? 'started' : 'init')
      );
      // this.FB_Update_PkLive(
      //   '_none', '_none', this.state.roomId, 'init' //this.state._isQuizEnded, this.state._isQuizStarted
      // );

      // this.setState({
      //   QuizStartDT: _startTime,
      //   QuizEndDT: _endTime,
      // });

      //Generate Room Id
      await this.FB_Generate_RoomCode(this.state.roomId);

      // //Save Room for LiveView
      // this.FB_SaveRoom_ForLiveView();

      // this.SetStage(StageMode.RoomCreated);


      //edited on 2020.11.24
      if (this.state.isCustomQuizTime) {
        alert("\n"
          + "Room < " + this.state.roomCode + " > has been created.\n\n"
          + "Subject: " + quizSubjectArray[this.state.quizSubject] + "\n"
          + "Grade: " + quizGradeArray[this.state.quizGrade] + "\n"
          + "Set: " + this.state.quizQSet + "\n"
          + "Qty: " + this.state.quizQty + "\n"
          + "Duration: " + this.state.quizTotalDurationText + "\n"
        );




        //2021.04.06 === fixing Copy/Paste feature on browser, where right now permission is needed to success === start
        const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
        const permissionStatus = await navigator.permissions.query(queryOpts);
        // Will be 'granted', 'denied' or 'prompt':
        // console.log(permissionStatus.state);

        if (navigator.clipboard !== undefined) {//Chrome
          await navigator.clipboard.writeText(this.state.roomCode).then(function () {
            console.log('Room Code: Copying to clipboard was successful!');
          }, function (err) {
            console.error('Room Code: Could not copy text: ', err);
          });
        }
        else if (window.clipboardData) { // Internet Explorer
          window.clipboardData.setData("Text", this.state.roomCode);
        }
        //2021.04.06 === end

        // navigator.clipboard.writeText(this.state.roomCode);
        alert("Room Code < " + this.state.roomCode + " > copied to clipboard.\n\nRemember to use CTRL + V to paste your room code.");

        this.setState({

          roomCode: '',
          roomId: '',
          quizSubject: 0,
          quizGrade: 0,
          quizQSet: 0,
          quizQSetPrevious: 0,
          quizMaxQty: 0,
          quizQty: 0,
          quizQuestions: '_none',
          quizDurationForEach: 90,
          quizTotalDuration: 0,
          quizTotalDurationText: '',
          quizQsCheckbox: [],

          //2021.01.19
          quizRoomTitle: '',
          quizDate: '',

          toggleCustomTotalDurationInputUi: false,
          CustomTotalDuration: 0,
          CustomTotalDurationInput_Min: 0,
          CustomTotalDurationInput_Hour: 0,
          CustomTotalDurationInput_Sec: 0,  //2020.12.31

          isCustomQuizTime: false,

          toggleEndTimeInputUi: false,
          EndTimeInput: '',
          EndTimeInputUi_disabled: false,
          toggleUseCustomQuizEndTime: false,   //2020.12.02

          toggleStartTimeInputUi: false,
          StartTimeInput: '',
          StartTimeInputUi_disabled: false,

          toggleQuizDateInputUi: false,
          QuizDateInput: moment().format('YYYY-MM-DD'),

          Btn_MakeRoom_Disabled: false,

          //2021.07.16
          QuizDateStart: '',
          QuizDateEnd: '',
          QuizTimeStart: '',
          QuizTimeEnd: '',
          toggleEditDateRange: false,
          QuizQuestionSetName: '',

        }, () => {
          this.SetStage(StageMode.Begin);
        });

      }
      else {
        this.SetStage(StageMode.RoomCreated);
      }
      // });
    }
  }

  GetTestPhasePath() {
    // return (isTestPhase ? 'pkquiz/' + this.state.todayDT + '/testPhase/' : 'pkquiz/' + this.state.todayDT + '/');

    // //2020.11.20
    // if (this.state.toggleQuizDateInputUi && moment(this.state.QuizDateInput) > moment()) {
    //   return (
    //     isTestPhase ?
    //       'pkquiz/' + this.state.QuizDateInput.replace(new RegExp('-', 'g'), '') + '/testPhase/'
    //       : 'pkquiz/' + this.state.QuizDateInput.replace(new RegExp('-', 'g'), '') + '/'
    //   );
    // }
    // else {
    //   return (isTestPhase ? 'pkquiz/' + this.state.todayDT + '/testPhase/' : 'pkquiz/' + this.state.todayDT + '/');
    // }

    //2021.07.16
    return (
      isTestPhase ?
        'pkquiz/' + this.state.QuizDateInput.replace(new RegExp('-', 'g'), '') + '/testPhase/'
        : 'pkquiz/' + this.state.QuizDateInput.replace(new RegExp('-', 'g'), '') + '/'
    );
  }

  FB_Get_PkRoomDetail() {
    let roomDetails = [];
    let loc = this.GetTestPhasePath() + 'pkquiz-room-detail/' + this.state.roomId;
    // window.confirm(`${loc}`);
    firebase.database().ref(loc)
      .once('value').then(function (dataSnapshot) {
        let roomValues = _(dataSnapshot.val()).values().value();

        roomValues.map((data, key) => {
          roomDetails.push(roomValues[key]);  //roomValues[key]
          return null;
        });
      })
      .catch(error => {
        window.alert('Get Operation Invalid.\n\n' + error);
      }).then(() => {
        this.setState({
          quizTotalDuration: roomDetails[0],
          quizDurationForEach: roomDetails[1],
          quizGrade: roomDetails[2],
          quizQty: roomDetails[3],
          quizQSet: roomDetails[4],
          quizQuestions: roomDetails[5],
          quizSubject: roomDetails[6],
          countdownTimer: 0,
        }, () => {
          // window.alert('Room Details save done.');
          // isFuncDone = true;
          // window.alert('Total Time : ' + this.state.quizTotalDuration + ' s');
        });
      });
  }

  FB_Update_PkRoomDetail = async (
    _duration, _durationForEach, _grade, _qnQty, _qnSet, _subject, _questions
    // _dateStart, _dateEnd, _timeStart, _timeEnd
  ) => {
    await firebase.database()
      .ref(this.GetTestPhasePath() + 'pkquiz-room-detail/' + this.state.roomId)
      .set({
        Duration: _duration,
        DurationPerQuestion: _durationForEach,
        Grade: _grade,
        QnQty: _qnQty,
        QnSet: _qnSet,
        Subject: _subject,
        Questions: _questions,

        //2021.03.08
        Organizer: organizer['DisplayName'],
        EventCode: eventCode,
        RoomTitle: this.state.quizRoomTitle,

        //2021.07.16
        DateStart: this.state.QuizDateStart,
        DateEnd: this.state.QuizDateEnd,
        TimeStart: this.state.QuizTimeStart,
        TimeEnd: this.state.QuizTimeEnd,

        //2021.10.29
        RoomType: 0,
        OrganizerIdentity: organizer['Identity'],
        OrganizerId: organizer['Id'],
      });
  }

  FB_Get_PkLive() {
    let _QuizEndDT = "";
    let _QuizStartDT = "";
    let _RoomState = "";
    let _RoomId = "";
    // let liveDetails = [];
    let loc = this.GetTestPhasePath() + 'pkquiz-live/' + this.state.roomId;
    // window.confirm(`${loc}`);
    firebase.database().ref(loc)
      .once('value').then(function (dataSnapshot) {
        let liveValues = _(dataSnapshot.val()).values().value();

        // liveValues.map((data, key) => {
        //   liveDetails.push({ key: key, value: liveValues[key] });
        // });

        _QuizEndDT = liveValues[0];
        _QuizStartDT = liveValues[1];
        _RoomState = liveValues[2];
        _RoomId = liveValues[3];
      })
      .catch(error => {
        window.alert('Get Operation Invalid.\n\n' + error);
      })
      .then(() => {
        let _elapsedSeconds = this.state.quizTotalDuration - (((new Date(_QuizEndDT)).getTime() - Date.now()) / 1000);
        // window.alert(_elapsedSeconds);        
        if (_elapsedSeconds < 0) {
          _elapsedSeconds = this.state.quizTotalDuration;
        }

        this.setState({
          countdownTimer: _elapsedSeconds,
          QuizEndDT: _QuizEndDT,
          QuizStartDT: _QuizStartDT,
          RoomState: _RoomState,
          RoomId: _RoomId,
          _isQuizStarted: true,
        }, () => {
          // window.alert('Room Details save done.');
          // isFuncDone = true;

          // this.SetStage(StageMode.HtmlRanking);
        });
      });
  }

  // FB_Update_PkLive(_startTime, _endTime, _roomId, _quizState, _quizEnded, _quizStarted) {
  FB_Update_PkLive = async (_startTime, _endTime, _roomId, _quizState) => {
    await firebase.database()
      .ref(this.GetTestPhasePath() + 'pkquiz-live/' + this.state.roomId)
      .set({
        QuizStartDT: _startTime,
        QuizEndDT: _endTime,
        RoomId: _roomId,
        // isQuizCompleted: _quizCompleted,
        // isQuizEnded: _quizEnded,
        // isQuizStarted: _quizStarted,
        QuizState: _quizState,

        //2021.07.19
        DateStart: this.state.QuizDateStart,
        DateEnd: this.state.QuizDateEnd,
        TimeStart: this.state.QuizTimeStart,
        TimeEnd: this.state.QuizTimeEnd,

        //2021.10.29
        RoomType: 0,
      });
  }

  FB_Generate_RoomCode = async (_roomId) => {
    var _roomCode = '';
    var _isRoomCodeValid = false;

    // do {
    //   let isRoomCodeValid = false;
    //   _roomCode = this.getRandomIntegerBetweenRange(10000, 99999);
    //   await firebase.database().ref(this.GetTestPhasePath() + 'pkquiz-room-code/' + _roomCode).once('value')
    //     // .then(function (dataSnapshot) {
    //     //   //room code is exists, so cannot use = invalid.
    //     //   if (dataSnapshot.val().toString() === _roomId) {
    //     //     isRoomCodeValid = false;
    //     //     // window.alert('Room Code (' + _roomCode + ') is found, therefore Invalid.');
    //     //   }
    //     // })
    //     .then((dataSnapshot) => {
    //       //room code is exists, so cannot use = invalid.
    //       if (dataSnapshot.val().toString() === _roomId) {
    //         isRoomCodeValid = false;
    //         // window.alert('Room Code (' + _roomCode + ') is found, therefore Invalid.');
    //       }
    //     })
    //     .catch(error => {
    //       isRoomCodeValid = true;
    //       // window.alert('Room Code (' + _roomCode + ') is Not Found, Therefore it is Valid.');
    //     });
    //   _isRoomCodeValid = isRoomCodeValid;
    // } while (!_isRoomCodeValid);


    //2021-08-25 === revamped.
    let loopCounter = 0;
    do {
      loopCounter++;
      let isRoomCodeValid = false;
      _roomCode = this.getRandomIntegerBetweenRange(10000, 99999);
      console.log(_roomCode);
      await firebase.database().ref(this.GetTestPhasePath() + 'pkquiz-room-code/' + _roomCode).once('value')
        .then(snapshot => {
          console.log(snapshot.val());
          if (snapshot.val() === null)
            isRoomCodeValid = true;
        })
        .catch(error => {
          //cant fetch means its available.
          isRoomCodeValid = true;
          // console.log(error.message);
        });
      // _isRoomCodeValid = isRoomCodeValid;
      console.log(isRoomCodeValid);

      //Check in FireStore for RoomCode duplicates.
      if (isRoomCodeValid) {
        await dbFireStore.collection("LiveQuiz_UniqueRoomCode")
          .where('RoomCode', '==', _roomCode)
          .get()
          .then((querySnapshot) => {
            let data = [];
            if (querySnapshot !== null) {
              querySnapshot.forEach((doc) => {
                data.push(doc.data());
              });
            }
            // console.log(JSON.stringify(data[0]));
            // if (data[0] !== undefined) {
            //   if (data[0].RoomCode !== _roomCode)
            //     isRoomCodeValid = true;
            //   else
            //     isRoomCodeValid = false;
            // }
            // else {
            //   isRoomCodeValid = false;
            // }
            if (data[0].RoomCode !== _roomCode)
              isRoomCodeValid = true;
            else
              isRoomCodeValid = false;
            console.log(JSON.stringify(data) + '\n' + isRoomCodeValid + '\n' + _roomCode);
          })
          .catch((error) => {
            //cant fetch means its available.
            isRoomCodeValid = true;
          });
      }
      console.log(isRoomCodeValid);

      _isRoomCodeValid = isRoomCodeValid;
      if (loopCounter > 5) {
        _isRoomCodeValid = true;
      }
    } while (!_isRoomCodeValid);

    if (loopCounter > 5)
      return null;

    this.setState({
      roomCode: _roomCode,
    });
    // }, async () => {
    await firebase.database()
      .ref(this.GetTestPhasePath() + 'pkquiz-room-code/' + _roomCode)
      .set(_roomId);
    //Save Room for LiveView
    await this.FB_SaveRoom_ForLiveView();

    //2021.03.02
    this.GetOrganizer();

    //2021.10.29
    let pickedGrade = String(quizGradeArray[this.state.quizGrade]).toLowerCase().replace('standard', '').replace(' ', '');
    let groupIndex = GradeGroupPairs.findIndex(x => x.Grade === pickedGrade);
    let _groupId = groupIndex > -1 ? GradeGroupPairs[groupIndex].GroupId : 0;


    //2021.01.19
    //clone room info to FS (new location).
    // alert(this.state.quizDate + "\n\n" + this.state.quizRoomTitle);
    let _modal = {
      // Date: moment(this.state.quizDate).format('YYYY-MM-DD 00:00:00'),
      // SubjectName: quizSubjectArray[this.state.quizSubject],
      // RoomId: Number(_roomId),
      // RoomCode: Number(_roomCode),
      // RoomTitle: this.state.quizRoomTitle,
      // // TimeStart: this.state.QuizStartDT,
      // // TimeEnd: this.state.QuizEndDT,
      // // Organizer: this.GetOrganizer(),

      // //2021.03.02
      // Organizer: organizer['DisplayName'],
      // EventCode: eventCode,

      // //2021.03.03
      // Duration: this.state.quizTotalDuration,

      // //2021.07.16
      // DateStart: this.state.QuizDateStart,
      // DateEnd: this.state.QuizDateEnd,
      // TimeStart: this.state.QuizTimeStart,
      // TimeEnd: this.state.QuizTimeEnd,

      // //2021.10.29
      // OrganizerIdentity: '',
      // RoomType: 0,

      //2021.10.29 - rearrange.
      Date: moment(this.state.quizDate).format('YYYY-MM-DD 00:00:00'),
      DateEnd: this.state.QuizDateEnd,
      DateStart: this.state.QuizDateStart,
      Duration: this.state.quizTotalDuration,
      EventCode: eventCode,
      GroupId: _groupId,
      Organizer: organizer['Name'],
      OrganizerId: organizer['Id'],
      OrganizerIdentity: organizer['Identity'],
      RoomCode: Number(_roomCode),
      RoomId: Number(_roomId),
      RoomTitle: this.state.quizRoomTitle,
      RoomType: 0,
      SubjectName: quizSubjectArray[this.state.quizSubject],
      TimeEnd: this.state.QuizTimeEnd,
      TimeStart: this.state.QuizTimeStart,
    };

    // _startTime = _startTime === '_none' ? moment(_quizDate + ' 00:01:00').format('YYYY-MM-DD HH:mm:ss') : _startTime;
    // _endTime = _endTime === '_none' ? moment(_quizDate + ' 23:59:00').format('YYYY-MM-DD HH:mm:ss') : _endTime;
    // alert(JSON.stringify(_modal));
    await dbFireStore.collection("LiveQuiz_UniqueRoomCode")
      .doc(_roomId)
      .set(_modal)
      .catch((error) => {
        alert("Error", error.code + "\n\n" + error.message);
      });
    // });
  }

  GetOrganizer() {

    // let _organizer = '';
    // let _subject = String(quizSubjectArray[this.state.quizSubject]).toLowerCase();

    // if (_subject.includes('abacus'))
    //   _organizer = 'Smart Newton';
    // else if (_subject.includes('colin'))
    //   _organizer = "The Colin's Learning Centre";
    // else
    //   _organizer = 'SIM';

    // //2021.02.25
    // if (this.state.quizRoomTitle.includes('全马线上考试'))
    //   _organizer = '佳学教育 JiaXue Tuition Center';

    // return _organizer;


    let _organizer = '';
    let _eventCode = '';

    // //2021.03.02
    // let _subject = String(quizSubjectArray[this.state.quizSubject]).toLowerCase();

    // if (_subject.includes('abacus')) {
    //   _organizer = 'Smart Newton';
    //   _eventCode = TargetList.abacus_challenge_smartnewton_2021_0206;
    // }
    // else if (_subject.includes('colin')) {
    //   _organizer = "The Colin's Learning Centre";
    //   _eventCode = TargetList.colin_bm_2021_0130;
    // }
    // else if (_subject.includes('betatest')) {
    //   _organizer = "The iKEY Team";
    //   _eventCode = TargetList.ikeyteam_2021_beta_test_event;
    // }
    // else if (_subject.includes('mixedquestions')) {
    //   _organizer = "SIM";
    //   // _eventCode = TargetList.spot_2021_sim_0417_1st_sem;
    //   _eventCode = TargetList.sjk_spot_2021_2nd_quarter; //2021.08.13
    // }
    // else {
    //   _organizer = 'SIM';
    //   _eventCode = TargetList.None;
    // }

    // //2021.02.25
    // if (this.state.quizRoomTitle.includes('全马线上考试')) {
    //   _organizer = '佳学教育 JiaXue Tuition Center';
    //   _eventCode = TargetList.ptsmc_primary_test_2021_0306_0307;
    // }

    //2021.08.13
    if (this.state.EventList.length > 0) {
      if (this.state.EventCode !== '') {
        _eventCode = this.state.EventCode;
        let findIndex = this.state.EventList.findIndex(x => x.EventCode === _eventCode);
        if (findIndex > -1) {
          _organizer = {
            DisplayName: this.state.EventList[findIndex].OrganizerDisplayName,
            Name: this.state.EventList[findIndex].Organizer,
            Identity: this.state.EventList[findIndex].OrganizerIdentity,
          };

          //2021.11.10
          let organizer_index = -1;
          if (this.state.EventList[findIndex].hasOwnProperty('OrganizerIdentity')) {
            if (String(this.state.EventList[findIndex].OrganizerIdentity).length > 0)
              organizer_index = OrganizerPairs.findIndex(x => x.Identity === String(this.state.EventList[findIndex].OrganizerIdentity));
          }
          else if (this.state.EventList[findIndex].hasOwnProperty('Organizer')) {
            if (String(this.state.EventList[findIndex].Organizer).length > 0)
              organizer_index = OrganizerPairs.findIndex(x => x.ProfileFS === String(this.state.EventList[findIndex].Organizer));
          }
          else if (this.state.EventList[findIndex].hasOwnProperty('OrganizerDisplayName')) {
            if (String(this.state.EventList[findIndex].OrganizerDisplayName).length > 0)
              organizer_index = OrganizerPairs.findIndex(x => x.Label === String(this.state.EventList[findIndex].OrganizerDisplayName));
          }
          if (organizer_index > -1)
            _organizer = {
              DisplayName: OrganizerPairs[organizer_index].Label,
              Name: OrganizerPairs[organizer_index].ProfileFS,
              Identity: OrganizerPairs[organizer_index].Identity,
              Id: OrganizerPairs[organizer_index].Id,
            };
          else
            _organizer = {
              DisplayName: this.state.EventList[findIndex].OrganizerDisplayName,
              Name: this.state.EventList[findIndex].Organizer,
              Identity: this.state.EventList[findIndex].OrganizerIdentity,
              Id: 0,
            };

          this.setState({
            EventName: this.state.EventList[findIndex].EventName,
            EventOrganizer: this.state.EventList[findIndex].OrganizerDisplayName,
          });
        }
      }
    }


    organizer = _organizer;
    eventCode = _eventCode;
  }

  GetRoomTitle = async () => {
    // let roomTitlePath = firebase.database().ref("pkquiz-questions/"
    //   + this.state.quizSubject + "/"
    //   + this.state.quizGrade + "/"
    //   + this.state.quizQSet + "/"
    //   + "RoomTitle"
    // );

    let grade = '';
    if (this.state.quizGrade === 'L')
      grade = 'Lower Primary';
    else if (this.state.quizGrade === 'U')
      grade = 'Upper Primary';
    else {
      // alert(quizSubjectArray[this.state.quizSubject]);
      let _subjectName = String(quizSubjectArray[this.state.quizSubject]).toLowerCase();
      if (_subjectName.includes('colin')) {
        let _slice = _subjectName.split(' ');
        let _tempGrade = String(quizGradeArray[this.state.quizGrade]);
        let _grade = _tempGrade.includes('_2020') ? _tempGrade.split('_')[0] + ' (Batch ' + _tempGrade.split('_')[1] + ')' : _tempGrade;
        // if (_subjectName.includes('sec'))
        //   grade = 'Secondary ' + String(_slice[1]).toUpperCase() + ' F' + _grade;
        // else if (_subjectName.includes('pri'))
        //   grade = 'Primary ' + String(_slice[1]).toUpperCase() + ' Standard ' + _grade;
        // else
        //   grade = String(_slice[1]).toUpperCase() + ' ' + _grade;
        grade = 'Secondary ' + String(_slice[1]).toUpperCase() + ' ' + _grade;
      }
      else {
        grade = 'Standard ' + this.state.quizGrade;
      }
    }

    // let roomTitle = '';
    // await firebase.database()
    //   .ref("pkquiz-questions/"
    //     + quizSubjectArray[this.state.quizSubject] + "/"
    //     + quizGradeArray[this.state.quizGrade] + "/"
    //     + this.state.quizQSet + "/"
    //     + "RoomTitle"
    //   )
    //   .once('value', snapshot => {
    //     roomTitle = snapshot.val() !== null ? snapshot.val().toString()
    //       : grade;  // + ' ' + quizSubjectArray[this.state.quizSubject];
    //   });

    //2021.08.13
    let roomTitle = '';
    let eventCode = '';
    let eventName = '';
    let eventOrganizer = '';
    await firebase.database()
      .ref("pkquiz-questions/"
        + quizSubjectArray[this.state.quizSubject] + "/"
        + quizGradeArray[this.state.quizGrade] + "/"
        + this.state.quizQSet
      )
      .once('value', snapshot => {
        if (snapshot.val() !== null) {
          let data = snapshot.val();
          roomTitle = data.hasOwnProperty('RoomTitle') ? data.RoomTitle : grade;
          eventCode = data.hasOwnProperty('EventCode') ? data.EventCode : '';
          if (eventCode !== '') {
            let findIndex = this.state.EventList.findIndex(x => x.EventCode === eventCode);
            eventName = findIndex > -1 ? this.state.EventList[findIndex].EventName : '';
            eventOrganizer = findIndex > -1 ? this.state.EventList[findIndex].OrganizerDisplayName : '';
          }
        }
        else {
          roomTitle = grade;
          eventCode = '';
        }
      });

    this.setState({
      EventCode: eventCode,
      EventName: eventName,
      EventOrganizer: eventOrganizer,
      quizRoomTitle: roomTitle,
      QuizQuestionSetName: roomTitle,
    }, () => {
      // alert(this.state.quizRoomTitle);
    });
  }

  FB_SaveRoom_ForLiveView = async () => {
    await firebase.database()
      .ref('pkquiz/room')
      .set(this.state.roomCode + ';' + this.state.roomId + ';');
  }

  StartQuiz() {
    this.SetStage(StageMode.QuizStarted);

    var quizTime = new Date().getTime();
    var startTime = moment(quizTime).format('YYYY-MM-DD HH:mm:ss');
    // var endTime = moment(quizTime + ((this.state.quizTotalDuration) * 1000) + 10000).format('YYYY-MM-DD HH:mm:ss');
    var endTime = moment(quizTime + (this.state.quizTotalDuration * 1000)).format('YYYY-MM-DD HH:mm:ss');
    // var startTime = moment(quizTime).format('HH:mm');
    // var endTime = moment(quizTime + 600000).format('HH:mm');


    // firebase.database().ref('pkquiz-live/' + this.state.roomId + '/isQuizStarted').set('True');
    this.setState({
      countdownTimer: 0,
      // countdownEndTime: (quizTime + (this.state.quizTotalDuration * 1)),
      _isQuizStarted: true,
      QuizEndDT: endTime,
      // QuizEndDT: _endTime,    //new 2020.11.13
    }, () => {

      if (this.state.isCustomQuizTime)  //new 2020.10.19
      {
        // //new 2020.11.13
        // let _endTime = this.state.toggleEndTimeInputUi ? moment().format('YYYY-MM-DD') + ' ' + this.state.EndTimeInput + ':00' : '_none';

        // this.FB_Update_PkLive(
        //   '_none', _endTime, this.state.roomId, 'started'
        // );
      }
      else {
        this.FB_Update_PkLive(
          startTime, endTime, this.state.roomId, 'started' //this.state._isQuizEnded, this.state._isQuizStarted
        );
      }
      // this.SetStage(StageMode.QuizStarted);
    });
    // .then(()=>{
    //   this.FB_Get_PkLive();
    //   this.SetStage(StageMode.QuizStarted);
    // });
  }

  StopQuiz() {
    this.SetStage(StageMode.QuizEnded);
    this.setState({
      _isQuizEnded: true
    }, () => {
      // firebase.database().ref((isTestPhase ? 'pkquiz/' + this.state.todayDT + '/' : '') + 'pkquiz-live/' + this.state.roomId + '/isQuizEnded')
      firebase.database().ref(this.GetTestPhasePath() + 'pkquiz-live/' + this.state.roomId + '/QuizState')
        .set('ended', () => {
          this.ResetValues();  //2020.06.01 = replace back.

          //2020.06.01 = disable remove action for leaving evidence for future reference in case smtg wrong happened.
          // firebase.database().ref(this.GetTestPhasePath() + 'pkquiz-room-code/' + this.state.roomCode).remove(() => {
          //   this.ResetValues();
          //   // firebase.database().ref(this.GetTestPhasePath() + 'pkquiz-ranking-live/' + this.state.roomCode).remove(() => {
          //   //   this.ResetValues();
          //   // });
          // });
        });
    });
  }

  CompleteQuiz() {
    // this.SetStage(StageMode.Begin);
    this.setState({
      // _isQuizCompleted: true,
      _isQuizEnded: false,
      _isQuizStarted: false,
      // stageModeText: 'ready',
    }, () => {

      //2020.06.01 = disable remove for leaving evidence for future reference in case smtg wrong happened.
      // firebase.database().ref(this.GetTestPhasePath() + 'pkquiz-ranking-live/' + this.state.roomCode).remove(() => {
      //   this.SetStage(StageMode.Begin);
      // });

      // this.SetStage(StageMode.Begin);
      window.location.reload();   //2020.06.04
    });
    // , () => {
    //   firebase.database().ref((isTestPhase ? 'pkquiz/' + this.state.todayDT + '/' : '') + 'pkquiz-live/' + this.state.roomId + '/isQuizCompleted').set(true, () => {
    //     firebase.database().ref((isTestPhase ? 'pkquiz/' + this.state.todayDT + '/' : '') + 'pkquiz-room-code/' + this.state.roomCode).remove(() => {
    //       this.ResetValues();
    //     });
    //   });
    // });
  }

  ResetValues() {
    this.setState({
      roomId: '_none',
      // _isQuizCompleted: false,
      _isQuizEnded: false,
      _isQuizStarted: false,

      isPlayerListLoaded: false,
      isSubjectPicked: false,
      isGradePicked: false,

      quizSubject: '_none',
      quizGrade: 0,
      quizQSet: 0,
      quizMaxQty: 0,
      quizQty: 0,
      quizQuestions: '_none',
      quizDurationForEach: 10,
      quizTotalDuration: 0,
      quizTotalDurationText: '',

      countdownStartTime: 0,
      countdownEndTime: 0,
      countdownTimer: 0,
      countdownText: '00:00:00',
    }, () => {
      this.CheckTestPhase();
    });
  }

  SetAdminCode(_code) {
    this.setState({
      adminCode: _code,
    })
  }

  CheckAdminCode() {
    if (this.state.adminCode === '8271653') {
      this.SetStage(StageMode.Begin);
      // this.SetStage(StageMode.AdminSelect);
      this.setState({
        adminCode: '',
      });
    }
    else {
      window.confirm(`${'Admin Code Invalid.'}`);
      this.SetStage(StageMode.Start);
    }
  }

  SetPlayerQty(_qty) {
    this.setState({
      playerQty: _qty,
    })
  }

  GetRoomIdForLiveView() {
    var _data;
    firebase.database().ref('pkquiz/room').once('value')
      .then(function (dataSnapshot) {
        // handle read data.
        _data = dataSnapshot.val().toString().split(';');
        // window.confirm(`${_data[0] + ' - ' + _data[1]}`);        
      })
      .catch(error => {
        // window.confirm(`${'Data Not Found.'}`);
        this.setState({
          debugText: "Data Not Found." //error.toString()
        });
      })
      .then(() => {
        this.setState({
          roomCode: _data[0],
          roomId: _data[1],
        }, () => {
          this.ListenToNode_QuizState();
        });
      })
      .then(() => {
        // window.confirm(`${this.state.roomCode + ' - ' + this.state.roomId}`);
        this.SetStage(StageMode.HtmlJoinedPlayers);
        this.FB_Get_PkRoomDetail();
      });

    //Activate Listening to Node Events - <pkquiz/date/pkquiz-live/QuizState>
    // this.ListenToNode_QuizState();
  }

  DisableNodeListening_QuizState() {
    let loc = this.GetTestPhasePath() + 'pkquiz-live/' + this.state.roomId + '/QuizState';
    firebase.database().ref(loc).off('value');
  }

  ListenToNode_QuizState() {
    // pkquiz/20190919/testPhase/pkquiz-live/_none/QuizState
    let loc = this.GetTestPhasePath() + 'pkquiz-live/' + this.state.roomId + '/QuizState';
    // window.confirm(`${loc}`);
    // this.EnableLiveRankListening();
    firebase.database().ref(loc).on('value', snapshot => {
      this.processQuizStateForLiveView(snapshot.val());
    });
  }

  // StartQuizForLiveView() {
  //   //
  //   this.SetStage(StageMode.HtmlRanking);

  //   var quizTime = new Date().getTime();
  //   var startTime = moment(quizTime).format('YYYY-MM-DD HH:mm:ss');
  //   var endTime = moment(quizTime + (this.state.quizTotalDuration + 10 * 1000) + 10000).format('YYYY-MM-DD HH:mm:ss');
  //   // var startTime = moment(quizTime).format('HH:mm');
  //   // var endTime = moment(quizTime + 600000).format('HH:mm');

  //   // firebase.database().ref('pkquiz-live/' + this.state.roomId + '/isQuizStarted').set('True');
  //   this.setState({
  //     countdownTimer: 0,
  //     // countdownEndTime: (quizTime + (this.state.quizTotalDuration * 1)),
  //     _isQuizStarted: true,
  //   });
  // }

  processQuizStateForLiveView(_state) {
    //QuizState = init/started/ended

    // window.confirm(`${_state}`);
    // let _quizState = _(_state).values().value();

    if (_state === 'started') {
      //
      // window.confirm(`${'Quiz is Started.'}`);
      // this.FB_Get_PkRoomDetail();
      // this.StartQuizForLiveView();

      // isFuncDone = false;
      // this.waitUntil(this.checkIsFuncDone(), this.FB_Get_PkLive(), 100, 10000,
      //   () => {
      //     isFuncDone = false;
      //     this.SetStage(StageMode.HtmlRanking);
      //     // this.StartQuizForLiveView();
      //     // this.setState({
      //     //   // countdownTimer: 0,
      //     //   // _isQuizStarted: true,
      //     // }, () => {            
      //     // });
      //   });

      // this.FB_Get_PkLive();
      // isFuncDone = false;
      // this.SetStage(StageMode.HtmlRanking);

      // this.EnableLiveRankListening();

      this.FB_Get_PkLive();

      setTimeout(() => {
        this.setState({
          // countdownTimer: 0,
          _isQuizStarted: true,
          _isQuizEnded: false,
        }, () => {
          // this.SetStage(StageMode.QuizStarted);
        });
      }, 1500);

      // this.setState({
      //   countdownTimer: 0,
      //   _isQuizStarted: true,
      //   _isQuizEnded: false,
      // }, () => {
      //   // window.alert('Room Details save done.');
      //   // this.SetStage(StageMode.HtmlRanking);
      // });
    }
    else if (_state === 'ended') {
      //
      // window.confirm(`${'Quiz is Ended.'}`);
      this.DisableLiveRankListening();
      this.setState({
        _isQuizEnded: true,
      }, () => {
        // this.SetStage(StageMode.HtmlResult);
        if (stageStat === StageMode.HtmlRanking) {
          this.SetStage(StageMode.HtmlResult);
        }
      });

      //
    }
  }

  EnableLiveRankListening() {
    firebase.database().ref(this.GetTestPhasePath() + 'pkquiz-ranking/' + this.state.roomId)
      .on('value', snapshot => {
        this.ProcessPlayerLiveRankingList(snapshot.val());
      });
  }

  DisableLiveRankListening() {
    firebase.database().ref(this.GetTestPhasePath() + 'pkquiz-ranking/' + this.state.roomId)
      .off('value');
  }

  ProcessPlayerLiveRankingList(values) {
    //
    // playerListArray = null;
    // [object Object]
    // window.confirm(`${_listArray[0].name + ' : ' + _listArray[0].score}`);
    let ranksVal = values;
    let rankKeys = _(ranksVal).keys().value();
    let rankValues = _(ranksVal).values().value();
    let textValues;

    this.setState({
      RankingList: [],
    });

    if (rankValues.length > 0) {
      // window.confirm(`${rankValues[0] + ''}`);

      rankKeys.map((data, key) => {
        // playerListArray.push({ name: _listArray[key].key, score: _listArray[key].score, time: _listArray[key].time, uid: _listArray[key].uid, isDone: _listArray[key].isDone });
        textValues = (rankValues[key] + '').split(';');
        // window.confirm(`${data.toString() + ':' + textValues[0] + ':' + textValues[1] + ':' + textValues[2] + ':' + textValues[3]}`);
        // this.state.RankingList.push({ pname: data.toString(), score: textValues[0], time: textValues[1], uid: textValues[2], isDone: textValues[3] });
        this.state.RankingList.push({ pname: textValues[0], score: textValues[1], time: textValues[2], uid: textValues[3] });
        return null;
      });

      this.setState({
        RankingList: this.state.RankingList,
        // isPlayerListLoaded: true,
      }, () => {
        // window.confirm(`${'Player Ranking List is Loaded.'}`);
        this.setState({
          isPlayerListLoaded: true,
        });
      });
    }
  }

  GetFormatedTime(_time) {
    //this.state.RankingList[3].time

    var minutes = (_time / 60).toFixed(3).split('.')[0];
    var seconds = (_time % 60).toFixed(2).toString();

    return ((minutes > 0 ? minutes + ' m ' : '') + seconds + ' s');
  }

  GetFormatedPName(_id) {
    return (this.state.RankingList.length > _id ? unescape(this.state.RankingList[_id].pname) : '');
  }

  GetFormatedScore(_id) {
    return (this.state.RankingList.length > _id ? this.state.RankingList[_id].score : '0');
  }

  GetFormatedTimeSpent(_id) {
    return (this.state.RankingList.length > _id ? this.GetFormatedTime(this.state.RankingList[_id].time) : '0 s');
  }

  LiveQuizEnd() {
    this.setState({
      _isQuizEnded: false,
      _isQuizStarted: false,
    }, () => {
      this.ResetValues();
      this.SetStage(StageMode.Html);
    });
  }

  GetRoomIdForLiveView_Manually(_roomCode, _isReconnect) {
    var _data;
    let isRoomFound = false;
    let loc = this.GetTestPhasePath() + 'pkquiz-room-code/' + _roomCode;
    firebase.database().ref(loc).once('value')
      .then(function (dataSnapshot) {
        // handle read data.
        _data = dataSnapshot.val().toString();
        // window.confirm(`${_data}`);
        isRoomFound = true;
      })
      .catch(error => {
        window.confirm(`${'Room Not Found.'}`);
        this.setState({
          debugText: "Room Not Found." //error.toString()
        });
      })
      .then(() => {
        if (isRoomFound) {
          this.setState({
            roomCode: _roomCode,
            roomId: _data,
            // countdownTimer: 0,
          }, () => {
            // this.ListenToNode_QuizState();
            // this.SetStage(StageMode.HtmlJoinedPlayers);   
            // this.FB_Get_PkRoomDetail();

            // window.alert(_roomCode + " / " + _isReconnect.toString());
            //2020.06.02 - edited
            this.FB_Get_PkRoomDetail();
            this.FB_Get_PkLive();
          });
        }
      })
      .then(() => {
        // this.FB_Get_PkRoomDetail();
        // this.FB_Get_PkLive();
        this.ListenToNode_QuizState();
        this.EnableLiveRankListening();
        if (_isReconnect === true) {
          // this.EnableLiveRankListening(); 
          setTimeout(() => {
            // window.alert(this.state.RoomState.toString());

            if (this.state.RoomState === 'started') {
              this.SetStage(StageMode.QuizStarted);
            }
            else if (this.state.RoomState === 'init') {
              this.SetStage(StageMode.RoomCreated);
            }
            else if (this.state.RoomState === 'ended') {
              this.DisableLiveRankListening();
              this.SetStage(StageMode.HtmlResult);
            }
          }, 1000);
        }
        else if (_isReconnect === false) {
          // this.ListenToNode_QuizState();
          // this.SetStage(StageMode.HtmlJoinedPlayers);     
          this.SetStage(StageMode.HtmlRanking);
        }
      });
  }

  //2020.06.01 - added isReconnect
  RequestForRoomCode(_isReconnect) {
    let _roomCode = window.prompt('Enter Room Code', '');
    if (_roomCode === null) {
      _roomCode = '';
    }
    if (_roomCode.length > 0 && _roomCode.length === 5 && _roomCode.match(new RegExp(/^[0-9]+$/))) {
      // this.SetStage(StageMode.Start);
      this.GetRoomIdForLiveView_Manually(_roomCode, _isReconnect);
    }
    else if (_roomCode.length > 5 || _roomCode.length < 5) {
      window.alert('Room Code is Invalid.\n\nEnter Only 5 numeric digits.\n\nPlease try again.');
    }
    else if (_roomCode.length === 5 && _roomCode.match(new RegExp(/^[a-zA-Z0-9]+$/))) {
      window.alert('Room Code is Invalid.\n\nOnly Numbers are allowed.\n\nPlease try again.');
    }
    else {
      window.alert('Room Code is Invalid.\nPlease try again.');
    }
  }

  // Test_db_Common() {
  //   // dbLiveQuiz.ref('pkquiz/20200608/pkquiz-room-detail/1591595188638/Subject').once('value', snapshot => {
  //   //   window.alert(snapshot.val().toString());
  //   // });
  //   dbCommon.ref('Common/RLrKkFraitd2akCRc9IZThqGdN23/_7Email').once('value', snapshot => {
  //     window.alert(snapshot.val().toString());
  //   });
  // }

  DrawNumbersInput() {
    if (this.state.NumbersInput === '') {
      let counter = 1;
      let _temp = '';
      _temp += '<table cellpadding="7px" bgcolor="#282c34">';
      for (var i = 0; i < 3; i++) {
        _temp += '<tr>';
        for (var j = 0; j < 3; j++) {
          _temp += '<td><button id="input_' + counter + '" class="buttonStyleEnterAdminUi">&nbsp;' + counter + '&nbsp;</button></td>';
          counter++;
        }
        _temp += '</tr>';
      }
      _temp += '<tr><td><button id="input_0" class="buttonStyleEnterAdminUi">&nbsp;0&nbsp;</button></td>';
      // _temp += '<td>&nbsp;</td>';
      _temp += '<td colspan="2"><button id="input_reset" class="buttonStyleEnterAdminUi" style="width:123px">&nbsp;Reset&nbsp;</button></td></tr>';
      _temp += '</table>';
      this.setState({
        NumbersInput: _temp,
      });
    }
  }

  enterNumber = (e) => {
    let _id = e.target.id;
    if (_id != null && _id.indexOf('_') > -1) {
      let _num = _id.replace('input_', '');
      if (parseInt(_num) > -1) {
        this.SetAdminCode(this.state.adminCode + _num);
      }
      else if (_num === 'reset') {
        this.SetAdminCode('');
      }
    }
  }

  //edited 2020.10.14
  DrawSetQuizQtySlicesUi() {
    if (this.state.quizQSet > 0) {

      let _quizMaxQty = this.state.quizMaxQty;
      let _qsQtySet = this.state.qsQtySet;

      if (this.state.quizQSet !== this.state.quizQSetPrevious) {
        _quizMaxQty = 0;
        // alert (this.state.quizQSet + " vs " + this.state.quizQSetPrevious);
      }

      if (_quizMaxQty > 0) {
        // this.state.qsQtySet = [];
        let _divide = this.state.qsQtyDivide;

        // let _slices = Math.round(this.state.quizMaxQty / _divide);
        let _slices = 0;
        if (_quizMaxQty >= 10)
          _slices = Math.round(_quizMaxQty / _divide);
        else
          _slices = 1;

        for (var i = 1; i < _slices; i++) {
          // this.state.qsQtySet.push({ i });
          _qsQtySet.push({ i });
        }
        this.setState({
          // qsQtySet: this.state.qsQtySet,
          // quizQSetPrevious: this.state.quizQSet,
          qsQtySet: _qsQtySet,
          quizMaxQty: _quizMaxQty,
        });
      }
      else {
        this.setState({
          quizQSetPrevious: this.state.quizQSet,
          quizMaxQty: 0,
          qsQtySet: [],
        }, () => {
          this.GetQSetTotalQnQty();
        });
        // this.GetQSetTotalQnQty();
      }
    }
    else {
      this.setState({
        quizMaxQty: 0,
        qsQtySet: [],
      });
    }
  }

  setQuizQtySlices = (e) => {
    let _id = e.target.id;
    if (_id != null && _id.indexOf('_') > -1) {
      let _num = parseInt(_id.replace('input_', ''));
      if (_num > -1) {
        this.SetQuizQty(_num * this.state.qsQtyDivide);
      }
    }
  }

  // bgLoop() {
  //   let bg = [];
  //   for (var i = 0; i < 20; i++) {
  //     bg.push(<img src={quizBg2} style={{ position: 'absolute', top: (i * window.innerHeight), left: 0, zIndex: -1, width: window.innerWidth }} />);
  //   }
  //   return bg;

  //   // return (
  //   //   <div
  //   //     style={{
  //   //       position: "absolute",
  //   //       overflow: "hidden",
  //   //       maxWidth: "100%",
  //   //       maxHeight: "100%",
  //   //       top: 0,
  //   //       justifyContent: "center"
  //   //     }}
  //   //   >
  //   //     <img src={quizBg2} alt="Live Quiz" width={window.innerWidth} height={window.innerHeight} />
  //   //   </div>
  //   // );
  // }



  //2020.11.25
  SetStartTimeInput = (event) => {
    this.setState({
      StartTimeInput: event.target.value
    }, () => {
      // alert(this.state.StartTimeInput);
      this.RefreshEndTimeInput();
    })
  }

  //2020.11.25
  RefreshEndTimeInput = () => {
    let _quizDate = this.state.toggleQuizDateInputUi ? this.state.QuizDateInput : moment().format('YYYY-MM-DD');
    let _duration = this.state.toggleCustomTotalDurationInputUi ? this.state.CustomTotalDuration : this.state.quizTotalDuration;
    // let _endTime = moment(_quizDate + ' ' + this.state.StartTimeInput + ':00').add(_duration, 'seconds');

    //2021.01.15
    let _endTime_Invalid = String(this.state.EndTimeInput).toLowerCase().includes('invalid');
    let _endTime = !this.state.EndTimeInputUi_disabled ?
      _endTime_Invalid ?
        moment(_quizDate + ' ' + this.state.StartTimeInput + ':00').add(_duration, 'seconds')
        :
        moment(_quizDate + ' ' + this.state.EndTimeInput + ':00')
      :
      moment(_quizDate + ' ' + this.state.StartTimeInput + ':00').add(_duration, 'seconds');

    // alert(
    //   "_quizDate: " + _quizDate
    //   + "\nEndTimeInput: " + this.state.EndTimeInput
    //   // + "\nEndTimeInput (new): " + _endTime_Input 
    //   + "\nStartTimeInput: " + this.state.StartTimeInput
    //   + "\n_duration: " + _duration.toString()
    //   + "\n_endTime: " + _endTime.toString()
    // );

    this.setState({
      // EndTimeInput: _endTime.format('HH:mm'),
      // EndTimeInput: this.state.toggleUseCustomQuizEndTime ? '' : _endTime.format('HH:mm'),   //2020.12.01
      EndTimeInput: _endTime.format('HH:mm'),   //2020.12.16
      quizTotalDuration: _duration,
      quizTotalDurationText: _duration + ' s (' + this.GetDurationInText(_duration) + ')',
      quizDate: _quizDate,
    }, () => {
      // alert('Start Time : ' + this.state.StartTimeInput + '\nEnd Time : ' + this.state.EndTimeInput + '\nDuration : ' + this.GetDurationInText(_duration));
    });
  }

  //2020.12.31
  SetCustomTotalDurationInput_Sec = (event) => {
    // let _second = parseInt(event.target.value) >= 59 ? 59 : parseInt(event.target.value);

    //2020.12.31
    let isNumber = (/^-?\d+\.?\d*$/).test(event.target.value);
    let _value = isNumber ? parseInt(event.target.value) : 0;
    let _second = _value >= 59 ? 59 : _value;

    this.setState({
      CustomTotalDurationInput_Sec: _second,
    }, () => {
      // alert(this.state.CustomTotalDurationInput_Min);
      this.SetCustomTotalDurationInput();
    })
  }

  //2020.11.25
  SetCustomTotalDurationInput_Min = (event) => {
    // let _minute = parseInt(event.target.value) >= 59 ? 59 : parseInt(event.target.value);

    //2020.12.31
    let isNumber = (/^-?\d+\.?\d*$/).test(event.target.value);
    let _value = isNumber ? parseInt(event.target.value) : 0;
    let _minute = _value >= 59 ? 59 : _value;

    this.setState({
      CustomTotalDurationInput_Min: _minute,
    }, () => {
      // alert(this.state.CustomTotalDurationInput_Min);
      this.SetCustomTotalDurationInput();
    })
  }

  //2020.11.25
  SetCustomTotalDurationInput_Hour = (event) => {
    // let _hour = parseInt(event.target.value) >= 6 ? 6 : parseInt(event.target.value);   //max = 6 hrs

    //2020.12.31
    let isNumber = (/^-?\d+\.?\d*$/).test(event.target.value);
    let _value = isNumber ? parseInt(event.target.value) : 0;
    let _hour = _value >= 6 ? 6 : _value;

    this.setState({
      CustomTotalDurationInput_Hour: _hour,
      CustomTotalDurationInput_Min: _hour >= 6 ? 0 : this.state.CustomTotalDurationInput_Min,
      CustomTotalDurationInput_Sec: _hour >= 6 ? 0 : this.state.CustomTotalDurationInput_Sec,   //2020.12.31
    }, () => {
      // alert(this.state.CustomTotalDurationInput_Hour);
      this.SetCustomTotalDurationInput();
    })
  }

  //2020.11.25
  SetCustomTotalDurationInput = () => {
    setTimeout(() => {
      let _maxValue = 6 * 60 * 60;  //max = 6 hrs
      let _totalSeconds = ((this.state.CustomTotalDurationInput_Hour * 60)
        + this.state.CustomTotalDurationInput_Min) * 60
        + this.state.CustomTotalDurationInput_Sec;
      // _totalSeconds = _totalSeconds >= _maxValue ? _maxValue : _totalSeconds;

      if (_totalSeconds > _maxValue) {
        alert("Custom total duration is over the 6 hours limit.\nPlease edit your custom total duration.");
      }
      else {
        _totalSeconds = _totalSeconds >= _maxValue ? _maxValue : _totalSeconds;
        this.setState({
          CustomTotalDuration: _totalSeconds
        }, () => {
          // alert(this.state.CustomTotalDuration);
          this.RefreshEndTimeInput();
        });
      }
    }, 500);
  }

  //2020.11.13
  SetEndTimeInput = (event) => {
    this.setState({
      EndTimeInput: event.target.value
    }, () => {
      // alert(this.state.EndTimeInput);
      this.RefreshEndTimeInput();
    })
  }

  //2020.11.20
  SetQuizDateInput = (event) => {
    this.setState({
      QuizDateInput: event.target.value
    }, () => {
      // alert(this.state.QuizDateInput);
      this.RefreshEndTimeInput();
      this.CheckOnDateInputs();
    })
  }

  //2021.07.16
  CheckOnDateInputs = () => {
    if (this.state.toggleEditDateRange) {
      if (this.state.toggleQuizDateInputUi) {
        let _quizDateInput = this.state.QuizDateStart === '' ? this.state.QuizDateInput : this.state.QuizDateStart;
        let _quizDateEnd = this.state.QuizDateEnd === '' ? _quizDateInput
          : moment(this.state.QuizDateEnd) < moment(_quizDateInput) ?
            _quizDateInput : this.state.QuizDateEnd;

        this.setState({
          QuizDateInput: _quizDateInput,
          QuizDateStart: this.state.QuizDateStart === '' ?
            _quizDateInput : this.state.QuizDateStart,
          QuizDateEnd: _quizDateEnd,
        });
      }
      else {
        let _quizDateInput = this.state.QuizDateStart === '' ? this.state.QuizDateInput : this.state.QuizDateStart;
        let _quizDateEnd = this.state.QuizDateEnd === '' ?
          this.state.QuizDateStart === '' ?
            _quizDateInput
            : this.state.QuizDateStart
          : moment(this.state.QuizDateEnd) < moment(this.state.QuizDateStart) ?
            this.state.QuizDateStart : this.state.QuizDateEnd;

        this.setState({
          QuizDateInput: _quizDateInput,
          QuizDateStart: this.state.QuizDateStart === '' ? this.state.QuizDateInput : this.state.QuizDateStart,
          QuizDateEnd: _quizDateEnd,
        });
      }
    }
    else {
      if (this.state.toggleQuizDateInputUi) {
        let _quizDateInput = this.state.QuizDateInput;
        let _quizDateEnd = this.state.QuizDateEnd === '' ?
          _quizDateInput
          : moment(_quizDateInput) > moment(this.state.QuizDateEnd) ?
            _quizDateInput
            : this.state.QuizDateEnd;

        this.setState({
          // QuizDateInput: _quizDateInput,
          QuizDateStart: _quizDateInput,
          QuizDateEnd: _quizDateEnd,
        });
      }
    }
    // setTimeout(() => {
    //   console.log(
    //     'QuizDateInput = ' + this.state.QuizDateInput +
    //     '\nQuizDateStart = ' + this.state.QuizDateStart +
    //     '\nQuizDateEnd = ' + this.state.QuizDateEnd
    //   );
    // }, 0);
  }

  render() {
    return (
      <div className="App" >
        {/* <header className="App-header"> */}

        {
          stageStat === StageMode.Start ?
            <>
              {/* <img src={quizBg2} style={{ position: 'absolute', top: 0, left: 0, zIndex: -1, width: window.innerWidth, height: window.innerHeight }} /> */}
              <div className="App-header-frame">
                <p>&nbsp;</p>
                <center>
                  <div className="App-header-start">
                    <p>iKey Edutech - Borderless Learning</p>
                    <br></br>
                    {/* <Button
                      variant="contained" color="primary"
                      // size='large'
                      style={styleJSX.buttonStyle}
                      disabled={this.state.isDataLoaded === true ? false : true}
                      onClick={() => this.SetStage(StageMode.Html)}
                    >Live Quiz Ranking</Button>
                    <br></br>
                    <Button
                      variant="outlined" color="primary"
                      size='large'
                      disabled={this.state.isDataLoaded === true ? false : true}
                      onClick={() => { this.DrawNumbersInput(); this.SetStage(StageMode.Admin); }}
                    >Admin Mode</Button>
                    <br></br>
                    <p>{this.state.stageModeText}</p>
                    <br></br> */}
                    <span style={{ color: 'red', }}>this site is no longer supported anymore,<br />any admin task please visit <a href='https://livequiz.ikeyedutech.com.my/'>this link</a>.</span>
                    <br></br>
                    <p style={{ marginTop: 25, color: 'lightgray', fontSize: 12, float: 'left' }}
                    >Last Update : {moment(this.state.LastUpdateDate).format('lll')}</p>
                  </div>

                </center>
              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.Html ?
            <>
              {this.DisableNodeListening_QuizState()}
              <div class="main-wrapper">
                <img src={quizBg1} alt=''
                  // onClick={() => this.GetRoomIdForLiveView()} //this.SetStage(StageMode.HtmlJoinedPlayers)}
                  onClick={() => this.RequestForRoomCode(false)}
                  usemap="#htmlMap"
                />
                <map name="htmlMap">
                  <area shape="rect" coords="0,0,200,200" alt="Switch Mode"
                    onClick={() => this.SetStage(StageMode.Start)}
                  />
                </map>

                <img src={emptyImage} alt=''
                  style={styleJSX.emptyImageStyle}
                  usemap="#htmlJoinedPlayers"
                />

                {/* <map name="htmlJoinedPlayers">
                  <area shape="rect" coords="0,0,200,200" alt="Enter Room Code"
                    // onClick={() => this.RequestForRoomCode(false)}
                    onClick={() => this.GetRoomIdForLiveView()}
                  />
                </map> */}

              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.HtmlJoinedPlayers ?
            <>
              <body>
                {/* <div style={styleJSX.divMapStyle}>
                  <img src={emptyImage} alt=""
                    style={styleJSX.buttonMapStyle}
                    usemap="#htmlJoinedMap"
                  />
                  <map name="htmlJoinedMap">
                    <area shape="rect" coords="0,0,200,200" alt="Switch Mode"
                      onClick={() => this.SetStage(StageMode.Html)}
                    />
                  </map>
                </div> */}

                <div class="main-wrapper">
                  <div class="bg-image" style={styleJSX.joinedBgImgStyle} />

                  <div class="page-content h-100 d-inline-block" style={styleJSX.joinedStyle}>
                    <div class="page-wrapper container-fluid h-100 d-inline-block">
                      <div class="row">
                        <div class="columns col-lg-12 title-wrapper">
                          {/* <div class="countdown">00:00</div> */}
                          <div class="title">Room Code</div>

                        </div>
                        <div class="columns col-lg-12 room-number text-center">
                          <h1>{this.state.roomCode}</h1>
                        </div>

                        <div class="columns col-lg-12">
                          <div class="join-player">
                            <div class="row">
                              <div class="columns col-lg-12 player-title">Joined Player</div>

                              <PkQuizPlayerJoinedList
                                db={firebase}
                                roomid={this.state.roomId}
                                isTest={this.GetTestPhasePath()}
                                stageMode={'htmlJoin'}  //{StageMode.HtmlJoinedPlayers.toString()}
                                callback={(data) => this.SetPlayerQty(data)}
                              />

                              {/* 
                              <div class="columns col-lg-3 col-sm-4 col-6">1. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">2. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">3. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">4. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">5. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">6. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">7. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">8. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">9. LIM JIE YEE</div>
                              <div class="columns col-lg-3 col-sm-4 col-6">10. LIM JIE YEE</div>
                              */}

                              {
                                this.state.playerQty > 50 ?
                                  <>
                                    <div class="columns col-lg-3 col-sm-4 col-6">&nbsp;</div>
                                    <div class="columns col-lg-3 col-sm-4 col-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;more...</div>
                                  </>
                                  : null
                              }

                            </div>
                          </div>
                        </div>
                        <div class="row footer">
                          <div class="columns col-lg-6 col-sm-6 col-6 text-left">
                            <img src={quizJoinFooterLeft} alt=''
                              onClick={() => this.SetStage(StageMode.Html)}
                            />
                          </div>
                          <div class="columns col-lg-6 col-sm-6 col-6 text-right">
                            <img
                              src={quizJoinFooterRight} alt=''
                              onClick={() => this.SetStage(StageMode.Start)}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>


                </div>

                {/* <div style={styleJSX.divMapStyle}>
                  <img src={emptyImage} alt=""
                    style={styleJSX.buttonMapStyle}
                    usemap="#htmlJoinedMap"
                  />
                  <map name="htmlJoinedMap">
                    <area shape="rect" coords="0,0,200,200" alt="Switch Mode"
                      onClick={() => this.SetStage(StageMode.Start)}
                    />
                  </map>
                </div> */}
              </body>
            </>
            : null
        }

        {
          stageStat === StageMode.HtmlRanking ?
            <>
              {/* <PkQuizRankingLive
                db={firebase}
                roomid={this.state.roomId}
                isTest={this.GetTestPhasePath()}
                stageMode={'htmlRanking'}
              //callback={(dataList) => this.ProcessPlayerLiveRankingList(dataList)}
              /> */}
              <body>
                <div class="main-wrapper current-ranking">
                  <div class="bg-image">
                    <div class="page-content h-100 d-inline-block" style={styleJSX.joinedStyle}>
                      <div class="page-wrapper container-fluid h-100 d-inline-block">
                        <div class="row">
                          <div class="columns col-lg-2 col-md-2 col-12">
                            <div class="title">Room Code <br></br> {this.state.roomCode} </div>
                          </div>
                          <div class="columns col-lg-8 col-md-8 col-12 text-center">
                            <h1>Current Ranking</h1>
                          </div>
                          <div class="columns col-lg-2 col-md-2 col-12">
                            <div class="countdown">
                              {/* 00:00 */}
                              {this.state.countdownText}
                            </div>
                          </div>

                          {
                            this.state.isPlayerListLoaded === true
                              ?
                              <>
                                <div class="columns col-lg-12">
                                  <div class="top-player-ranking">
                                    <div class="wrapper">
                                      <div class="top-ranking top">
                                        <div class="row">
                                          <div class="columns col-lg-6">1. {this.GetFormatedPName(1)}</div>
                                          <div class="columns col-lg-6 text-right score">{this.GetFormatedScore(1)} pts</div>
                                        </div>
                                      </div>
                                      <div class="top-ranking">
                                        <div class="row">
                                          <div class="columns col-lg-6">2. {this.GetFormatedPName(2)}</div>
                                          <div class="columns col-lg-6 text-right score">{this.GetFormatedScore(2)} pts</div>
                                        </div>
                                      </div>
                                      <div class="top-ranking">
                                        <div class="row">
                                          <div class="columns col-lg-6">3. {this.GetFormatedPName(3)}</div>
                                          <div class="columns col-lg-6 text-right score">{this.GetFormatedScore(3)} pts</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="columns col-lg-12">
                                  <div class="ranking-player">
                                    <div class="row">

                                      {
                                        this.state.RankingList.length > 3 ? //2020.06.01 = change 4 to 3
                                          this.state.RankingList.map((data, key) => {
                                            if (key > 3) { //2020.06.01 = change 4 to 3
                                              return (
                                                //2020.06.01 = change (key+1) to (key)
                                                <div class="columns col-lg-3 col-sm-4 col-6">{key}. {data.pname}</div>
                                              );
                                            }
                                            return null;
                                          })
                                          : null
                                      }

                                      {/* <div class="columns col-lg-3 col-sm-4 col-6">4. LIM JIE YEE</div>
                                      <div class="columns col-lg-3 col-sm-4 col-6">5. LIM JIE YEE</div>
                                      <div class="columns col-lg-3 col-sm-4 col-6">6. LIM JIE YEE</div>
                                      <div class="columns col-lg-3 col-sm-4 col-6">7. LIM JIE YEE</div>
                                      <div class="columns col-lg-3 col-sm-4 col-6">8. LIM JIE YEE</div>
                                      <div class="columns col-lg-3 col-sm-4 col-6">9. LIM JIE YEE</div>
                                      <div class="columns col-lg-3 col-sm-4 col-6">10. LIM JIE YEE</div>
                                      <div class="columns col-lg-3 col-sm-4 col-6">11. LIM JIE YEE</div> */}

                                    </div>
                                  </div>
                                </div>
                                <div class="row footer">
                                  <div class="columns col-lg-6 col-sm-6 col-6 text-left">
                                    <img src={quizJoinFooterLeft} alt="" />
                                  </div>
                                  <div class="columns col-lg-6 col-sm-6 col-6 text-right">
                                    <img src={quizJoinFooterRight} alt="" />
                                  </div>
                                </div>

                              </>
                              : null
                          }
                        </div >
                      </div >
                    </div >
                  </div >
                </div >
              </body >
            </>
            : null
        }

        {
          stageStat === StageMode.HtmlResult ?
            <>
              <div class="main-wrapper">
                <body>
                  <div class="main-wrapper winner-ranking">
                    <div class="winner-bg-image">
                      <div class="page-content h-100 d-inline-block" style={styleJSX.joinedStyle}>
                        <div class="page-wrapper container-fluid h-100 d-inline-block">
                          <div class="row header">
                            <div class="columns col-lg-12 col-sm-12 col-12">
                              <img
                                src={quizWinnerHeader}
                                onClick={() => this.LiveQuizEnd()}
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="columns col-lg-2 col-md-2 col-12 text-left winner-title">
                              <img src={quizWinnerLeft} alt="" />
                            </div>
                            <div class="columns col-lg-8 col-md-8 col-12 text-center">
                              <div class="row rank-place-table">
                                <div class="columns col-lg-4 col-md-4 col-4">
                                  <div class="rank-place third">3rd</div>
                                  <div class="top-ranking third">
                                    <p class="name">{this.GetFormatedPName(3)}</p>
                                    <p class="point">{this.GetFormatedScore(3)} pts</p>
                                    <div class="speed">Speed</div>
                                    <p class="time">{this.GetFormatedTimeSpent(3)}</p>
                                  </div>
                                </div>
                                <div class="columns col-lg-4 col-md-4 col-4">
                                  <div class="rank-place first">1st</div>
                                  <div class="top-ranking first">
                                    <p class="name">{this.GetFormatedPName(1)}</p>
                                    <p class="point">{this.GetFormatedScore(1)} pts</p>
                                    <div class="speed">Speed</div>
                                    <p class="time">{this.GetFormatedTimeSpent(1)}</p>
                                  </div>
                                </div>
                                <div class="columns col-lg-4 col-md-4 col-4">
                                  <div class="rank-place second">2nd</div>
                                  <div class="top-ranking second">
                                    <p class="name">{this.GetFormatedPName(2)}</p>
                                    <p class="point">{this.GetFormatedScore(2)} pts</p>
                                    <div class="speed">Speed</div>
                                    <p class="time">{this.GetFormatedTimeSpent(2)}</p>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="columns col-lg-6 col-md-6 col-12">
                                  {
                                    resultColumn1.map((data, key) => {
                                      // let checkData = this.GetFormatedPName(data);
                                      // if (checkData.length > 0) {
                                      return (
                                        <div class="row other-winner">
                                          <div class="columns col-lg-6 col-md-6 col-md-12 text-left">{data}. {this.GetFormatedPName(data)}</div>
                                          <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(data)} pts</div>
                                          <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(data)}</div>
                                        </div>
                                      )
                                      // }
                                    })
                                  }

                                  {/* <div class="row other-winner">
                                    <div class="columns col-lg-6 col-md-6 col-md-12 text-left">4. {this.GetFormatedPName(4)}</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(4)} pts</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(4)}</div>
                                  </div>
                                  <div class="row other-winner">
                                    <div class="columns col-lg-6 col-md-6 col-md-12 text-left">6. {this.GetFormatedPName(6)}</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(6)} pts</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(6)}</div>
                                  </div>
                                  <div class="row other-winner">
                                    <div class="columns col-lg-6 col-md-6 col-md-12 text-left">8. {this.GetFormatedPName(8)}</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(8)} pts</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(8)}</div>
                                  </div>
                                  <div class="row other-winner">
                                    <div class="columns col-lg-6 col-md-6 col-md-12 text-left">10. {this.GetFormatedPName(10)}</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(10)} pts</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(10)}</div>
                                  </div> */}

                                </div>

                                <div class="columns col-lg-6 col-md-6 col-12">
                                  {
                                    resultColumn2.map((data, key) => {
                                      // let checkData = this.GetFormatedPName(data);
                                      // if (checkData.length > 0) {
                                      return (
                                        <div class="row other-winner">
                                          <div class="columns col-lg-6 col-md-6 col-md-12 text-left">{data}. {this.GetFormatedPName(data)}</div>
                                          <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(data)} pts</div>
                                          <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(data)}</div>
                                        </div>
                                      )
                                      // }
                                    })
                                  }

                                  {/* <div class="row other-winner">
                                    <div class="columns col-lg-6 col-md-6 col-md-12 text-left">5. {this.GetFormatedPName(5)}</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(5)} pts</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(5)}</div>
                                  </div>
                                  <div class="row other-winner">
                                    <div class="columns col-lg-6 col-md-6 col-md-12 text-left">7. {this.GetFormatedPName(7)}</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(7)} pts</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(7)}</div>
                                  </div>
                                  <div class="row other-winner">
                                    <div class="columns col-lg-6 col-md-6 col-md-12 text-left">9. {this.GetFormatedPName(9)}</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(9)} pts</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(9)}</div>
                                  </div>
                                  <div class="row other-winner">
                                    <div class="columns col-lg-6 col-md-6 col-md-12 text-left">11. {this.GetFormatedPName(11)}</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 point">{this.GetFormatedScore(11)} pts</div>
                                    <div class="columns col-lg-3 col-md-3 col-6 time">{this.GetFormatedTimeSpent(11)}</div>
                                  </div> */}
                                </div>
                              </div>



                            </div>
                            <div class="columns col-lg-2 col-md-2 col-12 text-right winner-title">
                              <img src={quizWinnerRight} alt="" />
                            </div>



                          </div>


                        </div>
                      </div>
                    </div>

                  </div>
                </body>
              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.Admin ?
            <>
              {/* <img src={quizBg2} style={{ position: 'absolute', top: 0, left: 0, zIndex: -1, width: '100%', height: '100%' }} /> */}
              <div className="App-header-base">
                <br></br>
                <p>Enter Admin Code</p>
                <TextInput value={this.state.adminCode} onChangeText={_input => this.SetAdminCode(_input)} style={styles.textInputStyleForAdmin} />
                {/* <TextInput
                  value={this.state.adminCode}
                  onChangeText={_input => this.SetAdminCode(_input)}
                  style={styles.textInputStyleForAdmin}
                /> */}
                <br></br>
                {/* <button
                  style={styleJSX.buttonStyle}
                  onClick={() => this.CheckAdminCode()} //this.SetStage(StageMode.HtmlJoinedPlayers)} //StageMode.Html
                >Identify</button> */}
                <Button
                  variant="contained" color="primary"
                  style={styleJSX.buttonStyle}
                  onClick={() => this.CheckAdminCode()} //this.SetStage(StageMode.HtmlJoinedPlayers)} //StageMode.Html
                >Identify</Button>
                <br></br>
                {/* <br></br> */}
                <div
                  // style={styleJSX.qsDetailUi}
                  onClick={this.enterNumber}
                  dangerouslySetInnerHTML={{ __html: this.state.NumbersInput }}
                />
              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.Begin ?
            <>
              {/* <img src={quizBg2} style={{ position: 'absolute', top: 0, left: 0, zIndex: -1, width: '100%', height: '100%' }} /> */}
              <div className="App-header-base">

                <div className="row">
                  <div className="col">
                    <p>Live Quiz</p>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col">
                    <Button
                      variant="contained" color="primary"
                      disabled={this.state.stageModeText !== 'ready' ? true : false}
                      style={styleJSX.buttonStyle}
                      // onClick={() => this.CreateRoom()}
                      // disabled={true}
                      onClick={() => window.open('https://livequiz.ikeyedutech.com.my', '_blank')}
                    >Create Room</Button>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col">
                    <Button
                      variant="contained" color="secondary"
                      size='large'
                      disabled={this.state.isDataLoaded === true ? false : true}
                      onClick={() => this.RequestForRoomCode(true)}
                    >Reconnect Back To Room<br></br>(if Ranking not updating)</Button>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col">
                    {/* <button
                  style={styleJSX.buttonStyle}
                  disabled={this.state.isDataLoaded === true ? false : true}
                  onClick={() => this.SetStage(StageMode.DataBrowsing)}
                >Data Browsing</button> */}
                    <Button
                      variant="contained" color="primary"
                      style={styleJSX.buttonStyle}
                      disabled={this.state.isDataLoaded === true ? false : true}
                      onClick={() => this.SetStage(StageMode.DataBrowsing)}
                    >Data Browsing</Button>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col">
                    {/* <button
                  style={styleJSX.buttonStyle}
                  onClick={() => this.SetStage(StageMode.Start)}
                >Restart</button> */}
                    <Button
                      variant="contained" color="secondary"
                      size='large'
                      // style={styleJSX.buttonStyle}
                      onClick={() => this.SetStage(StageMode.Start)}
                    >Back to Home</Button>
                  </div>
                </div>

                <hr />

                {/* 2021.01.05 */}
                {/* 2021.08.06 = disabled by May request. */}
                <div className="row" hidden={this.state.HideEventListEntrance}>
                  <div className="col">
                    <Button
                      variant="contained" color="primary"
                      style={styleJSX.buttonStyle}
                      disabled={this.state.isDataLoaded === true ? false : true}
                      onClick={() => this.SetStage(StageMode.JoinedListBrowsing)}
                    >Event Join List Browsing</Button>
                  </div>
                </div>


                <img src={emptyImage} alt=''
                  style={styleJSX.emptyImageStyle}
                  usemap="#HiddenEventListEntrance"
                />
                <map name="HiddenEventListEntrance">
                  <area shape="rect" coords="0,0,200,200" alt="Enter Room Code"
                    // onClick={() => this.RequestForRoomCode(false)}
                    onClick={() => {
                      let _secretCode = window.prompt('Enter Secret Code', window.location.href.includes('localhost') ? '55699' : '');
                      if (_secretCode === null) {
                        _secretCode = '';
                      }
                      else if (_secretCode === '55699') {
                        this.setState({ HideEventListEntrance: false });
                      }
                    }}
                  />
                </map>

              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.CreatingRoom ?
            <>
              <div className="App-header">
                <p>Room Id : {this.state.roomId}</p>
                <p><font color='yellow'>Room Settings</font></p>

                <div className="App-body">
                  {/* Subject : {quizSubjectArray[this.state.quizSubject]} &nbsp;&nbsp; */}
                  <table>
                    <tr>
                      <td valign='top'>Subject</td>
                      <td valign='top'>&nbsp;:&nbsp;</td>
                      <td>
                        <Picker
                          selectedValue={this.state.quizSubject}
                          style={styles.pickerStyle}
                          onValueChange={(itemValue, itemIndex) =>
                            // this.setState({ quizSubject: itemValue })
                            this.SetPickedSubject(itemValue)
                          }>
                          {
                            quizSubjectArray.map((data, key) => {
                              return (
                                <Picker.Item label={data} value={key} />
                              )
                            })
                          }
                        </Picker>
                      </td>
                    </tr>

                    <tr>
                      <td valign='top'>Grade</td>
                      <td valign='top'>&nbsp;:&nbsp;</td>
                      <td>
                        <Picker
                          disabled={this.state.quizSubject === 0 ? true : false}
                          selectedValue={this.state.quizGrade}
                          style={styles.pickerStyle}
                          onValueChange={(itemValue, itemIndex) =>
                            this.SetPickedGrade(itemValue)
                          }>
                          {
                            quizGradeArray.map((data, key) => {
                              return (
                                <Picker.Item label={data} value={key} />
                              )
                            })
                          }
                        </Picker>
                        &nbsp;<font style={styleJSX.hintFontStyle}>(L = Lower Primary, U = Upper Primary)</font>
                      </td>
                    </tr>

                    <tr>
                      <td valign='top'>Q-Set</td>
                      <td valign='top'>&nbsp;:&nbsp;</td>
                      <td>
                        {
                          this.state.isGradePicked ?
                            <>
                              <Picker
                                selectedValue={this.state.quizQSet}
                                style={styles.pickerStyle}
                                onValueChange={(itemValue, itemIndex) =>
                                  this.setState({ quizQSet: itemValue, QuizQuestionSetName: '' },
                                    async () => {
                                      this.DrawSetQuizQtySlicesUi();

                                      //2021.01.19
                                      await this.GetRoomTitle();

                                      console.log('QSet = ' + itemValue);
                                    })
                                }>
                                {
                                  quizQSetArray.map((data, key) => {
                                    return (
                                      <Picker.Item label={data} value={key} />
                                    )
                                  })
                                }
                              </Picker>
                              &nbsp;<font style={styleJSX.hintFontStyle}>
                                {this.state.QuizQuestionSetName}
                                {this.state.EventName.length > 0 ?
                                  <><br />{'Event: ' + this.state.EventName}<br />{'Organizer: ' + this.state.EventOrganizer}</>
                                  : null}
                              </font>
                            </>
                            :
                            <font style={styleJSX.hintFontStyle}>Pick Subject & Grade first.</font>
                          // <button
                          //   onClick={() => this.GetQSetList()}
                          // >Select Question Set</button>
                        }
                      </td>
                    </tr>

                    <tr>
                      <td valign='top'>Total Questions</td>
                      <td valign='top'>&nbsp;:&nbsp;</td>
                      <td>
                        <TextInput value={this.state.quizQty} onChangeText={_input => this.SetQuizQty(_input)} style={styles.textInputStyle} />
                        &nbsp;<font style={styleJSX.hintFontStyle}>(max: {this.state.quizMaxQty})</font>&nbsp;
                        &nbsp;<Button variant="contained" color="primary" onClick={() => this.SetQuizQty(this.state.quizMaxQty)}>Max</Button>
                        {
                          this.state.quizMaxQty > 0 ?
                            this.state.qsQtySet.map((data, key) => {
                              return (<Button variant="contained" color="primary"
                                onClick={() => this.SetQuizQty((key + 1) * this.state.qsQtyDivide)}
                                style={{ marginLeft: 10 }}
                              >{(key + 1) * this.state.qsQtyDivide}</Button>);
                            })
                            : null
                        }
                      </td>
                    </tr>

                    <tr>
                      <td valign='top'>Duration per Question (seconds)</td>
                      <td valign='top'>&nbsp;:&nbsp;</td>
                      <td>
                        <TextInput value={this.state.quizDurationForEach} onChangeText={_input => this.SetQuizDurationForEach(_input)} style={styles.textInputStyle} />
                        &nbsp;<font style={styleJSX.hintFontStyle}>(max: unlimited, but try control at 20~30s per question)</font>
                      </td>
                    </tr>

                    <tr>
                      <td valign='top'>Duration (seconds)</td>
                      <td valign='top'>&nbsp;:&nbsp;</td>
                      <td>

                        <div className='row'>
                          <div className='col' style={{ paddingBottom: 0 }}>
                            {
                              this.state.quizTotalDurationText.length > 0 ?
                                this.state.quizTotalDurationText
                                : 'N/A'
                            }
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col'>
                            <input
                              type='checkbox'
                              value={this.state.toggleCustomTotalDurationInputUi}
                              onClick={() => this.setState({
                                toggleCustomTotalDurationInputUi: !this.state.toggleCustomTotalDurationInputUi,
                                CustomTotalDuration: 0,
                                CustomTotalDurationInput_Min: 0,
                                CustomTotalDurationInput_Hour: 0,
                                CustomTotalDurationInput_Sec: 0,  //2020.12.31
                              }, () => {
                                if (!this.state.toggleCustomTotalDurationInputUi) {
                                  let _duration = this.state.quizQty * this.state.quizDurationForEach;
                                  this.setState({
                                    quizTotalDuration: _duration,
                                    quizTotalDurationText: _duration + ' s (' + this.GetDurationInText(_duration) + ')',
                                  });
                                }
                                this.SetCustomTotalDurationInput();
                              })}
                            />&nbsp;
                            <span style={{ fontSize: 14 }}>Set <font color='yellow'>Custom Total Duration</font> (max: 6 hours)</span>
                          </div>
                        </div>

                        {
                          this.state.toggleCustomTotalDurationInputUi ?
                            <>
                              <div className='row' style={{ paddingLeft: 25, fontSize: 20 }}>
                                <div className='col-4'>
                                  <input
                                    type="number"
                                    value={this.state.CustomTotalDurationInput_Hour}
                                    onChange={this.SetCustomTotalDurationInput_Hour}
                                    // maxLength={2}
                                    // size={1}
                                    min="0"
                                    max="6"
                                  />&nbsp;hour{this.state.CustomTotalDurationInput_Hour > 1 ? 's' : ''}
                                </div>
                                <div className='col-4'>
                                  <input
                                    type="number"
                                    value={this.state.CustomTotalDurationInput_Min}
                                    onChange={this.SetCustomTotalDurationInput_Min}
                                    // maxLength={2}
                                    // size={1}
                                    min="0"
                                    max="59"
                                  />&nbsp;minute{this.state.CustomTotalDurationInput_Min > 1 ? 's' : ''}
                                </div>
                                {/* <div className='col-6'>&nbsp;</div> */}

                                {/* 2020.12.31 */}
                                <div className='col-4'>
                                  <input
                                    type="number"
                                    value={this.state.CustomTotalDurationInput_Sec}
                                    onChange={this.SetCustomTotalDurationInput_Sec}
                                    // maxLength={2}
                                    // size={1}
                                    min="0"
                                    max="59"
                                  />&nbsp;second{this.state.CustomTotalDurationInput_Sec > 1 ? 's' : ''}
                                </div>
                              </div>
                            </>
                            : null
                        }

                      </td>
                    </tr>

                    <tr>
                      <td valign='top'>Extra Option (Custom Settings)</td>
                      <td valign='top'>&nbsp;:&nbsp;</td>
                      <td>
                        {/* <Picker
                          selectedValue={this.state.isCustomQuizTime}
                          style={styles.pickerStyle, { width: 350 }}
                          onValueChange={(itemValue, itemIndex) =>
                            this.setState({ isCustomQuizTime: itemValue })
                          }>
                          <Picker.Item label={"Quiz is showed within Limited Time Period only"} value={false} selected />
                          <Picker.Item label={"Quiz is showed for Infinite Time period"} value={true} />
                        </Picker>(for Quiz created on today only) */}

                        <div className='row'>
                          <div className='col'>
                            <span style={{ fontSize: 18 }}>All Quiz created are limited to enter on their <font color='yellow'>created date</font> only</span>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col'>
                            <input type="radio"
                              name={'quiz_mode'}
                              checked={!this.state.isCustomQuizTime}
                              onClick={() => this.setState({
                                isCustomQuizTime: false,
                                toggleEndTimeInputUi: false,
                                toggleQuizDateInputUi: false,
                                EndTimeInput: '',
                                QuizDateInput: moment().format('YYYY-MM-DD'),
                              })}
                            />&nbsp;
                            {/* <span style={{ fontSize: 14 }}>Quiz is showed within Limited Time Period (Only can enter after the quiz has been started)</span> */}
                            <span style={{ fontSize: 14, color: 'pink' }}><font color='white'>Quiz for Now</font> (Only can enter <font color='yellow'>today</font> after the quiz has been started, available only within limited duration.)</span>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col'>
                            <input type="radio"
                              name={'quiz_mode'}
                              onClick={() => this.setState({ isCustomQuizTime: true })}
                            />&nbsp;
                            {/* <span style={{ fontSize: 14 }}>Quiz is showed for Infinite Time period (Can enter at anytime)</span> */}
                            <span style={{ fontSize: 14 }}>Quiz for Today or Future <font color='pink'>(with Custom Settings)</font></span>
                          </div>
                        </div>

                        {
                          this.state.isCustomQuizTime ?

                            <div className='row'>
                              <div className='col'>

                                <div className='row'>
                                  <div className='col' style={{ paddingLeft: 50 }}>
                                    <input
                                      type='checkbox'
                                      value={this.state.toggleStartTimeInputUi}
                                      onClick={() => this.setState({
                                        toggleStartTimeInputUi: !this.state.toggleStartTimeInputUi,
                                      }, () => this.setState({
                                        EndTimeInputUi_disabled: this.state.toggleStartTimeInputUi,
                                        toggleEndTimeInputUi: this.state.toggleStartTimeInputUi,
                                        EndTimeInput: '',
                                      }))}
                                    />&nbsp;
                                    <span style={{ fontSize: 14 }}>Set Quiz <b>Start Time</b></span>
                                  </div>
                                </div>

                                {
                                  this.state.toggleStartTimeInputUi ?
                                    <div className='row' style={{ paddingLeft: 55 }}>
                                      <div className='col-3'>
                                        <input
                                          type="time"
                                          // value={moment().format('hh:mm')}
                                          onChange={this.SetStartTimeInput}
                                        />
                                      </div>

                                      <div className='col-8'>&nbsp;&nbsp;
                                        <input
                                          type='checkbox'
                                          value={this.state.toggleUseCustomQuizEndTime}
                                          onClick={() => this.setState({
                                            toggleUseCustomQuizEndTime: !this.state.toggleUseCustomQuizEndTime,   //2020.12.01
                                            EndTimeInputUi_disabled: !this.state.EndTimeInputUi_disabled,   //2020.12.16
                                            // toggleEndTimeInputUi: false,
                                            EndTimeInput: '',
                                          }, () => this.RefreshEndTimeInput())}
                                        />&nbsp;
                                        <span style={{ fontSize: 14 }}><i>Use <font color='yellow'>custom</font> <b><u>Quiz End Time</u></b></i>.</span>
                                      </div>
                                    </div>
                                    : null
                                }

                                <div className='row'>
                                  <div className='col' style={{ paddingLeft: 50 }}>
                                    <input
                                      type='checkbox'
                                      checked={this.state.toggleEndTimeInputUi}
                                      onClick={() => this.setState({ toggleEndTimeInputUi: !this.state.toggleEndTimeInputUi })}
                                      disabled={this.state.EndTimeInputUi_disabled}
                                    />&nbsp;
                                    <span style={{ fontSize: 14 }}>Set Quiz <b>End Time</b></span>
                                  </div>
                                </div>

                                {
                                  this.state.toggleEndTimeInputUi ?
                                    <div className='row' style={{ paddingLeft: 55 }}>
                                      <div className='col'>
                                        <input
                                          type="time"
                                          // value={moment().format('hh:mm')}
                                          value={this.state.EndTimeInput}
                                          onChange={this.SetEndTimeInput}
                                          disabled={this.state.EndTimeInputUi_disabled}
                                        />
                                      </div>
                                    </div>
                                    : null
                                }

                                <div className='row'>
                                  <div className='col' style={{ paddingLeft: 50 }}>
                                    <input
                                      type='checkbox'
                                      value={this.state.toggleQuizDateInputUi}
                                      onClick={() => this.setState({ toggleQuizDateInputUi: !this.state.toggleQuizDateInputUi })}
                                      disabled={this.state.toggleEditDateRange}
                                    />&nbsp;
                                    <span style={{ fontSize: 14 }}>Set Quiz Date</span>
                                  </div>
                                </div>

                                {
                                  this.state.toggleQuizDateInputUi ?
                                    <div className='row' style={{ paddingLeft: 55 }}>
                                      <div className='col'>
                                        <input
                                          type="date"
                                          // value={moment().format('hh:mm')}
                                          value={this.state.QuizDateInput}
                                          onChange={this.SetQuizDateInput}
                                          disabled={this.state.toggleEditDateRange}
                                        />
                                      </div>
                                    </div>
                                    : null
                                }

                                <div className='row'>
                                  <div className='col' style={{ paddingLeft: 50 }}>
                                    <input
                                      type='checkbox'
                                      value={this.state.toggleEditDateRange}
                                      onClick={() => this.setState({ toggleEditDateRange: !this.state.toggleEditDateRange }
                                        , () => {
                                          this.CheckOnDateInputs();
                                        }
                                      )}
                                    />&nbsp;
                                    <span style={{ fontSize: 14 }}>Set Quiz Date In Range</span>
                                  </div>
                                </div>

                                {
                                  this.state.toggleEditDateRange ?
                                    <>
                                      <div className='row' style={{ paddingLeft: 55 }}>
                                        <div className='col'>From Date</div>
                                        <div className='col'>
                                          <input
                                            type="date"
                                            value={this.state.QuizDateStart}
                                            onChange={(e) => this.setState({ QuizDateStart: e.target.value }, () => {
                                              this.CheckOnDateInputs();
                                            })}
                                          />
                                        </div>
                                      </div>
                                      <div className='row' style={{ paddingLeft: 55 }}>
                                        <div className='col'>To Date</div>
                                        <div className='col'>
                                          <input
                                            type="date"
                                            value={this.state.QuizDateEnd}
                                            onChange={(e) => this.setState({ QuizDateEnd: e.target.value }, () => {
                                              this.CheckOnDateInputs();
                                            })}
                                          />
                                        </div>
                                      </div>
                                    </>
                                    : null
                                }

                              </div>
                            </div>
                            : null
                        }

                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3} style={{ height: 35 }}></td>
                    </tr>

                    <tr>
                      <td valign='top'>Questions</td>
                      <td valign='top'>&nbsp;:&nbsp;</td>
                      <td>
                        {/* &nbsp;<button onClick={() => this.PickFirstNumbers(this.state.quizQty)}>Pick First {this.state.quizQty > 0 ? this.state.quizQty : 'N'} questions</button>
                        &nbsp;<button onClick={() => this.RandomPickNumbers()}>Random Pick</button>
                        &nbsp;<button onClick={() => this.UnselectAll()}>Unselect All</button> */}
                        &nbsp;<Button variant="contained" color="primary" onClick={() => this.PickFirstNumbers(this.state.quizQty)}>Pick First {this.state.quizQty > 0 ? this.state.quizQty : 'N'} questions</Button>
                        &nbsp;<Button variant="contained" color="primary" onClick={() => this.RandomPickNumbers()}>Random Pick</Button>
                        &nbsp;<Button variant="contained" color="primary" onClick={() => this.UnselectAll()}>Unselect All</Button>
                        <br></br>
                        <div style={{ padding: 15 }}>
                          {
                            this.state.quizQty > 0 ?
                              this.GenerateQuestionCheckbox()
                              : <>&nbsp;&nbsp;&nbsp;&nbsp; (Zero Question)</>
                          }
                        </div>
                      </td>
                    </tr>

                    <tr><td colSpan='3'>&nbsp;</td></tr>
                  </table>
                </div>

                {/* <button
                  style={styleJSX.buttonStyle}
                  onClick={() => this.MakeRoom()}
                >Make Room</button> */}
                <Button
                  variant="contained" color="primary"
                  style={styleJSX.buttonStyle}
                  onClick={() => this.setState({ Btn_MakeRoom_Disabled: true }, async () => await this.MakeRoom())}
                  disabled={this.state.Btn_MakeRoom_Disabled}
                >Make Room</Button>
                {
                  isTestPhase ?
                    <p>{this.state.debugText}</p>
                    : null
                }
                <br></br>
                <Button
                  variant='contained' color='secondary'
                  style={styleJSX.buttonStyle, styleJSX.buttonHome}
                  // style={styleJSX.buttonStyle}
                  onClick={() => window.location.reload()}
                >Back to Home</Button>
                <br></br><br></br><br></br>
              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.RoomCreated ?
            <>
              <div className="App-header">
                <p>Room Code : {this.state.roomCode}</p>
                {/* <button
                  style={styleJSX.buttonStyle}
                  onClick={() => this.StartQuiz()}
                >Start Quiz</button> */}
                <Button
                  variant="contained" color="primary"
                  style={styleJSX.buttonStyle}
                  onClick={() => this.StartQuiz()}
                >Start Quiz</Button>
                <br></br>
                <PkQuizPlayerJoinedList db={firebase} roomid={this.state.roomId} isTest={this.GetTestPhasePath()} />
              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.QuizStarted ?
            <>
              <div className="App-header">
                <br></br>Room Code : {this.state.roomCode}
                <font style={{ fontSize: 72 }}>{this.state.countdownText}</font>
                <br></br>
                {this.state.debugText}<br></br>
                <br></br>
                {/* <button
                  style={styleJSX.buttonStyle}
                  onClick={() => this.StopQuiz()}
                >End Quiz</button> */}
                <Button
                  variant="contained" color="primary"
                  style={styleJSX.buttonStyle}
                  onClick={() => this.StopQuiz()}
                >End Quiz</Button>
                <p>   </p>
                <PkQuizRankingLive db={firebase} roomid={this.state.roomId} isTest={this.GetTestPhasePath()} width={800} />
              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.QuizEnded ?
            <>
              <div className="App-header">
                {/* <p>Room Code : {this.state.roomCode}</p> */}
                <p>Results</p>
                {/* <button
                  style={styleJSX.buttonStyle}
                  onClick={() => this.CompleteQuiz()}
                >End</button> */}
                <Button
                  variant="contained" color="primary"
                  style={styleJSX.buttonStyle}
                  onClick={() => this.CompleteQuiz()}
                >End</Button>
                <PkQuizRankingList db={firebase} roomid={this.state.roomId} isTest={this.GetTestPhasePath()} />
              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.AdminSelect ?
            <>
              <div className="App-header">
                {/* <p>Admin Mode : Select Feature</p> */}
                <p>Results</p>
                <Button
                  variant="contained" color="primary"
                  style={styleJSX.buttonStyle}
                  onClick={() => this.SetStage(StageMode.Begin)}
                >Create<br></br>Live Quiz</Button>
                <br></br>
                <br></br>
                <Button
                  variant="contained" color="primary"
                  style={styleJSX.buttonStyle}
                  onClick={() => this.SetStage(StageMode.DataBrowsing)}
                >Live Quiz<br></br>Data Browsing</Button>
                <br></br>
                <br></br>
                <Button
                  variant="contained" color="primary"
                  style={styleJSX.buttonStyle}
                  onClick={() => window.location.reload()}
                >Reload</Button>
              </div>
            </>
            : null
        }

        {
          stageStat === StageMode.DataBrowsing ? //2020.06.09
            <>
              <QuizDataBrowsing
                db={dbLiveQuiz}
                dbCom={dbCommon}
                dbQs={dbQsLib}
                dbLog={dbLogs}
                dbFireStore={dbFireStore}   //2020.12.11
                dbQuizBank={dbQuizBank}     //2021.09.30

                today={this.state.todayDT}
                isTest={this.state.isTestPhase}

                //2021.01.29
                StageMode={StageMode}
              />
            </>
            : null
        }

        {
          stageStat === StageMode.JoinedListBrowsing ?  //2021.01.05
            <>
              <EventJoinedListBrowsing
                db={dbLiveQuiz}
                dbCom={dbCommon}
                dbQs={dbQsLib}
                dbLog={dbLogs}
                dbFireStore={dbFireStore}

                // today={this.state.todayDT}
                // isTest={this.state.isTestPhase}

                //2021.01.29
                StageMode={StageMode}
                // SetStage={this.SetStage}

                //2021.09.10
                EventList={this.state.EventList}
              />
            </>
            : null
        }


        {/* </header> */}
      </div >
    );
  }
}

////2021.01.07 - moved to top.
// const StageMode = {
//   Begin: 'begin',
//   CreatingRoom: 'creatingRoom',
//   RoomCreated: 'roomCreated',
//   QuizStarted: 'quizStarted',
//   QuizEnded: 'quizEnded',
//   Admin: 'admin',
//   Start: 'start',
//   Html: 'html',
//   HtmlJoinedPlayers: 'htmlJoin',
//   HtmlRanking: 'htmlRanking',
//   HtmlResult: 'htmlResult',

//   AdminSelect: 'adminSelect',
//   DataBrowsing: 'dataBrowsing',
//   JoinedListBrowsing: 'joinedListBrowsing'  //2021.01.05
// }
// let stageStat = StageMode.Start;
// let stageStat = StageMode.JoinedListBrowsing    //2021.01.05 - testing only

export default App;

const styles = StyleSheet.create({
  textInputStyle: {
    backgroundColor: '#fff',
    color: '#000',
    height: 30,
    width: 50,
    fontSize: 20,
    fontWeight: 'bold',
    // 'text-align': 'center',
    textAlign: 'center',
  },
  textInputStyleForAdmin: {
    backgroundColor: '#fff',
    color: '#000',
    height: 50,
    width: 200,
    fontSize: 20,
    fontWeight: 'bold',
    // 'text-align': 'center',
    textAlign: 'center',
  },
  buttonStyle: {
    width: '100%',
    height: 200,
  },
  pickerStyle: {
    height: 30,
    width: 200,
    fontSize: 18,
    fontWeight: 'bold',
    // alignSelf: 'flex-start',
  }
});

const styleJSX = {
  buttonStyle: {
    width: 200,
    height: 100,
    // borderRadius: 25,
    borderRadius: 5,
  },
  buttonHome: {
    width: 150,
    height: 75,
  },
  buttonStyleAdmin: {
    width: 100,
    height: 100,
    // borderRadius: 25,
    borderRadius: 5,
  },
  buttonStyleBackToRoom: {
    width: 350,
    height: 125,
    // borderRadius: 25,
    borderRadius: 5,
  },
  hintFontStyle: {
    color: 'gray',
    fontSize: 18,
  },
  joinedStyle: {
    'min-height': '1113px',
  },
  joinedBgImgStyle: {
    background: quizBg2,
    // position: 'fixed',
    'min-height': '1113px',
    height: '100%',
  },
  buttonMapStyle: {
    width: '200px',
    height: '200px',
  },
  divMapStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '200px',
    height: '200px',
    // 'z-index': 999,
  },
  emptyImageStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '200px',
    height: '200px',
    // 'z-index': 999,
  },
}
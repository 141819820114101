// PkQuizRankingList.js

import React, { Component } from 'react';
// import { Text, StyleSheet } from 'react-native';
// import ItemInfo from './ItemInfo';
import _ from 'lodash';
let playerNodes;
let app;
class PkQuizPlayerJoinedList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Players: [],
            isloaded: false,
            playerQty: 0,
        };
        // let app = this.props.db.database().ref('pkquiz-ranking/' + this.props.roomid);
        // app = this.props.db.database().ref('pkquiz-ranking/217879');
        app = this.props.db.database().ref(this.props.isTest + 'pkquiz-ranking-live/' + this.props.roomid);
        // app.on('value', snapshot => {
        //     this.processData(snapshot.val());
        // });
    }

    componentDidMount() {
        app.on('value', snapshot => {
            this.processData(snapshot.val());
        });
    }

    componentWillUnmount() {
        app.off('value');
    }

    getName(_value) {
        return unescape(_value.split(';')[2]);    //Uid : Scores;Time;Name;TRUE;
    }

    processData(values) {
        let playersVal = values;
        let playerKeys = _(playersVal).keys().value();
        let playerValues = _(playersVal).values().value();
        let playersList = [];
        playerKeys.map((data, key) => {
            playersList.push({ key: data, value: playerValues[key] });
            return null;
        });

        this.setState({
            Players: playersList,
            playerQty: playersList.length
        });

        playerNodes = this.state.Players.map((data, key) => {

            if (this.props.stageMode !== 'htmlJoin') {
                return (
                    <>
                        {/* <text style={styleJSX.textStyle}>&nbsp;{unescape(data.key)}&nbsp;</text> <br /> */}
                        <text style={styleJSX.textStyle}>&nbsp;{this.getName(data.value)}&nbsp;</text> <br />
                    </>
                )
            }
            else {
                return (    //2020.06.01 = change (key+1) to (key)
                    <>
                        {/* <div class="columns col-lg-3 col-sm-4 col-6">{key}. {data.key}</div> */}
                        <div class="columns col-lg-3 col-sm-4 col-6">{key + 1}. {this.getName(data.value)}</div>
                    </>
                )
            }

            // data.key != null ?
            //     <>
            //         <text style={styleJSX.textStyle}>&nbsp;{data.key}&nbsp;</text> <br />
            //     </>
            //     :
            //     <>
            //         <text style={styleJSX.textStyle}>&nbsp;waiting for players...&nbsp;</text>
            //     </>
        });

        this.setState({
            isloaded: true
        });

        if (this.props.stageMode === 'htmlJoin') {
            this.props.callback(this.state.playerQty);
        }
    }

    render() {
        if (this.props.stageMode !== 'htmlJoin') {
            return (
                <div>
                    <p>Live Quiz - Joined Players</p>
                    <p>
                        {
                            this.state.isloaded ? playerNodes : null    //<text style={styleJSX.textStyle}>&nbsp;waiting for players...&nbsp;</text>
                        }
                    </p>
                    <p>End of list</p>
                </div>
            )
        }
        else {
            return (
                this.state.isloaded ? playerNodes : null
            )
        }
    }
}

export default PkQuizPlayerJoinedList

// const styles = StyleSheet.create({
//     textStyle: {
//         backgroundColor: '#000',
//         color: 'yellow',
//         fontSize: 20,
//     },
// });

const styleJSX = {
    textStyle: {
        backgroundColor: '#000',
        color: 'yellow',
        fontSize: 20,
    },
}
// QuizDataBrowsing.js      //2020.06.09

import React, { Component } from 'react';
import { ActivityIndicator, Picker, Switch } from 'react-native';
import _ from 'lodash';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import styleCss from 'react-calendar/dist/Calendar.css';

import Button from '@material-ui/core/Button';

// import quizBg2 from './html/images/page-bg.png';

import './App.css';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// let rankingNodes;
// let dbPathRef, rooomDetail_PathRef, common_PathRef;
// let textValues;
// let _QsUiTopPos = '0px';

//RFC 5322 compliant regex 
// const emailRegex = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

//RFC 5322 compliant regex 
// const uidRegex = /^[a-zA-Z0-9]*$/gm;
// const uidRegex = /^(\d|\w)+$/;

let ProgressionStateText = '';

class QuizDataBrowsing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            versionDivisionDate: 20200630,
            versionDivision: '2.2.3.1(68)',
            isButtonEnabled: true,
            selectedDate: new Date(),
            liveQuizDate: '',
            Rankings: [],
            isloaded: false,
            temp: '',

            RoomCodeArrays: [],
            showRoomDetailsList: false,
            showRoomDetail: false,
            RoomDetailArrays: [],
            RoomDetails: '',
            // QsUiTopPos: '0px',

            isRoomListLoaded: false,
            isRoomDetailsLoaded: false,
            isShowRoomDetails: false,
            selectedRoomId: -1,
            selectedRoomIndex: -1,
            prevSelectedRoomId: -1,
            selectedRoomCode: -1,

            isShowJoinedPlayers: false,
            isJoinedPlayersLoaded: false,
            JoinedPlayerArrays: [],
            JoinedPlayerEmails: [],
            // JoinedPlayersList: [],

            singleQuestionDetailArray: [],
            singleQuestionDetailHtml: '',
            isShowQuestionDetailHtml: false,

            // showRankingList: false,
            isShowRankingList: false,
            isRankingListLoaded: false,
            RankingListArrays: [],

            isShowFinalRankingList: false,
            isFinalRankingListLoaded: false,
            FinalRankingList: [],
            // isAlmostDone: false,
            // dummy: false,
            dupeQtyForFRList: 0,

            // isShowLogDataDetail: false,
            logDataDetails: '',
            Hidden_Ranking_List_With_Results: '',
            // RankingListResults: [],
            resultDataDetails: '',
            selectedQuestionId: -1,
            scrollPosition: 0,

            //added 2020.11.13
            toggleAutoRefresh: false,
            autoRefreshTimerValue: 20,
            autoRefreshElapsedTime: 0,

            //2021.03.01
            Hidden_RoomList_table: '',

            //2021.07.29
            Hidden_RoomList_TimeTable: '',

            //2021.09.13
            QuizQuestions: [],
            SelectedAnswers: [],
            ReCalculatedResults: [],
            ProgressionState: 'processing...',
        };

        // dbPathRef = this.props.db.database().ref(this.props.isTest + 'pkquiz-ranking/' + this.props.roomid);
        // rooomDetail_PathRef = this.props.db.database().ref(checkTestPhasePath(this.props.isTest)  + '/pkquiz-room-detail');

        // common_PathRef = this.props.dbCom.app().database("https://ikeyedutech-student.firebaseio.com/");
    }

    //2021.09.10
    Delay = ms => new Promise(res => setTimeout(res, ms));

    //2021.09.13
    DelayUntil = (conditionFunction) => {
        const poll = resolve => {
            if (conditionFunction()) resolve();
            else setTimeout(_ => poll(resolve), 500);
        }
        return new Promise(poll);
    }

    //===== Auto Refresh === //added 2020.11.13
    ToggleAutoRefresh = () => {
        this.setState({
            toggleAutoRefresh: !this.state.toggleAutoRefresh,
            autoRefreshElapsedTime: 0,
        });
    }
    SetAutoRefreshTimerValue = (value) => {
        this.setState({
            autoRefreshTimerValue: Number(value),
            autoRefreshElapsedTime: 0,
        },
            () => {
                // alert(this.state.autoRefreshTimerValue);
            }
        );
    }
    CheckOnAutoRefreshTimer = () => {
        if (this.state.autoRefreshElapsedTime >= this.state.autoRefreshTimerValue) {
            this.setState({
                autoRefreshElapsedTime: 0,
            }, () => {
                this.SelectRoom(this.state.selectedRoomId);
            });
        }
    }
    //======= ends.


    checkTestPhasePath = (_date) => {
        return (this.props.isTest ? 'pkquiz/' + _date + '/testPhase/' : 'pkquiz/' + _date + '/');
    }

    roomDetailPath = (_date) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-room-detail');
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-room-detail');
    }

    roomDetailPathByRoomId = (_roomId) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-room-detail');
        return this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-room-detail/' + _roomId);
    }

    roomCodePath = (_date) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-room-code');
    }

    roomCodePathByRoomCode = (_roomCode) => {
        return this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-room-code/' + _roomCode);
    }

    joinedPlayersPath = (_date) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
    }

    joinedPlayersPathV2 = (_date, _roomId) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + _roomId);
    }

    liveRankingPath = (_date) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + this.state.selectedRoomId);
    }

    rankingListPath = (_date) => {
        // return this.props.db.database().ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking/' + this.state.selectedRoomId);
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking/' + this.state.selectedRoomId);
    }

    liveQuizRoomStatePath = (_roomIndex) => {
        // return this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-live/' + this.state.RoomCodeArrays[_roomIndex].RoomId);
        return this.props.db.ref('pkquiz/' + this.state.liveQuizDate + '/pkquiz-live/' + _roomIndex);
    }

    roomTitlePath = (_roomIndex) => {
        return this.props.db.ref("pkquiz-questions/"
            + this.state.RoomDetailArrays[_roomIndex].Subject + "/"
            + this.state.RoomDetailArrays[_roomIndex].Grade + "/"
            + this.state.RoomDetailArrays[_roomIndex].QnSet + "/"
            + "RoomTitle"
        );
    }

    userEmailPath = (_uid) => {
        return this.props.dbCom.ref("Common/" + _uid + "/_7Email");
    }

    userNamePath = (_uid) => {
        return this.props.dbCom.ref("Common/" + _uid + "/_1Name");
    }

    //2021.01.30
    userProfilePath = (_uid) => {
        return this.props.dbCom.ref("Common/" + _uid);
    }

    questionPath = (_roomIndex, _qsId) => {
        let path = "LiveQuiz/"
            + this.state.RoomDetailArrays[_roomIndex].Subject + "/"
            + this.state.RoomDetailArrays[_roomIndex].Grade + "/"
            + this.state.RoomDetailArrays[_roomIndex].QnSet + "/"
            + _qsId;
        // window.alert(path);
        return this.props.dbQs.ref(path);
    }

    //2021.09.13
    questionSetPath = (_roomIndex) => {
        let path = "LiveQuiz/"
            + this.state.RoomDetailArrays[_roomIndex].Subject + "/"
            + this.state.RoomDetailArrays[_roomIndex].Grade + "/"
            + this.state.RoomDetailArrays[_roomIndex].QnSet;
        // window.alert(path);
        return this.props.dbQs.ref(path);
    }

    //2021.09.30
    getRoomQuestionPath_QuestionSet_V1 = (questionSetUniqueId) => {
        let path = questionSetUniqueId + "/Questions";
        return this.props.dbQuizBank.ref(path);
    }

    //2021.09.13
    personalASelectsPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/ASelects');
    }

    //2020.07.15
    logsPath = (_uid) => {
        // let date = moment.unix(this.state.selectedRoomId / 1000);   //.format("YYYY-MM-DD HH:mm:ss"); //room id = ticks
        // alert(date.format("YYYY-MM-DD HH:mm:ss"));

        let date = moment(this.state.liveQuizDate);
        let path = _uid + "/" + date.format("YYYY") + "/" + date.format("M") + "/" + date.format("D");
        // alert(path);
        return this.props.dbLog.ref(path);
    }

    //2020.11.13
    personalQResultPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/QResults');
    }

    //2020.11.11
    personalScoreRecordPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/ScoreRecord');
    }

    //2020.07.17
    personalResultPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/QResults');

        // let path = this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '/QResults');
        // alert(path);
        // return path;
    }

    //2020.07.17
    personalResult_OldPath = (_date, _roomId, _uid) => {
        return this.props.db.ref(this.checkTestPhasePath(_date) + '/pkquiz-personal-data/' + _uid + '/' + _roomId + '_Results');
    }

    componentDidMount = () => {
        //      
        this.onCalenderChange(new Date());

        //Timer
        this.myInterval = setInterval(() => {
            if (uiModeState === UiMode.ShowFinalRankingList
                && this.state.toggleAutoRefresh
                && this.state.selectedRoomId > 0) {
                this.setState({
                    autoRefreshElapsedTime: this.state.autoRefreshElapsedTime + 1,
                }, () => {
                    this.CheckOnAutoRefreshTimer();
                });
            }
        }, 1000);
    }

    componentDidUpdate = () => {
        //
    }

    componentWillUnmount = () => {
        //
    }

    onCalenderChange = (value) => {
        let dateString = moment(value).format('YYYYMMDD');
        // alert(dateString);
        this.setState({
            isButtonEnabled: false,
            selectedDate: value,
            liveQuizDate: dateString,
            RoomDetailArrays: [],
            RoomCodeArrays: [],
            selectedRoomIndex: -1,
        }, () => {
            this.LoadRoomListAndDetails(UiMode.ShowRoomList);
            // this.LoadRoomListAndDetails();
        });
    }

    LoadRoomListAndDetails = (_uiMode) => {
        this.SwitchUi(_uiMode);

        if (this.state.RoomDetailArrays.length > 0) {
            this.setState({
                isButtonEnabled: true,
                isRoomListLoaded: true,
                // selectedRoomIndex: -1,
            });
        }
        else {
            // let date = moment(this.state.selectedDate).format('YYYYMMDD');
            // this.roomDetailPath(this.state.liveQuizDate).once('value', snapshot => {
            //     this.processRoomDetailsData(snapshot.val());
            //     // if (snapshot.val() != null) {
            //     //     this.processRoomDetailsData(snapshot.val());
            //     // }
            //     // else {
            //     //     this.setState({
            //     //         isRoomListLoaded: true,
            //     //         RoomDetails: <tr><td>No room is created for this day.</td></tr>,
            //     //     });
            //     // }
            // });
            this.setState({
                isButtonEnabled: false,
            }, async () => {
                await this.roomCodePath(this.state.liveQuizDate).once('value', snapshot => {
                    this.processRoomCodeData(snapshot.val());
                });
                await this.roomDetailPath(this.state.liveQuizDate).once('value', snapshot => {
                    this.processRoomDetailsData(snapshot.val());
                });
            });
        }
    }

    processRoomCodeData = (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = room code
        let dataValues = _(dataSnapVal).values().value();   //values = room id

        dataKeys.map(async (data, key) => {
            return this.state.RoomCodeArrays.push({
                RoomCode: data,
                RoomId: dataValues[key],
            })
        });

        this.setState({
            RoomCodeArrays: this.state.RoomCodeArrays,
        });
    }

    processRoomDetailsData = async (dataSnapVal) => {
        // if (dataSnapVal != null)
        //     alert(JSON.stringify(dataSnapVal));

        let dataKeys = _(dataSnapVal).keys().value();       //key = room id
        let dataValues = _(dataSnapVal).values().value();   //values = room details

        this.setState({
            RoomDetailArrays: [],
            RoomDetails: '',
        });

        // console.log(JSON.stringify(dataSnapVal));

        let _RoomDetailArrays = this.state.RoomDetailArrays;

        dataKeys.map((data, key) => {
            // let _roomCode = this.state.RoomDetailArrays.find(({ RoomId }) => RoomId === data).RoomCode;

            // console.log(JSON.stringify(data) + '\n\n' + key + '\n\n' + JSON.stringify(this.state.RoomCodeArrays));

            // //2020.12.11
            // let _roomCode = this.state.RoomCodeArrays.find(x => x.RoomId === data).RoomCode;

            //2021.07.23
            let _index = this.state.RoomCodeArrays.findIndex(x => x.RoomId === data);

            // console.log(_index + '\n\n' + JSON.stringify(this.state.RoomCodeArrays[_index]));

            if (_index > -1) {
                let _roomCode = this.state.RoomCodeArrays[_index].RoomCode;

                _RoomDetailArrays.push({
                    // RoomCode: _roomCode,
                    // RoomId: data.toString(),
                    // Duration: _(dataValues[key]).values().value()[0],
                    // DurationPerQuestion: _(dataValues[key]).values().value()[1],
                    // Grade: _(dataValues[key]).values().value()[2],
                    // QnQty: _(dataValues[key]).values().value()[3],
                    // QnSet: _(dataValues[key]).values().value()[4],
                    // Questions: _(dataValues[key]).values().value()[5],
                    // Subject: _(dataValues[key]).values().value()[6],

                    RoomCode: _roomCode,
                    RoomId: data.toString(),
                    Duration: dataValues[key]['Duration'],
                    DurationPerQuestion: dataValues[key]['DurationPerQuestion'],
                    Grade: dataValues[key]['Grade'],
                    QnQty: dataValues[key]['QnQty'],
                    QnSet: dataValues[key]['QnSet'],
                    Questions: dataValues[key]['Questions'],
                    Subject: dataValues[key]['Subject'],

                    RoomTitle: dataValues[key]['RoomTitle'],

                    //2020.12.11
                    StartTime: '',
                    EndTime: '',
                    // CustomDuration: '',

                    //2021.07.23
                    DateStart: dataValues[key].hasOwnProperty('DateStart') ? dataValues[key]['DateStart'] : '',
                    DateEnd: dataValues[key].hasOwnProperty('DateEnd') ? dataValues[key]['DateEnd'] : '',
                    TimeStart: dataValues[key].hasOwnProperty('TimeStart') ? dataValues[key]['TimeStart'] : '',
                    TimeEnd: dataValues[key].hasOwnProperty('TimeEnd') ? dataValues[key]['TimeEnd'] : '',

                    //2021.09.30
                    QuestionSetUniqueId: dataValues[key].hasOwnProperty('QuestionSetUniqueId') ? dataValues[key]['QuestionSetUniqueId'] : '',
                });
            }
            // return _RoomDetailArrays.push(null);
            return null;
        });
        // window.alert(details);

        //Get RoomTitle for each Room.
        Promise.all(
            dataKeys.map(async (data, key) => {
                // for (var key = 0; key < dataKeys.length; key++) {
                if (_RoomDetailArrays[key] !== undefined) {
                    // console.log(JSON.stringify(_RoomDetailArrays[key]));

                    let grade = '';
                    if (_RoomDetailArrays[key].Grade === 'L')
                        grade = 'Lower Primary';
                    else if (_RoomDetailArrays[key].Grade === 'U')
                        grade = 'Upper Primary';
                    else
                        grade = 'Standard ' + _RoomDetailArrays[key].Grade;

                    if (_RoomDetailArrays[key].RoomTitle === '') {
                        await this.roomTitlePath(key)
                            .once('value', snapshot => {
                                let title = snapshot.val() !== null ? snapshot.val().toString()
                                    : grade + ' ' + _RoomDetailArrays[key].Subject;
                                return _RoomDetailArrays[key].RoomTitle = title;  //Current Format
                            });
                    }

                    //2021.07.23
                    if (_RoomDetailArrays[key].DateStart !== '' && _RoomDetailArrays[key].TimeStart !== '') {
                        //2021.07.23
                        _RoomDetailArrays[key].StartTime = _RoomDetailArrays[key].DateStart + ' ' + _RoomDetailArrays[key].TimeStart;
                        _RoomDetailArrays[key].EndTime = _RoomDetailArrays[key].DateEnd + ' ' + _RoomDetailArrays[key].TimeEnd;
                    }
                    else {
                        //2020.12.11
                        let _roomId = this.state.RoomCodeArrays.find(x => x.RoomCode === _RoomDetailArrays[key].RoomCode).RoomId;
                        await this.liveQuizRoomStatePath(_roomId)
                            .once('value', snapshot => {
                                let roomState = snapshot.val();
                                _RoomDetailArrays[key].StartTime = roomState['QuizStartDT'];
                                _RoomDetailArrays[key].EndTime = roomState['QuizEndDT'];
                                // _RoomDetailArrays[key].CustomDuration = '';

                                // _RoomDetailArrays[key].RoomCode = _roomId;
                                // _RoomDetailArrays[key].RoomCode = 'pkquiz/' + this.state.liveQuizDate + '/pkquiz-live/' + this.state.RoomCodeArrays[key].RoomId
                                return null;
                            });
                    }
                }
                // }
                return null;
            })
        )
            .then(() => {
                this.setState({
                    RoomDetailArrays: _RoomDetailArrays,
                    isRoomListLoaded: true,
                    isButtonEnabled: true,
                }, () => {
                    this.SetRoomListTable();
                    this.PopulateRoomDetailInTimeTableForm();
                });
            });
        // .then(async () => {
        //     await this.LoadQuizQuestions();
        // });
        // this.setState({
        //     RoomDetailArrays: this.state.RoomDetailArrays,
        //     isRoomListLoaded: true,
        // });
    }

    //2021.07.29
    PopulateRoomDetailInTimeTableForm = async () => {
        if (this.state.RoomDetailArrays.length > 0) {

            let subjects = [];
            let currentSubject = '';

            let grades = [];
            let currentGrade = '';

            let timeStartGroups = [];
            let currentTimeStart = '';

            let timeEndGroups = [];
            let currentTimeEnd = '';

            let dateStartGroups = [];
            let currentDateStart = '';

            let dateEndGroups = [];
            let currentDateEnd = '';

            this.state.RoomDetailArrays.map((data, key) => {
                currentSubject = -1;
                currentSubject = subjects.findIndex(x => x === data.Subject);
                if (currentSubject < 0) {
                    subjects.push(data.Subject);
                }
                currentGrade = -1;
                currentGrade = grades.findIndex(x => x === data.Grade);
                if (currentGrade < 0) {
                    grades.push(data.Grade);
                }
                currentTimeStart = -1;
                currentTimeStart = timeStartGroups.findIndex(x => x === data.TimeStart);
                if (currentTimeStart < 0) {
                    timeStartGroups.push(data.TimeStart);
                }
                currentTimeEnd = -1;
                currentTimeEnd = timeEndGroups.findIndex(x => x === data.TimeEnd);
                if (currentTimeEnd < 0) {
                    timeEndGroups.push(data.TimeEnd);
                }
                currentDateStart = -1;
                currentDateStart = dateStartGroups.findIndex(x => x === data.DateStart);
                if (currentDateStart < 0) {
                    dateStartGroups.push(data.DateStart);
                }
                currentDateEnd = -1;
                currentDateEnd = dateEndGroups.findIndex(x => x === data.DateEnd);
                if (currentDateEnd < 0) {
                    dateEndGroups.push(data.DateEnd);
                }
                return null;
            });
            console.log('Subject: ' + subjects.join(', '));
            console.log('Grade: ' + grades.join(', '));
            console.log('Time Start: ' + timeStartGroups.join(', '));
            console.log('Time End: ' + timeEndGroups.join(', '));
            console.log('Date Start: ' + dateStartGroups.join(', '));
            console.log('Date End: ' + dateEndGroups.join(', '));

            // let subjectIndex = 0;
            // let groupIndex = 0;
            // this.state.RoomDetailArrays.map((data, key) => {
            //     if (data.Subject === subjects[subjectIndex]) {
            //         if (data.Group === groups[groupIndex]) {

            //         }
            //     }
            //     return null;
            // });


            var htmls = "<table id='Room-List-TimeTable' cellSpacing='0' cellPadding='0' border='0'><tbody>";
            for (var i = 0; i < grades.length; i++) {

                htmls += "<tr><td><table id='" + i + "' cellSpacing='0' cellPadding='5' border='1' style='font-weight: bold;'>";
                htmls += "<tbody><tr style='background-color: yellow;height: 35;'>";
                htmls += "<td>" + this.GetGroupName(grades[i]) + "</td>";
                for (var j = 0; j < timeStartGroups.length; j++) {
                    let _index = this.state.RoomDetailArrays.findIndex(x =>
                        x.Grade === grades[i] && x.TimeStart === timeStartGroups[j]);
                    if (_index > -1) {
                        htmls += "<td>" + this.GetRoomTime(this.state.RoomDetailArrays[_index]) + "</td>";
                    }
                }
                htmls += "</tr>";

                let _roomTitleHtml = '';
                for (var m = 0; m < subjects.length; m++) {
                    for (var p = 0; p < dateStartGroups.length; p++) {
                        // htmls += "<tr style='height: 35'>";
                        for (var k = 0; k < timeStartGroups.length; k++) {
                            let _index = -1;
                            _index = this.state.RoomDetailArrays.findIndex(x =>
                                x.Grade === grades[i]
                                && x.Subject === subjects[m]
                                && x.DateStart === dateStartGroups[p]
                                && x.TimeStart === timeStartGroups[k]);
                            if (_index > -1) {
                                htmls += "<tr style='height: 35'>";
                                htmls += "<td>" + this.GetRoomDate(this.state.RoomDetailArrays[_index]) + "</td>";
                                htmls += "<td>" + this.GetSubjectText(_index) + "</td>";
                                htmls += "</tr>";
                                _roomTitleHtml += "<tr style='height: 35'><td>" + this.state.RoomDetailArrays[_index].RoomTitle + "</td></tr>";
                            }
                        }
                        // htmls += "</tr>";
                    }
                }
                // htmls += "</table></td></tr>";
                // // htmls += "<tr style='border: 0'><td colspan='" + (timeStartGroups.length + 1) + "'>&nbsp;</td></tr>";
                // htmls += "<tr><td>&nbsp;</td></tr>";

                htmls += "</tbody></table></td><td width='50'></td><td>";
                htmls += "<table id='roomTitle_" + i + "' cellSpacing='0' cellPadding='5' border='1' style='font-weight: bold;font-weight: bold;border: 0px solid black;'>";
                htmls += "<tbody><tr style='background-color: yellow;height: 35;'><td>Room Title</td></tr>";
                htmls += _roomTitleHtml + "</tbody></table>";
                htmls += "<tr><td colspan='3'>&nbsp;</td></tr>";
            }
            htmls += "</tbody></table>";

            // //2021.04.06 === fixing Copy/Paste feature on browser, where right now permission is needed to success === start
            // const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
            // const permissionStatus = await navigator.permissions.query(queryOpts);
            // // Will be 'granted', 'denied' or 'prompt':
            // // console.log(permissionStatus.state);
            // if (navigator.clipboard !== undefined) {//Chrome
            //     await navigator.clipboard.writeText(htmls).then(function () {
            //         console.log('Copied to clipboard');
            //     }, function (err) {
            //         console.error('Could not copy text: ', err);
            //     });
            // }
            // else if (window.clipboardData) { // Internet Explorer
            //     window.clipboardData.setData("Text", htmls);
            // }
            // //2021.04.06 === end


            this.setState({
                Hidden_RoomList_TimeTable: htmls,
            });
        }
    }

    //2021.07.29
    PreviewRoomListTimeTable = () => {
        var newWindow = window.open();
        newWindow.document.body.innerHTML = "<html><head></head><body>" + this.state.Hidden_RoomList_TimeTable + "</body></html>";
    }

    //2021.07.29
    GetGroupName = (groupNumber) => {
        switch (groupNumber) {
            default: return '';
            case '1': return '一年级';
            case '2': return '二年级';
            case '3': return '三年级';
            case '4': return '四年级';
            case '5': return '五年级';
            case '6': return '六年级';
        }
    }

    //2021.07.29
    GetRoomTime = (data) => {
        let today = moment().format('YYYY-MM-DD ');
        let startTime = data.TimeStart;
        let endTime = data.TimeEnd;
        if (startTime !== undefined && endTime !== undefined)
            return moment(today + startTime).format('hh:mm A') + ' - ' + moment(today + endTime).format('hh:mm A');
        else
            return 'invalid time format';
    }

    //2021.07.29
    GetRoomDate = (data) => {
        let day = moment(data.DateStart).day();
        let dayChinese = '';
        switch (day) {
            default: dayChinese = ''; break;
            case 0: dayChinese = '星期日'; break;
            case 1: dayChinese = '星期一'; break;
            case 2: dayChinese = '星期二'; break;
            case 3: dayChinese = '星期三'; break;
            case 4: dayChinese = '星期四'; break;
            case 5: dayChinese = '星期五'; break;
            case 6: dayChinese = '星期六'; break;
        }
        return dayChinese + ' (' + moment(data.DateStart).format('Do)');
    }

    //2021.07.29
    GetSubjectText = (_index) => {
        let roomCode = this.state.RoomDetailArrays[_index].RoomCode;
        let subject = String(this.state.RoomDetailArrays[_index].Subject).toLowerCase();
        switch (subject) {
            default: return 'N/A (' + roomCode + ')';
            case 'science': return '科学 (' + roomCode + ')';
            case 'chinese': return '华语 (' + roomCode + ')';
            case 'english': return '英语 (' + roomCode + ')';
            case 'malay': return '国语 (' + roomCode + ')';
            case 'mathematics': return '数学 (' + roomCode + ')';
            case 'mixedquestions': return '多科目 (' + roomCode + ')';
        }
    }

    //2021.07.29
    GetNameOfRoomListTimeTable = () => {
        return "RoomList_TimeTable_" + this.state.liveQuizDate;
    }

    SelectRoom = (_roomId) => {
        // window.alert(_roomId);
        // this.state.JoinedPlayerEmails = [];
        // this.state.JoinedPlayerArrays = [];
        // this.state.RankingListArrays = [];
        // this.state.FinalRankingList = [];
        this.setState({
            isButtonEnabled: false,
            // JoinedPlayerEmails: this.state.JoinedPlayerEmails,
            // JoinedPlayerArrays: this.state.JoinedPlayerArrays,
            // RankingListArrays: this.state.RankingListArrays,
            // FinalRankingList: this.state.FinalRankingList,
            // JoinedPlayerEmails: [],
            // JoinedPlayerArrays: [],
            // RankingListArrays: [],
            // FinalRankingList: [],

            autoRefreshElapsedTime: 0,
        });
        // }, () => {

        let _roomIndex = 0;
        let _roomCode = 0;
        switch (uiModeState) {
            case UiMode.ShowRoomList:
                this.setState({
                    RoomDetails: '',
                    isRoomDetailsLoaded: false,
                    isShowRoomDetails: false,
                }, () => {
                    if (this.state.RoomDetailArrays.length > 0) {
                        // let _roomIndex = 0;
                        // let _roomCode = 0;
                        if (_roomId > 0) {
                            this.state.RoomDetailArrays.map((data, index) => {
                                if (data.RoomId === _roomId) {
                                    _roomIndex = index;
                                    _roomCode = data.RoomCode;
                                }
                                return null;
                            });
                        }
                        this.processRoomDetailUi(_roomIndex, _roomId, _roomCode);
                    }
                });
                break;
            case UiMode.ShowJoinedPlayers:
                this.setState({
                    isShowJoinedPlayers: true,
                    isJoinedPlayersLoaded: false,
                    // JoinedPlayerArrays: [],
                    // JoinedPlayerEmails: [],
                }, () => {
                    if (this.state.RoomDetailArrays.length > 0) {
                        // let _roomIndex = 0;
                        if (_roomId > 0) {
                            this.state.RoomDetailArrays.map((data, index) => {
                                if (data.RoomId === _roomId) {
                                    _roomIndex = index;
                                    _roomCode = data.RoomCode;
                                }
                                return null;
                            });
                        }
                        this.fetchJoinedPlayerList(_roomIndex, _roomId, _roomCode);
                    }
                });
                break;
            case UiMode.ShowRankingList:
                this.setState({
                    isShowRankingList: true,
                    isRankingListLoaded: false,
                    // JoinedPlayerEmails: [],
                    // JoinedPlayerArrays: [],
                    // RankingListArrays: [],
                    // FinalRankingList: [],
                }, () => {
                    if (this.state.RoomDetailArrays.length > 0) {
                        // let _roomIndex = 0;
                        if (_roomId > 0) {
                            this.state.RoomDetailArrays.map((data, index) => {
                                if (data.RoomId === _roomId) {
                                    _roomIndex = index;
                                    _roomCode = data.RoomCode;
                                }
                                return null;
                            });
                        }
                        this.fetchRankingList(_roomIndex, _roomId, _roomCode);
                    }
                });
                break;
            case UiMode.ShowFinalRankingList:
                this.setState({
                    isShowFinalRankingList: true,
                    isFinalRankingListLoaded: false,
                    JoinedPlayerEmails: [],
                    JoinedPlayerArrays: [],
                    RankingListArrays: [],
                    FinalRankingList: [],
                    Hidden_Ranking_List_With_Results: '',
                    // RankingListResults: [],
                }, () => {
                    if (this.state.RoomDetailArrays.length > 0) {
                        // let _roomIndex = 0;
                        if (_roomId > 0) {
                            this.state.RoomDetailArrays.map((data, index) => {
                                if (data.RoomId === _roomId) {
                                    _roomIndex = index;
                                    _roomCode = data.RoomCode;
                                }
                                return null;
                            });
                        }
                        this.fetchFinalRankingList(_roomIndex, _roomId, _roomCode);
                    }
                });
                break;
            default:
                break;
        }
        // });
    }

    processRoomDetailUi = (_index, _roomId, _roomCode) => {
        this.setState({
            selectedRoomCode: _roomCode,
            selectedRoomId: _roomId,
            selectedRoomIndex: _index,
            prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
            isRoomDetailsLoaded: true,
            RoomDetails: '',
            singleQuestionDetailArray: [],
            isShowQuestionDetailHtml: false,
        }, () => {
            //Draw Ui.
            let _selectedQuestionsUi = "<table border={1} borderColor='white' bgcolor={'#2e2e2e'}><tr>";

            //2020.07.16
            if (this.state.RoomDetailArrays[_index].Grade === 'L' || this.state.RoomDetailArrays[_index].Grade === 'U') {
                _selectedQuestionsUi += "<td colspan='10' align='center' style='color:blue;'>These questions are not available to view online at the moment.</td></tr><tr>";
            }

            this.state.RoomDetailArrays[_index].Questions.split(';')
                .map((qs, key) => {
                    if (qs.length > 0) {
                        // {
                        _selectedQuestionsUi += "<td width=50 align='center' id='" + qs + "'>"
                            + "<a href='#' id='" + qs + "' >&nbsp;"
                            + qs
                            + "&nbsp;</a></td>";
                        if (key > 0 && (key + 1) % 10 === 0) {
                            _selectedQuestionsUi += "</tr><tr>";
                        }
                        // }
                    }
                    return null;
                })
            _selectedQuestionsUi += "</tr></table>";

            let _roomDetails_temp = <table>
                <tr><td style={{ color: 'yellow' }}>Room Details</td></tr>
                <tr><td>Room Code </td><td>: {this.state.RoomDetailArrays[_index].RoomCode} </td></tr>
                <tr><td>Room Id </td><td>: {this.state.RoomDetailArrays[_index].RoomId} </td></tr>
                <tr><td>Title </td><td>: {this.state.RoomDetailArrays[_index].RoomTitle} </td></tr>
                <tr><td>Subject </td><td><div dangerouslySetInnerHTML={{ __html: this.GetSubjectName(_index) }}></div></td></tr>
                <tr><td>Group </td><td>: {this.state.RoomDetailArrays[_index].Grade} </td></tr>
                <tr><td>Total Duration </td><td>: {this.GetDurationInText(this.state.RoomDetailArrays[_index].Duration)} ({this.state.RoomDetailArrays[_index].Duration} sec) </td></tr>
                <tr><td>Duration Per Question &nbsp;</td><td>: {this.state.RoomDetailArrays[_index].DurationPerQuestion} sec</td></tr>
                <tr><td>Question Set </td><td>: {this.state.RoomDetailArrays[_index].QnSet} </td></tr>
                <tr><td>Total Question </td><td>: {this.state.RoomDetailArrays[_index].QnQty} </td></tr>
                {/* <tr><td>Selected Questions </td><td>: {this.state.RoomDetailArrays[_index].Questions.split(';').map(qs => <p>{qs}</p>)} </td></tr> */}
                {/* <tr><td>Selected Questions </td><td>: {this.state.RoomDetailArrays[_index].Questions.replace(/;/g, '\n')} </td></tr> */}

                <tr><td></td></tr>
                <tr><td>Start Time </td><td>: {
                    this.state.RoomDetailArrays[_index].StartTime === '_none' ?
                        <font color='yellow'>Quiz is available for today ({moment(this.state.selectedDate).format('ll')}) until 12 AM.</font>
                        :
                        <>{this.state.RoomDetailArrays[_index].StartTime} <font color='yellow'>({moment(this.state.RoomDetailArrays[_index].StartTime).format('lll')})</font></>
                }</td></tr>
                <tr><td>End Time </td><td>: {
                    this.state.RoomDetailArrays[_index].EndTime === '_none' ?
                        <font color='yellow'>Quiz is available for today ({moment(this.state.selectedDate).format('ll')}) until 12 AM.</font>
                        :
                        <>{this.state.RoomDetailArrays[_index].EndTime} <font color='yellow'>({moment(this.state.RoomDetailArrays[_index].EndTime).format('lll')})</font></>
                }</td></tr>
                <tr><td></td></tr>

                <tr><td valign={'top'}>Selected Questions </td>
                    <td>:
                        <div
                            onClick={this.fetchQuestion}
                            dangerouslySetInnerHTML={{ __html: _selectedQuestionsUi }}
                        />
                    </td>
                </tr>

                <tr>
                    <td colSpan='2' valign='bottom' height='75'>
                        <button
                            className='btn btn-secondary'
                            disabled={moment() > moment(this.state.liveQuizDate).add(1, 'day')}
                            onClick={() => this.RemoveSelectedRoom(this.state.RoomDetailArrays[_index].RoomCode, this.state.RoomDetailArrays[_index].RoomId)}
                        >Remove this Room ({this.state.RoomDetailArrays[_index].RoomCode + '/' + this.state.RoomDetailArrays[_index].RoomId}) {
                                moment() > moment(this.state.liveQuizDate).add(1, 'day') ?
                                    "(disabled after 24hrs on current Quiz's Date)"
                                    : ''
                            }</button>
                    </td>
                </tr>
            </table>;

            //Show Ui.
            // _QsUiTopPos = '0px';
            this.setState({
                // QsUiTopPos: '0px',
                RoomDetails: _roomDetails_temp,
                isShowRoomDetails: true,
                isButtonEnabled: true,
                // });
            }, async () => {
                // await this.LoadQuizQuestions();
            });
            // window.alert(this.state.selectedRoomId);
        });
    }

    RemoveSelectedRoom = async (_roomCode, _roomId) => {
        let confirmRemoveRoom = window.confirm('Are you sure you really want to remove this room ?\nOnce confirmed, this action is not reversible.\nClick confirm to continue, or Cancel to skip this action.');
        if (confirmRemoveRoom) {
            let _securityCode = window.prompt('Enter Admin Code to continue', '');
            if (_securityCode === '8271653') {
                alert('Admin Code validated, proceed to remove selected Room.');

                //Remove record on FireStore > LiveQuiz_UniqueRoomCode
                await this.props.dbFireStore
                    .collection("LiveQuiz_UniqueRoomCode")
                    .doc(_roomId)
                    .delete()
                    .then(() => {
                        console.log('Room (' + _roomCode + '/' + _roomId + ') has been removed from FS.');
                    })
                    .catch((error) => {
                        console.log('Room (' + _roomCode + '/' + _roomId + ') removal action encountered error.\n' + error);
                    });

                //Remove records on RTDB > https://ikeyedutech-student-5ea3c-pkquiz.firebaseio.com/pkquiz/{quizDate} 
                //3 location > pkquiz-room-detail (roomid) , pkquiz-room-code (roomcode) , pkquiz-live (roomid)

                //pkquiz-room-detail
                await this.roomDetailPathByRoomId(_roomId)
                    .remove()
                    .then(() => {
                        console.log('Room (' + _roomCode + '/' + _roomId + ') has been removed from RTDB (pkquiz-room-detail).');
                    })
                    .catch((error) => {
                        console.log('Room (' + _roomCode + '/' + _roomId + ') removal action encountered error. (pkquiz-room-detail)\n' + error);
                    });

                //pkquiz-room-code
                await this.roomCodePathByRoomCode(_roomCode)
                    .remove()
                    .then(() => {
                        console.log('Room (' + _roomCode + '/' + _roomId + ') has been removed from RTDB (pkquiz-room-code).');
                    })
                    .catch((error) => {
                        console.log('Room (' + _roomCode + '/' + _roomId + ') removal action encountered error. (pkquiz-room-code)\n' + error);
                    });

                //pkquiz-live
                await this.liveQuizRoomStatePath(_roomId)
                    .remove()
                    .then(() => {
                        console.log('Room (' + _roomCode + '/' + _roomId + ') has been removed from RTDB (pkquiz-live).');
                    })
                    .catch((error) => {
                        console.log('Room (' + _roomCode + '/' + _roomId + ') removal action encountered error. (pkquiz-live)\n' + error);
                    });

                //reload Room List & Ui.
                this.onCalenderChange(this.state.selectedDate);
            }
            else {
                alert('Invalid Admin Code.');
            }
        }
    }

    GetSubjectName = (_index) => {
        let textToReturn = ": " + this.state.RoomDetailArrays[_index].Subject;
        if (String(this.state.RoomDetailArrays[_index].Subject).toUpperCase() === "SEC_BM_COLIN") {
            textToReturn += " (aka <span style='color:yellow;'>Colin's BM</span>)";
        }
        return textToReturn;
    }

    GetDurationInText = (_totalSeconds) => {
        var minutes = (_totalSeconds / 60).toFixed(3).split('.')[0];
        var seconds = (_totalSeconds % 60).toString();
        return minutes + ' min ' + seconds + ' sec';
    }

    fetchQuestion = (e) => {
        // window.alert(e);
        let _id = e.target.id;
        // window.alert(_id);

        if (_id != null && _id > 0) {
            this.setState({
                singleQuestionDetailArray: [],
                singleQuestionDetailHtml: '',
                isShowQuestionDetailHtml: false,
            }, () => {
                this.questionPath(this.state.selectedRoomIndex, _id).once('value', snapshot => {
                    this.processSingleQuestionUi(snapshot.val(), _id);
                });
            });
        }
    }

    processSingleQuestionUi = (dataSnapVal, _id) => {
        if (dataSnapVal == null) {
            window.alert('This question is not available to view online at the moment.');
            return;
        }

        let dataKeys = _(dataSnapVal).keys().value();       //key
        let dataValues = _(dataSnapVal).values().value();   //values

        // window.alert(dataKeys[0].toString() + " : " + dataValues[0].toString());
        // window.alert(dataKeys[0] + " : " + _(dataValues[0]).value());

        let _singleQuestionDetailArray = [];
        dataKeys.map((data, key) => {
            // this.state.singleQuestionDetailArray[data] = _(dataValues[key]).value();
            // window.alert(data + " : " + this.state.singleQuestionDetailArray[data]);
            return _singleQuestionDetailArray[data] = _(dataValues[key]).value();
        });

        this.setState({
            // singleQuestionDetailArray: this.state.singleQuestionDetailArray,
            singleQuestionDetailArray: _singleQuestionDetailArray,
        }, () => {
            let formatedQuestionUi = "<center><table>";
            formatedQuestionUi += "<tr><td colspan='3'><b><u><font color='yellow' size=5>Question #" + _id + "</font></u></b></td></tr>";
            // formatedQuestionUi += "<tr><td>Question </td><td>&nbsp; : &nbsp;</td><td>" + this.state.singleQuestionDetailArray['Content'] + "</td></tr>";

            //2020.12.31
            if (
                String(this.state.singleQuestionDetailArray['Content']).replace(' ', '').length > 0
                && String(this.state.singleQuestionDetailArray['Content']) !== "<subjective-instruction>"
            ) {
                formatedQuestionUi += "<tr><td>Question </td><td>&nbsp; : &nbsp;</td><td>" + this.state.singleQuestionDetailArray['Content'] + "</td></tr>";
            }

            // formatedQuestion += "<tr><td>Answer </td><td> : " + this.state.singleQuestionDetailArray['Answer'] + "</td></tr>";
            // formatedQuestion += "<tr><td valign='top' colspan='3'>Options </td></tr><tr><td colspan='2'></td><td>";
            if (this.state.singleQuestionDetailArray['PictureUrl'].length > 0) {
                formatedQuestionUi += "<tr><td>Picture</td><td>&nbsp; : &nbsp;</td><td><img width='35%' src='"
                    + "https://ikeynew.blob.core.windows.net/ikeykidz/JPG/"
                    + this.state.singleQuestionDetailArray['PictureUrl'] + ".jpg' alt='' /></td></tr>";
            }
            else {
                formatedQuestionUi += "<tr><td colspan='3' height=25></td></tr>";
            }

            //2020.12.31
            if (this.state.singleQuestionDetailArray['Selection'].length <= 0) {
                formatedQuestionUi += "<tr><td>Answer</td><td>&nbsp; : &nbsp;</td><td>";
                formatedQuestionUi += this.state.singleQuestionDetailArray['Answer'];
            }
            else {
                formatedQuestionUi += "<tr><td valign='top' colspan='2'>Options </td><td>";
                formatedQuestionUi += "<table>";
                let answerOptions = this.state.singleQuestionDetailArray['Selection'].split(';');
                answerOptions.map((data, key) => {
                    if (data.length > 0) {
                        formatedQuestionUi +=
                            data.split(':')[1] === '' ? '' :
                                "<tr><td>"
                                + (
                                    this.state.singleQuestionDetailArray['Answer'] === data.split(':')[0] ?
                                        "<b><u><font color='yellow'>" + data.split(':')[0] + "</font></u></b>"
                                        :
                                        data.split(':')[0]
                                )
                                +
                                (
                                    data.split(':')[1].includes('_pk_') ?
                                        "&nbsp;&nbsp;<img src='https://ikeynew.blob.core.windows.net/ikeykidz/JPG/" + data.split(':')[1] + ".jpg' alt='' width='70%' />"
                                        :
                                        " </td><td>: " + data.split(':')[1] + "</td></tr>"
                                )
                    }
                    return null;
                });
                formatedQuestionUi += "</table>";
            }
            formatedQuestionUi += "</td></tr>";
            formatedQuestionUi += "<tr><td align='right' colspan='3'><button id='closeQsDetailUi' class='buttonStyleCloseUi'>Close</button></td></tr>";
            // formatedQuestionUi += "<tr><td align='right' colspan='3'><Button id='closeQsDetailUi' variant='contained' color='primary'>Close</Button></td></tr>";
            formatedQuestionUi += "</table></center>"
            // window.alert(formatedQuestion);

            // _QsUiTopPos = '200px';   
            this.setState({
                // QsUiTopPos: '200px',
                singleQuestionDetailHtml: formatedQuestionUi,
                isShowQuestionDetailHtml: true,
            });
        });
    }

    closeQsDetailUi = (e) => {
        let _id = e.target.id;
        if (_id != null && _id === 'closeQsDetailUi') {
            this.setState({
                singleQuestionDetailArray: [],
                singleQuestionDetailHtml: '',
                isShowQuestionDetailHtml: false,
            });
        }
    }

    fetchJoinedPlayerList = (_roomIndex, _roomId, _roomCode) => {
        // let date = moment(this.state.selectedDate).format('YYYYMMDD');
        this.setState({
            selectedRoomCode: _roomCode,
            selectedRoomId: _roomId,
            selectedRoomIndex: _roomIndex,
            prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
        }, async () => {
            await this.joinedPlayersPathV2(this.state.liveQuizDate, _roomId).once('value', async (snapshot) => {
                await this.processJoinedPlayersData(snapshot.val());
            });
        });
    }

    processJoinedPlayersData = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        // window.alert(_(dataValues[0]).values().value()[0]); //correct

        this.setState({
            JoinedPlayerArrays: [],
            JoinedPlayerEmails: [],
        });

        // await Promise.all(
        // this.state.liveQuizDate > 20200607 ?
        dataKeys.map(async (data, key) => {
            // if (data.indexOf('%') < 0) {                        
            //     await this.userEmailPath(data).once('value', snapshot => {
            //         return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
            //     });
            // }
            // else {
            //     return this.state.JoinedPlayerEmails.push('N/A');
            // }
            // await this.userEmailPath(data).once('value', snapshot => {
            //     return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
            // });

            let dataSet = dataValues[key].split(';');

            let _uid = data.length >= 28 && data.indexOf(' ') === -1 ? data : dataSet[2];
            let _email = '';
            // let uid = uidRegex.test(data) ? data : dataValues[key].split(';')[2].toString();
            // let _uid = data.length >= 28 && data.indexOf(' ') === -1 ? data : dataValues[key].split(';')[2].toString();

            await this.userEmailPath(_uid).once('value', snapshot => {
                // return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
                return _email = snapshot.val();
            });

            //2020.12.09
            let _name = dataSet[2];
            if (_name.length <= 0) {
                await this.userNamePath(_uid).once('value', snapshot => {
                    // return this.state.JoinedPlayerEmails.push(snapshot.val());
                    return _name = snapshot.val();
                });
                // alert(_name);
            }

            //2020.12.12
            if (_name.length <= 0) {
                await this.props.dbFireStore.collection("User")
                    .doc(_uid)
                    .get()
                    .then((querySnapshot) => {
                        let data = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                data.push(doc.data());
                            });
                        }
                        if (data.length > 0) {
                            _name = data[0]['Name'];
                        }
                    });
                // alert(_name);
            }

            // if (_email === 'yaphs3989@gmail.com') {
            //     alert(_name + "\n\n" + _email);
            // }
            alert(_name);

            let _emails = this.state.JoinedPlayerEmails;

            if (_emails.findIndex(element => element.email === _email) < 0) {
                _emails.push(
                    {
                        uid: _uid,
                        email: _email,
                        pname: _name.length > 0 ? _name : _email.split('@')[0],    //2020.12.09
                    }
                );
            }
            this.setState({
                JoinedPlayerEmails: _emails,
            });
        });
        // :
        // dataValues.map(async (data, key) => {
        //     // if (data.split(';')[2].indexOf('%') < 0) {
        //     //     await this.userEmailPath(data.split(';')[2]).once('value', snapshot => {
        //     //         return this.state.JoinedPlayerEmails.push(snapshot.val());
        //     //     });
        //     // }
        //     // else {                        
        //     //     return this.state.JoinedPlayerEmails.push('N/A');
        //     // }
        //     let _email = '';
        //     let _uid = data.split(';')[2];
        //     await this.userEmailPath(_uid).once('value', snapshot => {
        //         // return this.state.JoinedPlayerEmails.push(snapshot.val());
        //         return _email = snapshot.val();
        //     });

        //     //2020.12.09
        //     let _name = '';
        //     await this.userNamePath(_uid).once('value', snapshot => {
        //         // return this.state.JoinedPlayerEmails.push(snapshot.val());
        //         return _name = snapshot.val();
        //     });

        //     //2020.12.12
        //     if (_name.length <= 0) {
        //         await this.props.dbFireStore.collection("User")
        //             .where('Uid', '==', _uid)
        //             .get()
        //             .then((querySnapshot) => {
        //                 let data = [];
        //                 if (querySnapshot !== null) {
        //                     querySnapshot.forEach((doc) => {
        //                         data.push(doc.data());
        //                     });
        //                 }
        //                 if (data.length > 0) {
        //                     _name = data[0]['Name'];
        //                 }
        //             });
        //     }


        //     if (this.state.JoinedPlayerEmails.findIndex(element => element.email === _email) < 0) {
        //         this.state.JoinedPlayerEmails.push(
        //             {
        //                 uid: _uid,
        //                 email: _email,
        //                 pname: _name.length > 0 ? _name : _email.split('@')[0],    //2020.12.09
        //             }
        //         );
        //     }
        //     this.setState({
        //         JoinedPlayerEmails: this.state.JoinedPlayerEmails,
        //     });
        // })
        // );
        // // .then(() => {
        // //     this.setState({
        // //         JoinedPlayerEmails: this.state.JoinedPlayerEmails,
        // //     }, () => {
        // //         // window.alert(this.state.JoinedPlayerEmails.length);
        // //     });
        // // })
        // .then(() => {
        //     dataKeys.map((data, key) => {
        //         let dataVal = _(dataValues[key]).value().split(';');
        //         let _isVerNew = data.length >= 28 && data.indexOf(' ') === -1 ? true : false;
        //         if (this.state.liveQuizDate > 20200607) {   //  (/^\s*$/.test(dataVal[2])) = contains any space
        //             // if (this.state.liveQuizDate > 20200607 || !(/^\s*$/.test(dataVal[2]))) {
        //             let _pname = data.length >= 28 && data.indexOf(' ') === -1 ?
        //                 unescape(dataVal[2])
        //                 : unescape(data);

        //             let _uid = data.length >= 28 && data.indexOf(' ') === -1 ?
        //                 data
        //                 : dataValues[key].split(';')[2].toString();

        //             //2020.12.09
        //             if (_pname.length <= 0) {
        //                 _pname = this.state.JoinedPlayerEmails.find(x => x.uid === _uid).pname;
        //             }

        //             return this.state.JoinedPlayerArrays.push({
        //                 // PlayerName: !(/^\s*$/.test(unescape(dataVal[2]))) ? unescape(dataVal[2]) : data,
        //                 // PlayerName: data.length >= 28 && data.indexOf(' ') === -1 ? unescape(dataVal[2]) : data,
        //                 PlayerName: _pname,
        //                 Scores: dataVal[0],
        //                 TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
        //                 IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
        //                 Uid: _uid,
        //                 // Email: this.state.JoinedPlayerEmails[key + 1],
        //                 Email: this.state.JoinedPlayerEmails.find(x => x.uid === _uid).email,
        //                 Version: _isVerNew,
        //             });
        //         }
        //         else {

        //             //2020.12.09
        //             let _pname = unescape(data);
        //             if (_pname.length <= 0) {
        //                 _pname = this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).pname;
        //             }

        //             return this.state.JoinedPlayerArrays.push({
        //                 // PlayerName: unescape(data),
        //                 PlayerName: _pname,
        //                 Scores: dataVal[0],
        //                 TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
        //                 IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
        //                 Uid: dataVal[2],
        //                 // Email: this.state.JoinedPlayerEmails[key + 1],
        //                 Email: this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).email,
        //                 Version: _isVerNew,
        //             });
        //         }
        //     });
        // })
        // .then(() => {
        //     //Sort List according to Name in ASC
        //     this.state.JoinedPlayerArrays.sort(function (a, b) {
        //         if (a.PlayerName < b.PlayerName) { return -1; }
        //         if (a.PlayerName > b.PlayerName) { return 1; }
        //         return 0;
        //     });

        //     //Show Ui & Joined Players List
        //     this.setState({
        //         JoinedPlayerArrays: this.state.JoinedPlayerArrays,
        //         isJoinedPlayersLoaded: true,
        //         isButtonEnabled: true,
        //     });
        // });

        dataKeys.map((data, key) => {
            let dataVal = _(dataValues[key]).value().split(';');
            let _isVerNew = data.length >= 28 && data.indexOf(' ') === -1 ? true : false;
            if (this.state.liveQuizDate > 20200607) {   //  (/^\s*$/.test(dataVal[2])) = contains any space
                // if (this.state.liveQuizDate > 20200607 || !(/^\s*$/.test(dataVal[2]))) {
                let _pname = data.length >= 28 && data.indexOf(' ') === -1 ?
                    unescape(dataVal[2])
                    : unescape(data);

                let _uid = data.length >= 28 && data.indexOf(' ') === -1 ?
                    data
                    : dataValues[key].split(';')[2].toString();

                //2020.12.09
                if (_pname.length <= 0) {
                    _pname = this.state.JoinedPlayerEmails.find(x => x.uid === _uid).pname;
                }

                return this.state.JoinedPlayerArrays.push({
                    // PlayerName: !(/^\s*$/.test(unescape(dataVal[2]))) ? unescape(dataVal[2]) : data,
                    // PlayerName: data.length >= 28 && data.indexOf(' ') === -1 ? unescape(dataVal[2]) : data,
                    PlayerName: _pname,
                    Scores: dataVal[0],
                    TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                    IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                    Uid: _uid,
                    // Email: this.state.JoinedPlayerEmails[key + 1],
                    Email: this.state.JoinedPlayerEmails.find(x => x.uid === _uid).email,
                    Version: _isVerNew,
                });
            }
            else {

                //2020.12.09
                let _pname = unescape(data);
                if (_pname.length <= 0) {
                    _pname = this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).pname;
                }

                return this.state.JoinedPlayerArrays.push({
                    // PlayerName: unescape(data),
                    PlayerName: _pname,
                    Scores: dataVal[0],
                    TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                    IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                    Uid: dataVal[2],
                    // Email: this.state.JoinedPlayerEmails[key + 1],
                    Email: this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).email,
                    Version: _isVerNew,
                });
            }
        });

        //Sort List according to Name in ASC
        this.state.JoinedPlayerArrays.sort(function (a, b) {
            if (a.PlayerName < b.PlayerName) { return -1; }
            if (a.PlayerName > b.PlayerName) { return 1; }
            return 0;
        });

        //Show Ui & Joined Players List
        this.setState({
            JoinedPlayerArrays: this.state.JoinedPlayerArrays,
            isJoinedPlayersLoaded: true,
            isButtonEnabled: true,
        });
    }

    // async fetchEmail(_uid) {
    //     this.userEmailPath(_uid).once('value', snapshot => {
    //         // window.alert(snapshot.val());
    //         return snapshot.val();
    //     });
    // }

    //New created 2020.07.01
    fetchFinalRankingList = (_roomIndex, _roomId, _roomCode) => {
        ProgressionStateText = 'Downloading data...';
        this.setState({
            selectedRoomCode: _roomCode,
            selectedRoomId: _roomId,
            selectedRoomIndex: _roomIndex,
            prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
            JoinedPlayerEmails: [],
            JoinedPlayerArrays: [],
            RankingListArrays: [],
            FinalRankingList: [],
            // });
        }, async () => {
            //         this.fetchFinalRankingList_Stage1(_roomIndex, _roomId, _roomCode);
            //     });
            // }

            // fetchFinalRankingList_Stage1 = async (_roomIndex, _roomId, _roomCode) => {

            // let _FinalRankingList = [];

            // this.setState({
            //     prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
            //     // });
            // }, async () => {
            //     this.setState({
            //         selectedRoomId: _roomId,
            //         selectedRoomIndex: _roomIndex,
            //         // prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
            //         // JoinedPlayerEmails: [],
            //         // JoinedPlayerArrays: [],
            //         // RankingListArrays: [],
            //         // FinalRankingList: [],
            //     });
            // });
            // }, async () => {

            // if (
            //     this.state.JoinedPlayerEmails.length > 0
            //     || this.state.JoinedPlayerArrays.length > 0
            //     || this.state.RankingListArrays.length > 0
            //     || this.state.FinalRankingList.length > 0
            // ) {
            //     setTimeout(() => {
            //         this.setState({
            //             JoinedPlayerEmails: [],
            //             JoinedPlayerArrays: [],
            //             RankingListArrays: [],
            //             FinalRankingList: [],
            //         });
            //     }, 0);
            // }
            // setTimeout(() => {
            //     
            // }, 500);

            // this.setState({
            //     dummy: _roomId,
            // });

            //Get Joined Players arrays.
            let _snapShot = [];
            await this.joinedPlayersPathV2(this.state.liveQuizDate, _roomId).once('value', snapshot => {
                if (snapshot.exists()) {
                    // _snapShot = Object.assign({}, snapshot.val());
                    let _dataString = JSON.stringify(snapshot.val());
                    // console.log(_dataString);
                    _dataString = _dataString.replace(new RegExp('{', 'g'), '');
                    _dataString = _dataString.replace(new RegExp('}', 'g'), '');
                    _dataString = _dataString.replace(new RegExp('"', 'g'), '');
                    let _sourceArray = _dataString.split(',');
                    let _dataArray = [];
                    for (var k = 0; k < _sourceArray.length; k++) {
                        let splits = _sourceArray[k].split(':');
                        _dataArray.push({ Uid: splits[0], Value: splits[1] });
                    }
                    _snapShot = _dataArray;
                    console.log(JSON.stringify(_dataArray));

                    // let _source = snapshot.val();
                    // let _array = [];
                    // _source.map((data, key) => {
                    //     _dataArray.push({ Uid: , Value: splits[1] });
                    // });
                }
            });
            // console.log(JSON.stringify(_snapShot));
            await this.processJoinedPlayersDataV3(_snapShot);
            // console.log(JSON.stringify(this.state.JoinedPlayerArrays));

            if (this.state.liveQuizDate <= this.state.versionDivisionDate) {
                //Get Ranking List arrays.
                await this.rankingListPath(this.state.liveQuizDate).once('value', snapshot => {
                    _snapShot = snapshot.val();
                });
                await this.processRankingListDataV3(_snapShot);
                // console.log(_snapShot.length);
            }

            //#region older pattern.
            //Combine both list.
            // () => {
            // let combinedList = [];
            // let date = moment.unix(this.state.selectedRoomId / 1000);
            let date = moment(this.state.selectedRoomId);
            let _FinalRankingList = this.state.FinalRankingList;
            Promise.all(
                this.state.JoinedPlayerArrays.map(async (data, key1) => {
                    //2020.07.27
                    let isLogsExisted = false;
                    let actualTimeConsumed = 0;
                    let quizStartTimeTicks = 0;
                    let isQuizStartTimeGet = false;
                    let quizCompletedTimeTicks = 0;
                    let isQuizCompletedTimeGet = false;
                    let score = 0;

                    //edited on 2020.11.11
                    // personalScoreRecordPath(_date, _roomId, _uid)
                    let theDate = moment(this.state.selectedDate);
                    if (theDate > moment('20201019', 'YYYYMMDD')) {
                        isLogsExisted = true;
                        await this.personalScoreRecordPath(theDate.format('YYYYMMDD'), this.state.selectedRoomId, data.Uid).once('value', snapshot => {
                            if (snapshot.exists()) {
                                let splitData = String(snapshot.val()).split(';');
                                actualTimeConsumed = Number(splitData[1]);
                                score = Number(splitData[0]);
                            }
                        });

                        // if (data.Uid === 'VIZUL0z28rTwLupGn2CYjkoWnwo2') {
                        //     console.log('Score = ' + score + '\nTime Consumed = ' + actualTimeConsumed);
                        // }

                        //added 2020.11.13  //to counter user still using app.
                        // if (score > 100.00 || String(score).includes('.00') === false || String(score).includes('.50') === false) {
                        if (score > 100.00 || String(score).includes('.') === false || actualTimeConsumed <= 0) {

                            //find the correct actual time consumed.
                            await this.logsPath(data.Uid).once('value', snapshot => {
                                if (snapshot.exists) {
                                    isLogsExisted = true;

                                    let dataKeys = _(snapshot.val()).keys().value();       //key = time
                                    let dataValues = _(snapshot.val()).values().value();   //values = log message

                                    dataKeys.map((data, key) => {
                                        if (dataValues[key].toString().includes('Quiz Started')
                                            && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                            && !isQuizStartTimeGet) {
                                            isQuizStartTimeGet = true;
                                            quizStartTimeTicks = this.GetConvertedTime(data, date);
                                        }
                                        if (dataValues[key].toString().includes('Quiz Completed')
                                            && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                            && !isQuizCompletedTimeGet) {
                                            isQuizCompletedTimeGet = true;
                                            quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                        }
                                        if (dataValues[key].toString().includes('Exit')
                                            && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                            && isQuizStartTimeGet
                                            && !isQuizCompletedTimeGet) {
                                            quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                        }
                                        return null;
                                    });

                                    if (quizStartTimeTicks > 0 && quizCompletedTimeTicks > 0) {
                                        actualTimeConsumed = (quizCompletedTimeTicks - quizStartTimeTicks) / 1000;
                                        // alert(quizCompletedTimeTicks + " | " + quizStartTimeTicks + " | " + actualTimeConsumed);
                                    }
                                }
                            });

                            //find the actual score received.
                            await this.personalQResultPath(theDate.format('YYYYMMDD'), this.state.selectedRoomId, data.Uid)
                                .once('value', snapshot => {
                                    if (snapshot.exists()) {
                                        let splitData = String(snapshot.val()).split(':');
                                        let correct = 0;
                                        if (splitData.length > 0) {
                                            splitData.map((data, key) => {
                                                if (data === '1')
                                                    correct++;
                                                return null;
                                            });
                                            score = (correct / splitData.length) * 100;
                                        }
                                    }
                                });

                            data.TimeElapsed = actualTimeConsumed;
                            data.Scores = score.toFixed(2);
                        }

                        // if (data.Uid === 'VIZUL0z28rTwLupGn2CYjkoWnwo2') {
                        //     console.log('Score = ' + score + '\nTime Consumed = ' + actualTimeConsumed);

                        //     // if (actualTimeConsumed < 0)
                        // }
                    }
                    else {
                        await this.logsPath(data.Uid).once('value', snapshot => {
                            if (snapshot.exists()) {
                                isLogsExisted = true;

                                let dataKeys = _(snapshot.val()).keys().value();       //key = time
                                let dataValues = _(snapshot.val()).values().value();   //values = log message

                                dataKeys.map((data, key) => {
                                    if (dataValues[key].toString().includes('Quiz Started')
                                        && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                        && !isQuizStartTimeGet) {
                                        isQuizStartTimeGet = true;
                                        // let hr = data.substring(0, 2);
                                        // let min = data.substring(2, 4);
                                        // let sec = data.substring(4, 6);
                                        // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                                        // quizStartTimeTicks = moment(dateTime).add(8, 'hours').format('x');
                                        quizStartTimeTicks = this.GetConvertedTime(data, date);
                                    }
                                    // if (dataValues[key].toString().includes('Exit')
                                    //     && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                    //     && isQuizStartTimeGet
                                    //     && !isQuizCompletedTimeGet) {
                                    //     // let hr = data.substring(0, 2);
                                    //     // let min = data.substring(2, 4);
                                    //     // let sec = data.substring(4, 6);
                                    //     // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                                    //     // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
                                    //     quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                    // }
                                    if (dataValues[key].toString().includes('Quiz Completed')
                                        && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                        && !isQuizCompletedTimeGet) {
                                        isQuizCompletedTimeGet = true;
                                        // let hr = data.substring(0, 2);
                                        // let min = data.substring(2, 4);
                                        // let sec = data.substring(4, 6);
                                        // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                                        // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
                                        quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                    }
                                    if (dataValues[key].toString().includes('Exit')
                                        && dataValues[key].toString().includes(this.state.selectedRoomCode)
                                        && isQuizStartTimeGet
                                        && !isQuizCompletedTimeGet) {
                                        // let hr = data.substring(0, 2);
                                        // let min = data.substring(2, 4);
                                        // let sec = data.substring(4, 6);
                                        // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
                                        // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
                                        quizCompletedTimeTicks = this.GetConvertedTime(data, date);
                                    }
                                    return null;
                                });

                                // if (quizStartTimeTicks == 0) {

                                // }

                                if (quizStartTimeTicks > 0 && quizCompletedTimeTicks > 0) {
                                    actualTimeConsumed = (quizCompletedTimeTicks - quizStartTimeTicks) / 1000;
                                    // alert(quizCompletedTimeTicks + " | " + quizStartTimeTicks + " | " + actualTimeConsumed);
                                }
                            }
                        });
                    }

                    // let _FinalRankingList = this.state.FinalRankingList;
                    _FinalRankingList.push({
                        PlayerName: data.PlayerName,
                        Scores: data.Scores,
                        TimeElapsed: actualTimeConsumed > 0 ? actualTimeConsumed : data.TimeElapsed,
                        ActualTimeElapsed: data.TimeElapsed,
                        // TimeElapsed: data.TimeElapsed,
                        // ActualTimeElapsed: actualTimeConsumed > 0 ? actualTimeConsumed : data.TimeElapsed,
                        IsCompleted: data.IsCompleted,
                        Uid: data.Uid,
                        Email: '',
                        Version: isLogsExisted, //data.Version,
                        Duplicated: false,
                        Qty: 0,
                        // Rank: 0,
                        CorrectQty: 0,
                        WrongQty: 0,
                        IsWeb: false,
                    });
                    // this.setState({ FinalRankingList: _FinalRankingList });
                    return null;
                })
            )
                .then(() => {

                    // let _FinalRankingList = this.state.FinalRankingList;
                    if (this.state.liveQuizDate <= this.state.versionDivisionDate) {
                        this.state.RankingListArrays.map((data1, key1) => {
                            let pickedData;
                            _FinalRankingList.map((data2, key2) => {
                                // _FinalRankingList = this.state.FinalRankingList;
                                if (data1.Uid === data2.Uid) {
                                    // let isDuped = false;
                                    if (parseInt(data1.Scores) > parseInt(data2.Scores)) {  //Score 1 higher.
                                        pickedData = data1;
                                    }
                                    else if (parseInt(data1.Scores) < parseInt(data2.Scores)) { //Score 1 lower.
                                        pickedData = data2;
                                    }
                                    else {  //Same Score.
                                        if (parseFloat(data1.TimeElapsed) <= parseFloat(data2.TimeElapsed)) {    //TimeElapsed 1 lesser, or =Zero.
                                            pickedData = data1;
                                        }
                                        else if (parseFloat(data1.TimeElapsed) > parseFloat(data2.TimeElapsed)) {    //TimeElapsed 1 more.
                                            pickedData = data2;
                                        }
                                        else {
                                            pickedData = data1; //default if no matches.
                                            // isDuped = true;
                                        }
                                    }
                                    _FinalRankingList[key2].Scores = pickedData.Scores;
                                    _FinalRankingList[key2].TimeElapsed = pickedData.TimeElapsed;
                                    // this.state.FinalRankingList[key2].Duplicated = isDuped;
                                    _FinalRankingList[key2].Qty += 1;
                                    // this.setState({ FinalRankingList: _FinalRankingList });
                                }
                                return null;
                            });
                            // _FinalRankingList = this.state.FinalRankingList;
                            if (pickedData == null) {
                                // combinedList.push({
                                //     PlayerName: pickedData.PlayerName,
                                //     Scores: pickedData.Scores,
                                //     TimeElapsed: pickedData.TimeElapsed,
                                //     IsCompleted: pickedData.IsCompleted,
                                //     Uid: pickedData.Uid,
                                //     Email: '',
                                // });
                                _FinalRankingList.push({
                                    PlayerName: this.state.RankingListArrays[key1].PlayerName,
                                    Scores: this.state.RankingListArrays[key1].Scores,
                                    TimeElapsed: this.state.RankingListArrays[key1].TimeElapsed,
                                    ActualTimeElapsed: this.state.RankingListArrays[key1].ActualTimeElapsed,    //new 2020-10-19
                                    IsCompleted: this.state.RankingListArrays[key1].IsCompleted,
                                    Uid: this.state.RankingListArrays[key1].Uid,
                                    Email: '',
                                    Version: this.state.RankingListArrays[key1].Version,
                                    Duplicated: false,
                                    Qty: 1,
                                    // Rank: 0,
                                    CorrectQty: 0,
                                    WrongQty: 0,
                                    IsWeb: false,
                                });
                            }
                            // this.setState({
                            //     FinalRankingList: _FinalRankingList,
                            //     // FinalRankingList: combinedList,
                            // });
                            return null;
                        });
                    }
                    // console.log('line = 1824');
                    this.setState({
                        isButtonEnabled: false,
                        FinalRankingList: _FinalRankingList,
                        // FinalRankingList: combinedList,
                        dupeQtyForFRList: 0,
                    }, async () => {

                        //Find Email & fill-in.
                        // () => {
                        let _dupeQty = 0;
                        let _FinalRankingList = this.state.FinalRankingList;
                        await Promise.all(
                            _FinalRankingList.map(async (data, key) => {
                                if (_FinalRankingList[key].Qty > 1) {
                                    _FinalRankingList[key].Duplicated = true;
                                    _dupeQty += 1;
                                }
                                let _email = '';
                                await this.userEmailPath(data.Uid).once('value', snapshot => {
                                    return _email = snapshot.val();
                                });
                                // if (this.state.JoinedPlayerEmails.findIndex(element => element.uid === data.Uid) < 0) {
                                //     this.state.JoinedPlayerEmails.push(
                                //         {
                                //             uid: data.Uid,
                                //             email: _email,
                                //         }
                                //     );
                                // }
                                // this.setState({
                                //     JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                                // });
                                _FinalRankingList[key].Email = _email;
                                this.setState({
                                    FinalRankingList: _FinalRankingList,
                                });
                                return null;
                            })
                        );

                        // this.setState({
                        //     FinalRankingList: this.state.FinalRankingList,
                        // });
                        // this.setState({
                        //     FinalRankingList: this.state.FinalRankingList,
                        //     // });
                        // }, () => {

                        // const _finalRankingList = this.state.FinalRankingList.filter((val, id, array) => {
                        //     return array.indexOf(val) == id;
                        // });
                        // window.alert(_finalRankingList.length);
                        this.setState({
                            // FinalRankingList: _finalRankingList,
                            // FinalRankingList: this.state.FinalRankingList,
                            FinalRankingList: _FinalRankingList,
                            // isAlmostDone: true,
                            // ProgressionState: 'Almost done... just a little bit more...<br></br>Sorting list right now...',
                            dupeQtyForFRList: _dupeQty,
                        }, async () => {
                            ProgressionStateText = 'Almost done... just a little bit more...<br>please wait patiently...';
                            await this.processHiddenRankingListWithResults();
                            // console.log('line = 1898');
                        });

                        //#region simplified, can remove if no use.
                        // //2021.09.10
                        // await this.Delay(1000);
                        // for (var s = 0; s < this.state.FinalRankingList.length; s++) {
                        //     if (Number(this.state.FinalRankingList[s].TimeElapsed) < 0) {
                        //         //
                        //     }
                        // }

                        // // let _delay_ms = this.state.FinalRankingList.length * 35;
                        // // _delay_ms = _delay_ms > 10000 ? 10000 : _delay_ms;
                        // let _delay_ms = 2000;
                        // setTimeout(async () => {

                        //     // //Sort List according to Scores & TimeElapsed in ASC
                        //     // this.state.FinalRankingList.sort(function (a, b) {
                        //     //     if (parseInt(a.Scores) > parseInt(b.Scores)) {
                        //     //         return -1;
                        //     //     }
                        //     //     if (parseInt(a.Scores) < parseInt(b.Scores)) {
                        //     //         return 1;
                        //     //     }
                        //     //     if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                        //     //         return -1;
                        //     //     }
                        //     //     if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                        //     //         return 1;
                        //     //     }
                        //     //     // if (a.PlayerName < b.PlayerName) { return -1; }
                        //     //     // if (a.PlayerName > b.PlayerName) { return 1; }
                        //     //     return 0;
                        //     // });

                        //     // //Set Rank.
                        //     // this.state.FinalRankingList.map((data, key) => {
                        //     //     this.state.FinalRankingList[key].Rank = key + 1;
                        //     // });

                        //     // //Sort by Rank again.
                        //     // this.state.FinalRankingList.sort(function (a, b) {
                        //     //     if (parseInt(a.Rank) < parseInt(b.Rank)) {
                        //     //         return -1;
                        //     //     }
                        //     //     if (parseInt(a.Rank) > parseInt(b.Rank)) {
                        //     //         return 1;
                        //     //     }
                        //     //     if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                        //     //         return -1;
                        //     //     }
                        //     //     if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                        //     //         return 1;
                        //     //     }
                        //     //     return 0;
                        //     // });

                        //     //Save state & show results in Ui.
                        //     // this.setState({
                        //     //     FinalRankingList: this.state.FinalRankingList,
                        //     //     // isFinalRankingListLoaded: true,
                        //     //     // isButtonEnabled: true,
                        //     //     // isAlmostDone: false,
                        //     // }, async () => {
                        //     //     await this.processHiddenRankingListWithResults();
                        //     // });

                        //     await this.processHiddenRankingListWithResults();

                        // }, _delay_ms);
                        // );
                        // });
                        // });
                        //#endregion
                    });
                });
            //#endregion older logic

            //#region part of new pattern logic, but too slow, do not use, can remove.
            //     await this.fetchFinalRankingList_Stage2(_roomIndex, _roomId, _roomCode);
            // }, async () => {
            //     // }

            //     // fetchFinalRankingList_Stage2 = async (_roomIndex, _roomId, _roomCode) => {
            //     //Combine both list.
            //     //1st stage.
            //     let date = moment(this.state.selectedRoomId);
            //     let _FinalRankingList = [];
            //     for (var join = 0; join < this.state.JoinedPlayerArrays.length; join++) {
            //         let data = this.state.JoinedPlayerArrays[join];
            //         //2020.07.27
            //         let isLogsExisted = false;
            //         let actualTimeConsumed = 0;
            //         let quizStartTimeTicks = 0;
            //         let isQuizStartTimeGet = false;
            //         let quizCompletedTimeTicks = 0;
            //         let isQuizCompletedTimeGet = false;
            //         let score = 0;

            //         //edited on 2020.11.11
            //         // personalScoreRecordPath(_date, _roomId, _uid)
            //         let theDate = moment(this.state.selectedDate);
            //         if (theDate > moment('20201019', 'YYYYMMDD')) {
            //             isLogsExisted = true;
            //             await this.personalScoreRecordPath(theDate.format('YYYYMMDD'), this.state.selectedRoomId, data.Uid).once('value', snapshot => {
            //                 if (snapshot.exists()) {
            //                     let splitData = String(snapshot.val()).split(';');
            //                     actualTimeConsumed = Number(splitData[1]);
            //                     score = Number(splitData[0]);
            //                 }
            //             });

            //             // if (data.Uid === 'VIZUL0z28rTwLupGn2CYjkoWnwo2') {
            //             //     console.log('Score = ' + score + '\nTime Consumed = ' + actualTimeConsumed);
            //             // }

            //             //added 2020.11.13  //to counter user still using app.
            //             // if (score > 100.00 || String(score).includes('.00') === false || String(score).includes('.50') === false) {
            //             if (score > 100.00 || String(score).includes('.') === false || actualTimeConsumed <= 0) {

            //                 //find the correct actual time consumed.
            //                 await this.logsPath(data.Uid).once('value', snapshot => {
            //                     if (snapshot.exists()) {
            //                         isLogsExisted = true;

            //                         let dataKeys = _(snapshot.val()).keys().value();       //key = time
            //                         let dataValues = _(snapshot.val()).values().value();   //values = log message

            //                         dataKeys.map((data, key) => {
            //                             if (dataValues[key].toString().includes('Quiz Started')
            //                                 && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                                 && !isQuizStartTimeGet) {
            //                                 isQuizStartTimeGet = true;
            //                                 quizStartTimeTicks = this.GetConvertedTime(data, date);
            //                             }
            //                             if (dataValues[key].toString().includes('Quiz Completed')
            //                                 && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                                 && !isQuizCompletedTimeGet) {
            //                                 isQuizCompletedTimeGet = true;
            //                                 quizCompletedTimeTicks = this.GetConvertedTime(data, date);
            //                             }
            //                             if (dataValues[key].toString().includes('Exit')
            //                                 && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                                 && isQuizStartTimeGet
            //                                 && !isQuizCompletedTimeGet) {
            //                                 quizCompletedTimeTicks = this.GetConvertedTime(data, date);
            //                             }
            //                             return null;
            //                         });

            //                         if (quizStartTimeTicks > 0 && quizCompletedTimeTicks > 0) {
            //                             actualTimeConsumed = (quizCompletedTimeTicks - quizStartTimeTicks) / 1000;
            //                             // alert(quizCompletedTimeTicks + " | " + quizStartTimeTicks + " | " + actualTimeConsumed);
            //                         }
            //                     }
            //                 });

            //                 //find the actual score received.
            //                 await this.personalQResultPath(theDate.format('YYYYMMDD'), this.state.selectedRoomId, data.Uid)
            //                     .once('value', snapshot => {
            //                         if (snapshot.exists()) {
            //                             let splitData = String(snapshot.val()).split(':');
            //                             let correct = 0;
            //                             if (splitData.length > 0) {
            //                                 splitData.map((data, key) => {
            //                                     if (data === '1')
            //                                         correct++;
            //                                     return null;
            //                                 });
            //                                 score = (correct / splitData.length) * 100;
            //                             }
            //                         }
            //                     });

            //                 data.TimeElapsed = actualTimeConsumed;
            //                 data.Scores = score.toFixed(2);
            //             }

            //             // if (data.Uid === 'VIZUL0z28rTwLupGn2CYjkoWnwo2') {
            //             //     console.log('Score = ' + score + '\nTime Consumed = ' + actualTimeConsumed);

            //             //     // if (actualTimeConsumed < 0)
            //             // }
            //         }
            //         else {
            //             await this.logsPath(data.Uid).once('value', snapshot => {
            //                 if (snapshot.exists()) {
            //                     isLogsExisted = true;

            //                     let dataKeys = _(snapshot.val()).keys().value();       //key = time
            //                     let dataValues = _(snapshot.val()).values().value();   //values = log message

            //                     // dataKeys.map((data, key) => {
            //                     for (var key = 0; key < dataKeys.length; key++) {
            //                         if (dataValues[key].toString().includes('Quiz Started')
            //                             && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                             && !isQuizStartTimeGet) {
            //                             isQuizStartTimeGet = true;
            //                             // let hr = data.substring(0, 2);
            //                             // let min = data.substring(2, 4);
            //                             // let sec = data.substring(4, 6);
            //                             // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            //                             // quizStartTimeTicks = moment(dateTime).add(8, 'hours').format('x');
            //                             quizStartTimeTicks = this.GetConvertedTime(dataKeys[key], date);
            //                         }
            //                         // if (dataValues[key].toString().includes('Exit')
            //                         //     && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                         //     && isQuizStartTimeGet
            //                         //     && !isQuizCompletedTimeGet) {
            //                         //     // let hr = data.substring(0, 2);
            //                         //     // let min = data.substring(2, 4);
            //                         //     // let sec = data.substring(4, 6);
            //                         //     // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            //                         //     // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
            //                         //     quizCompletedTimeTicks = this.GetConvertedTime(data, date);
            //                         // }
            //                         if (dataValues[key].toString().includes('Quiz Completed')
            //                             && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                             && !isQuizCompletedTimeGet) {
            //                             isQuizCompletedTimeGet = true;
            //                             // let hr = data.substring(0, 2);
            //                             // let min = data.substring(2, 4);
            //                             // let sec = data.substring(4, 6);
            //                             // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            //                             // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
            //                             quizCompletedTimeTicks = this.GetConvertedTime(dataKeys[key], date);
            //                         }
            //                         if (dataValues[key].toString().includes('Exit')
            //                             && dataValues[key].toString().includes(this.state.selectedRoomCode)
            //                             && isQuizStartTimeGet
            //                             && !isQuizCompletedTimeGet) {
            //                             // let hr = data.substring(0, 2);
            //                             // let min = data.substring(2, 4);
            //                             // let sec = data.substring(4, 6);
            //                             // let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            //                             // quizCompletedTimeTicks = moment(dateTime).add(8, 'hours').format('x');
            //                             quizCompletedTimeTicks = this.GetConvertedTime(dataKeys[key], date);
            //                         }
            //                         // return null;
            //                         // });
            //                     }

            //                     // if (quizStartTimeTicks == 0) {

            //                     // }

            //                     if (quizStartTimeTicks > 0 && quizCompletedTimeTicks > 0) {
            //                         actualTimeConsumed = (quizCompletedTimeTicks - quizStartTimeTicks) / 1000;
            //                         // alert(quizCompletedTimeTicks + " | " + quizStartTimeTicks + " | " + actualTimeConsumed);
            //                     }
            //                 }
            //             });
            //         }

            //         _FinalRankingList.push({
            //             PlayerName: data.PlayerName,
            //             Scores: data.Scores,
            //             TimeElapsed: actualTimeConsumed > 0 ? actualTimeConsumed : data.TimeElapsed,
            //             ActualTimeElapsed: data.TimeElapsed,
            //             // TimeElapsed: data.TimeElapsed,
            //             // ActualTimeElapsed: actualTimeConsumed > 0 ? actualTimeConsumed : data.TimeElapsed,
            //             IsCompleted: data.IsCompleted,
            //             Uid: data.Uid,
            //             Email: '',
            //             Version: isLogsExisted, //data.Version,
            //             Duplicated: false,
            //             Qty: 0,
            //             // Rank: 0,
            //             CorrectQty: 0,
            //             WrongQty: 0,
            //             IsWeb: false,
            //         });
            //         // this.setState({ FinalRankingList: _FinalRankingList });
            //     }

            //     //2nd stage.
            //     //Find Email & fill-in.
            //     this.setState({
            //         isButtonEnabled: false,
            //         dupeQtyForFRList: 0,
            //     });

            //     let _dupeQty = 0;
            //     for (var key = 0; key < _FinalRankingList.length; key++) {
            //         if (_FinalRankingList[key].Qty > 1) {
            //             _FinalRankingList[key].Duplicated = true;
            //             _dupeQty += 1;
            //         }
            //         let _email = '';
            //         await this.userEmailPath(_FinalRankingList[key].Uid).once('value', snapshot => {
            //             return _email = snapshot.val();
            //         });
            //         _FinalRankingList[key].Email = _email;
            //     }

            //     this.setState({
            //         FinalRankingList: _FinalRankingList,
            //         isAlmostDone: true,
            //         dupeQtyForFRList: _dupeQty,
            //     });
            //     await this.Delay(1000);
            //     await this.processHiddenRankingListWithResults();
            //#endregion
        });
    }

    GetConvertedTime = (_data, _date) => {
        let hr = _data.substring(0, 2);
        let min = _data.substring(2, 4);
        let sec = _data.substring(4, 6);
        let dateTime = _date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
        return moment(dateTime).add(8, 'hours').format('x');
    }

    processJoinedPlayersDataV3 = async (dataSnapVal) => {
        // let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        // let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        this.setState({
            JoinedPlayerArrays: [],
        });
        // await this.Delay(500);
        let _JoinedPlayerArrays = [];   //this.state.JoinedPlayerArrays;

        // await Promise.all(
        // dataKeys.map(async (data_Uid, key) => {
        for (var key = 0; key < dataSnapVal.length; key++) {
            let data_Uid = dataSnapVal[key].Uid;
            // let dataVal = _(dataValues[key]).value().split(';');
            let dataVal = dataSnapVal[key].Value.split(';');
            let _isVerNew = data_Uid.length >= 28 && data_Uid.indexOf(' ') === -1 ? true : false;
            if (this.state.liveQuizDate > 20200607) {
                let _pname = data_Uid.length >= 28 && data_Uid.indexOf(' ') === -1 ?
                    unescape(dataVal[2])
                    : unescape(data_Uid);
                let _uid = data_Uid.length >= 28 && data_Uid.indexOf(' ') === -1 ?
                    data_Uid
                    : dataVal[2].toString();

                //2020.12.09
                // let _name = dataSet[2];
                if (_pname.length <= 0) {
                    await this.userNamePath(_uid).once('value', snapshot => {
                        // return this.state.JoinedPlayerEmails.push(snapshot.val());
                        return _pname = snapshot.val();
                    });
                    // alert(_name);
                }

                //2020.12.12
                let _email = '';
                if (_pname.length <= 0) {
                    await this.props.dbFireStore.collection("User")
                        .where('Uid', '==', _uid)
                        .get()
                        .then((querySnapshot) => {
                            let data = [];
                            if (querySnapshot !== null) {
                                querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                });
                            }
                            if (data.length > 0) {
                                _pname = data[0]['Name'];
                                _email = data[0]['Email'];
                            }
                        });
                    // alert(_name);
                }

                _JoinedPlayerArrays.push({
                    PlayerName: _pname,
                    Scores: dataVal[0],
                    TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                    IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                    Uid: _uid,
                    Email: _email,
                    Version: _isVerNew,
                });
                // return null;
            }
            else {
                _JoinedPlayerArrays.push({
                    PlayerName: unescape(data_Uid),
                    Scores: dataVal[0],
                    TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                    IsCompleted: dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                    Uid: dataVal[2],
                    Email: '',
                    Version: _isVerNew,
                });
            }
            // return null;
        }
        //     })
        // );

        this.setState({
            JoinedPlayerArrays: _JoinedPlayerArrays,
        }, () => {
            // console.log(JSON.stringify(this.state.JoinedPlayerArrays));
        });
    }

    processRankingListDataV3 = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        this.setState({
            RankingListArrays: [],
        });

        // dataKeys.map((data, key) => {
        //     let dataVal = dataValues[key].split(';');
        //     if (this.state.liveQuizDate > 20200607) {   //  (/^\s*$/.test(dataVal[2])) = contains any space
        //         // if (this.state.liveQuizDate > 20200607 || !(/^\s*$/.test(dataVal[2]))) {
        //         let _pname = data.length >= 28 && data.indexOf(' ') === -1 ?
        //             unescape(dataVal[2])
        //             : unescape(data);
        //         let _uid = data.length >= 28 && data.indexOf(' ') === -1 ?
        //             data
        //             : dataValues[key].split(';')[2].toString();
        //         return this.state.RankingListArrays.push({
        //             PlayerName: _pname,
        //             Scores: dataVal[0],
        //             TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
        //             Uid: _uid,
        //             Email: '',
        //         });
        //     }
        //     else {
        //         return this.state.RankingListArrays.push({
        //             PlayerName: unescape(data),
        //             Scores: dataVal[0],
        //             TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
        //             Uid: dataVal[2],
        //             Email: '',
        //         });
        //     }
        // });

        // dataKeys.map((data, key) => {
        for (var key = 0; key < dataKeys.length; key++) {
            let data = dataKeys[key];
            if (data <= 0)
                return null;
            // if (data > 0) {
            let dataVals = _(dataValues[parseInt(data)]).value().split(';');
            let _uid = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ?
                dataVals[0]
                : dataVals[3];
            let _isVerNew = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? false : true;
            return this.state.RankingListArrays.push({
                // PlayerName: !(/^\s*$/.test(unescape(dataVal[2]))) ? unescape(dataVal[2]) : data,
                PlayerName: dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? unescape(dataVals[3]) : unescape(dataVals[0]),
                Scores: dataVals[1],
                TimeElapsed: parseFloat(dataVals[2]).toFixed(1),
                // IsCompleted: '',
                Uid: _uid,
                Email: '',
                Version: _isVerNew,
            });
        }
        // }
        // });

        //Show Ui & Joined Players List
        this.setState({
            RankingListArrays: this.state.RankingListArrays,
            isButtonEnabled: true,
        });
    }

    fetchRankingList = (_roomIndex, _roomId, _roomCode) => {
        // let date = moment(this.state.selectedDate).format('YYYYMMDD');
        this.setState({
            selectedRoomCode: _roomCode,
            selectedRoomId: _roomId,
            selectedRoomIndex: _roomIndex,
            prevSelectedRoomId: this.state.prevSelectedRoomId !== _roomId ? _roomId : this.state.selectedRoomId,
        }, () => {
            this.rankingListPath(this.state.liveQuizDate).once('value', snapshot => {
                this.processRankingListData(snapshot.val());
            });
            // this.joinedPlayersPath(this.state.liveQuizDate).once('value', snapshot => {
            //     // this.processRankingListData(snapshot.val());
            //     this.processRankingListDataV2(snapshot.val());
            // });  //2020.06.30
        });
    }

    processRankingListDataV2 = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        // window.alert(_(dataValues[0]).values().value()[0]); //correct

        this.setState({
            RankingListArrays: [],
            JoinedPlayerEmails: [],
        });

        Promise.all(
            this.state.liveQuizDate > 20200607 ?
                dataKeys.map(async (data, key) => {
                    let _email = '';
                    // let uid = uidRegex.test(data) ? data : dataValues[key].split(';')[2].toString();
                    let _uid = data.length >= 28 && data.indexOf(' ') === -1 ? data : dataValues[key].split(';')[2].toString();
                    await this.userEmailPath(_uid).once('value', snapshot => {
                        // return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
                        return _email = snapshot.val();
                    });
                    if (this.state.JoinedPlayerEmails.findIndex(element => element.uid === _uid) < 0) {
                        this.state.JoinedPlayerEmails.push(
                            {
                                uid: _uid,
                                email: _email,
                            }
                        );
                    }
                    this.setState({
                        JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                    });
                })
                :
                dataValues.map(async (data, key) => {
                    let _email = '';
                    let _uid = data.split(';')[2];
                    await this.userEmailPath(_uid).once('value', snapshot => {
                        // return this.state.JoinedPlayerEmails.push(snapshot.val());
                        return _email = snapshot.val();
                    });
                    if (this.state.JoinedPlayerEmails.findIndex(element => element.email === _email) < 0) {
                        this.state.JoinedPlayerEmails.push(
                            {
                                uid: _uid,
                                email: _email,
                            }
                        );
                    }
                    this.setState({
                        JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                    });
                })
        )
            // .then(() => {
            //     this.setState({
            //         JoinedPlayerEmails: this.state.JoinedPlayerEmails,
            //     }, () => {
            //         // window.alert(this.state.JoinedPlayerEmails.length);
            //     });
            // })
            .then(() => {
                dataKeys.map((data, key) => {
                    let dataVal = _(dataValues[key]).value().split(';');
                    let _isVerNew = data.length >= 28 && data.indexOf(' ') === -1 ? false : true;
                    if (this.state.liveQuizDate > 20200607) {   //  (/^\s*$/.test(dataVal[2])) = contains any space
                        // if (this.state.liveQuizDate > 20200607 || !(/^\s*$/.test(dataVal[2]))) {
                        let _pname = data.length >= 28 && data.indexOf(' ') === -1 ?
                            unescape(dataVal[2])
                            : unescape(data);
                        let _uid = data.length >= 28 && data.indexOf(' ') === -1 ?
                            data
                            : dataValues[key].split(';')[2].toString();
                        return this.state.RankingListArrays.push({
                            PlayerName: _pname,
                            Scores: dataVal[0],
                            TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                            Uid: _uid,
                            Email: this.state.JoinedPlayerEmails.find(x => x.uid === _uid).email,
                            Version: _isVerNew,
                        });
                    }
                    else {
                        return this.state.RankingListArrays.push({
                            PlayerName: unescape(data),
                            Scores: dataVal[0],
                            TimeElapsed: parseFloat(dataVal[1]).toFixed(1),
                            Uid: dataVal[2],
                            Email: this.state.JoinedPlayerEmails.find(x => x.uid === dataVal[2]).email,
                            Version: _isVerNew,
                        });
                    }
                });
            })
            .then(() => {
                //Sort List according to Scores & TimeElapsed in ASC
                this.state.RankingListArrays.sort(function (a, b) {
                    if (parseInt(a.Scores) > parseInt(b.Scores)) {
                        return -1;
                    }
                    if (parseInt(a.Scores) < parseInt(b.Scores)) {
                        return 1;
                    }
                    if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                        return -1;
                    }
                    if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                        return 1;
                    }
                    return 0;
                });

                //Show Ui & Joined Players List
                this.setState({
                    RankingListArrays: this.state.RankingListArrays,
                    isRankingListLoaded: true,
                    isButtonEnabled: true,
                });
            });
    }

    processRankingListData = async (dataSnapVal) => {
        let dataKeys = _(dataSnapVal).keys().value();       //key = uid
        let dataValues = _(dataSnapVal).values().value();   //values = data == "Scores;Time;Name;IsCompleted(TRUE/FALSE/None);"

        // window.alert(_(dataValues[0]).values().value()[0]); //correct

        this.setState({
            // JoinedPlayerArrays: [],
            JoinedPlayerEmails: [],
            RankingListArrays: [],
            dupeQtyForFRList: 0,
            // });
        }, () => {
            let _dupeQty = 0;
            // let _TempRankingListArrays = [];
            Promise.all(
                dataKeys.map(async (data, key) => {
                    if (data <= 0)
                        return null;

                    // if (data > 0) {
                    let dataVal = dataValues[key].split(';');
                    // let dataVal = _(dataValues[key]).value().split(';');
                    let _email = '';
                    let _uid = dataVal[3].length >= 28 && dataVal[3].indexOf(' ') === -1 ? dataVal[3] : dataVal[0];
                    // let _pname = dataVal[3].length >= 28 && dataVal[3].indexOf(' ') === -1 ? dataVal[0] : dataVal[3];
                    await this.userEmailPath(_uid).once('value', snapshot => {
                        // window.alert(dataVal[3] + " / " + snapshot.val());
                        // return this.state.JoinedPlayerEmails.push(snapshot.val());  //Current Format
                        return _email = snapshot.val();
                    });
                    if (this.state.JoinedPlayerEmails.findIndex(element => element.uid === _uid) < 0) {
                        this.state.JoinedPlayerEmails.push(
                            {
                                uid: _uid,
                                email: _email,
                            }
                        );
                    }
                    // else {
                    //     this.state.JoinedPlayerEmails.push('email not found');
                    // }
                    this.setState({
                        JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                    });
                })
            )
                // .then(() => {
                //     // window.alert(this.state.JoinedPlayerEmails.toString());
                //     // window.alert(this.state.JoinedPlayerEmails.length);
                //     this.setState({
                //         JoinedPlayerEmails: this.state.JoinedPlayerEmails,
                //     }, () => {
                //         // window.alert(this.state.JoinedPlayerEmails.toString());
                //         // window.alert(this.state.JoinedPlayerEmails.length);
                //     });
                // })
                .then(() => {
                    // window.alert(this.state.JoinedPlayerEmails.toString());
                    dataKeys.map((data, key) => {
                        if (data <= 0)
                            return null;
                        // if (data > 0) {
                        let dataVals = _(dataValues[parseInt(data)]).value().split(';');
                        let _uid = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ?
                            dataVals[0]
                            : dataVals[3];
                        let _isVerNew = dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? false : true;
                        // window.alert(dataVals.toString());
                        // PlayerName: !(/^\s*$/.test(unescape(dataVal[2]))) ? unescape(dataVal[2]) : data,
                        // return this.state.RankingListArrays.push({
                        return this.state.RankingListArrays.push({
                            PlayerName: dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? unescape(dataVals[3]) : unescape(dataVals[0]),
                            Scores: dataVals[1],
                            TimeElapsed: parseFloat(dataVals[2]).toFixed(1),
                            // IsCompleted: '', //dataVal.length > 3 ? (dataVal[3] ? 'YES' : 'NO') : 'NO',
                            // Uid: dataVals[0].length >= 28 && dataVals[0].indexOf(' ') === -1 ? dataVals[0] : dataVals[3],
                            Uid: _uid,
                            // Email: data + ' ' + key,
                            // Email: dataVals[0] + ' - ' + this.state.JoinedPlayerEmails[key - 1],
                            // Email: this.state.JoinedPlayerEmails[key - 1],
                            Email: this.state.JoinedPlayerEmails.find(x => x.uid === _uid).email,
                            // Email: '',
                            Version: _isVerNew,
                            Duplicated: false,
                            Qty: 0,
                        });
                        // }
                    });
                    // window.alert(this.state.RankingListArrays.toString());
                })
                // .then(() => {
                //     // let pickedData;
                //     this.state.RankingListArrays.map((data, key) => {

                //         let index = _TempRankingListArrays.find(({ Uid }) => Uid === data.Uid);
                //         if (index < 0) {  //Not Exist
                //             _TempRankingListArrays.push({
                //                 PlayerName: data.PlayerName,
                //                 Scores: data.Scores,
                //                 TimeElapsed: data.TimeElapsed,
                //                 Uid: data.Uid,
                //                 Email: data.Email,
                //                 Version: data.Version,
                //                 Duplicated: data.Duplicated,
                //                 Qty: data.Qty,
                //             })
                //         }
                //         else {
                //             _TempRankingListArrays.push({
                //                 PlayerName: data.PlayerName,
                //                 Scores: data.Scores,
                //                 TimeElapsed: data.TimeElapsed,
                //                 Uid: data.Uid,
                //                 Email: data.Email,
                //                 Version: data.Version,
                //                 Duplicated: data.Duplicated,
                //                 Qty: data.Qty,
                //             })
                //         }

                //         // let index = _TempRankingListArrays.findIndex(element => element.Uid === data.Uid);
                //         // if (index < 0)  //Not Exist
                //         // {
                //         //     _TempRankingListArrays.push({
                //         //         PlayerName: data.PlayerName,
                //         //         Scores: data.Scores,
                //         //         TimeElapsed: data.TimeElapsed,
                //         //         Uid: data.Uid,
                //         //         Email: data.Email,
                //         //         Version: data.Version,
                //         //         Duplicated: data.Duplicated,
                //         //         Qty: data.Qty,
                //         //     })
                //         // }
                //         // else {
                //         //     this.state.RankingListArrays[key].Qty += 1;
                //         //     // if (this.state.RankingListArrays[key].Uid == _TempRankingListArrays[index].Uid) {
                //         //     //     // let isDuped = false;
                //         //     //     if (parseInt(this.state.RankingListArrays[key].Scores) > parseInt(_TempRankingListArrays[index].Scores)) {  //Score 1 higher.
                //         //     //         pickedData = this.state.RankingListArrays[key];
                //         //     //     }
                //         //     //     else if (parseInt(this.state.RankingListArrays[key].Scores) < parseInt(_TempRankingListArrays[index].Scores)) { //Score 1 lower.
                //         //     //         pickedData = _TempRankingListArrays[index];
                //         //     //     }
                //         //     //     else {  //Same Score.
                //         //     //         if (parseFloat(this.state.RankingListArrays[key].TimeElapsed) <= parseFloat(_TempRankingListArrays[index].TimeElapsed)) {    //TimeElapsed 1 lesser, or =Zero.
                //         //     //             pickedData = this.state.RankingListArrays[key];
                //         //     //         }
                //         //     //         else if (parseFloat(this.state.RankingListArrays[key].TimeElapsed) > parseFloat(_TempRankingListArrays[index].TimeElapsed)) {    //TimeElapsed 1 more.
                //         //     //             pickedData = _TempRankingListArrays[index];
                //         //     //         }
                //         //     //         else {
                //         //     //             pickedData = this.state.RankingListArrays[key]; //default if no matches.
                //         //     //             // isDuped = true;
                //         //     //         }
                //         //     //     }
                //         //     //     this.state.RankingListArrays[key].Scores = pickedData.Scores;
                //         //     //     this.state.RankingListArrays[key].TimeElapsed = pickedData.TimeElapsed;
                //         //     //     // this.state.FinalRankingList[key2].Duplicated = isDuped;
                //         //     //     this.state.RankingListArrays[key].Qty += 1;
                //         //     // }
                //         // }    
                //     });

                //     _TempRankingListArrays.map((data, key) => {
                //         if (data.Qty > 1) {
                //             this.state.RankingListArrays[key].Duplicated = true;
                //         }
                //     });
                // })
                .then(() => {
                    // //Sort List according to Name in ASC
                    // this.state.JoinedPlayerArrays.sort(function (a, b) {
                    //     if (a.PlayerName < b.PlayerName) { return -1; }
                    //     if (a.PlayerName > b.PlayerName) { return 1; }
                    //     return 0;
                    // });

                    //Show Ui & Joined Players List
                    this.setState({
                        RankingListArrays: this.state.RankingListArrays,
                        // RankingListArrays: _TempRankingListArrays,
                        isRankingListLoaded: true,
                        isButtonEnabled: true,
                        dupeQtyForFRList: _dupeQty,
                    });
                });
        });
    }

    LoadRoomList = (_uiMode) => {
        if (this.state.RoomDetailArrays.length <= 0) {
            this.LoadRoomListAndDetails(_uiMode);
        }
        else {
            this.SwitchUi(_uiMode);
            this.setState({
                isRoomListLoaded: true,
            });
        }
    }

    // LoadRoomListAndJoinedPlayers() {
    //     if (this.state.RoomDetailArrays.length <= 0) {
    //         this.LoadRoomListAndDetails(UiMode.ShowJoinedPlayers);
    //     }
    //     else {
    //         this.SwitchUi(UiMode.ShowJoinedPlayers);
    //         this.setState({
    //             isRoomListLoaded: true,
    //         });
    //     }
    // }

    // LoadRoomListForRankingList() {
    //     if (this.state.RoomDetailArrays.length <= 0) {
    //         this.LoadRoomListAndDetails(UiMode.ShowRankingList);
    //     }
    //     else {
    //         this.SwitchUi(UiMode.ShowRankingList);
    //         this.setState({
    //             isRoomListLoaded: true,
    //         });
    //     }
    // }

    // LoadRoomListForFinalRankingList() {
    //     if (this.state.RoomDetailArrays.length <= 0) {
    //         this.LoadRoomListAndDetails(UiMode.ShowFinalRankingList);
    //     }
    //     else {
    //         this.SwitchUi(UiMode.ShowFinalRankingList);
    //         this.setState({
    //             isRoomListLoaded: true,
    //         });
    //     }
    // }

    SwitchUi = (_uiMode) => {
        uiModeState = _uiMode;
        this.setState({
            // selectedRoomId: -1,
            // selectedRoomIndex: -1,
            showRoomDetailsList: false,
            showRoomDetail: false,
            isRoomListLoaded: false,
            isRoomDetailsLoaded: false,
            isShowRoomDetails: false,
            isShowJoinedPlayers: false,
            isJoinedPlayersLoaded: false,
            // showRankingList: false,
            isShowRankingList: false,
            // showRoomDetailsList: true,
            isShowFinalRankingList: false,
            isAlmostDone: false,

            // JoinedPlayerEmails: [],
            // JoinedPlayerArrays: [],
            // RankingListArrays: [],
            // FinalRankingList: [],

            logDataDetails: '',
            resultDataDetails: '',
        }, () => {
            switch (_uiMode) {
                case UiMode.Begin:
                    break;
                // case UiMode.ShowRoomList:
                //     this.setState({
                //         showRoomDetailsList: true,
                //     });
                //     break;
                // case UiMode.ShowJoinedPlayers:
                //     if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0)
                //         this.SelectRoom(this.state.prevSelectedRoomId);
                //     // this.setState({
                //     //     isShowJoinedPlayers: true,
                //     // });
                //     break;
                // case UiMode.ShowRankingList:
                //     if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0)
                //         this.SelectRoom(this.state.prevSelectedRoomId);
                //     // this.setState({
                //     //     isShowRankingList: true,
                //     // });
                //     break;
                // case UiMode.ShowFinalRankingList:
                //     // if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0)
                //     //     this.SelectRoom(this.state.prevSelectedRoomId);
                //     this.setState({
                //         // isShowFinalRankingList: true,
                //         showRoomDetailsList: true,
                //         JoinedPlayerArrays: [],
                //         RankingListArrays: [],
                //         FinalRankingList: [],
                //     }, () => {
                //         if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0)
                //             this.SelectRoom(this.state.prevSelectedRoomId);
                //     });
                //     break;
                default:
                    this.setState({
                        showRoomDetailsList: true,
                    });
                    // this.setState({
                    //     showRoomDetailsList: true,
                    // }, () => {
                    //     if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0) {
                    //         this.SelectRoom(this.state.prevSelectedRoomId);
                    //     }
                    // });
                    break;
            }
            if (this.state.prevSelectedRoomId === this.state.selectedRoomId && this.state.prevSelectedRoomId > 0) {
                this.SelectRoom(this.state.prevSelectedRoomId);
            }
        });
    }

    downloadFile = () => {
        let myData;
        let lastNotice;
        let _filename = this.state.liveQuizDate + '_';
        let roomDetails = this.state.RoomDetailArrays[this.state.selectedRoomIndex];
        if (uiModeState === UiMode.ShowJoinedPlayers) {
            _filename += roomDetails.RoomTitle.replace(' ', '-') + '_' + roomDetails.RoomId + '_Joined-Player-List';
            myData = this.state.JoinedPlayerArrays;
            lastNotice = 'Joined Player List for <' + roomDetails.RoomTitle + '> has been downloaded.';
        }
        else if (uiModeState === UiMode.ShowRankingList) {
            _filename += roomDetails.RoomTitle.replace(' ', '-') + '_' + roomDetails.RoomId + '_Player-Ranking-List';
            myData = this.state.RankingListArrays;
            lastNotice = 'Player Ranking List for <' + roomDetails.RoomTitle + '> has been downloaded.';
        }
        else if (uiModeState === UiMode.ShowFinalRankingList) {
            _filename += roomDetails.RoomTitle.replace(' ', '-') + '_' + roomDetails.RoomId + '_Player-Final-Ranking-List';
            myData = this.state.FinalRankingList;
            lastNotice = 'Final Ranking List for <' + roomDetails.RoomTitle + '> has been downloaded.';
        }
        const fileName = _filename;
        const json = JSON.stringify(myData);
        const blob = new Blob([json], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => {
            window.alert(lastNotice);
        }, 1000);
    }

    // exportToJson() {
    //     let objectData;
    //     if (uiModeState === UiMode.ShowJoinedPlayers) {
    //         objectData = this.state.JoinedPlayerArrays;
    //     }
    //     else if (uiModeState === UiMode.ShowRankingList) {
    //         objectData = this.state.RankingListArrays;
    //     }

    //     let filename = "export.json";
    //     let contentType = "application/json;charset=utf-8;";
    //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //         var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
    //         navigator.msSaveOrOpenBlob(blob, filename);
    //     }
    //     else {
    //         var a = document.createElement('a');
    //         a.download = filename;
    //         a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
    //         a.target = '_blank';
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //     }
    // }

    // downloadFile1() {
    //     let objectData;
    //     if (uiModeState === UiMode.ShowJoinedPlayers) {
    //         objectData = this.state.JoinedPlayerArrays;
    //     }
    //     else if (uiModeState === UiMode.ShowRankingList) {
    //         objectData = this.state.RankingListArrays;
    //     }

    //     let link = document.createElement("a");
    //     link.download = 'fileName.json';
    //     link.href = `data:application/octet-stream,${objectData}`;
    //     return link;
    // }

    PrintDiv = () => {
        window.alert("Best View in Mode: Landscape, A3 size paper");

        let objectData;
        if (uiModeState === UiMode.ShowJoinedPlayers) {
            objectData = 'Joined-Player-List';
        }
        else if (uiModeState === UiMode.ShowRankingList) {
            objectData = 'Ranking-List';
        }
        else if (uiModeState === UiMode.ShowFinalRankingList) {
            // objectData = 'Final-Ranking-List';
            objectData = 'Ranking-List-with-Quiz-Result_hidden';
        }

        var mywindow = window.open('', 'PRINT', 'height=700,width=1000');

        mywindow.document.write('<html><head><title>' + this.getNameOfReport() + '</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1>' + document.title + '</h1>');
        mywindow.document.write(document.getElementById(objectData).innerHTML.replace('yellow', 'black'));
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    //2020.07.15
    OpenLogs = async (_uid, _pname, _rank, _email) => {
        // alert(_uid);
        // window.open('https://www.google.com/');

        this.setState({
            logDataDetails: '',
            scrollPosition: window.pageYOffset,
        });

        // let date = moment.unix(this.state.selectedRoomId / 1000);   //.format("YYYY-MM-DD HH:mm:ss"); //room id = ticks
        // alert(date.format("YYYY-MM-DD HH:mm:ss") + " / " + new Date(this.state.selectedRoomId / 10000).toString());
        // alert(date.format("YYYY-MM-DD HH:mm:ss"));
        // alert(date.format("YYYY-MM-DD HH:mm:ss") + "\n\n" 
        // + date.format("YYYY") + "|" + date.format("M") + "|" + date.format("D") 
        // + "|=|" + date.format("hh:mm:ss A"));

        await this.logsPath(_uid).once('value', snapshot => {
            this.processLogsData(snapshot.val(), _pname, _rank, _email);
        });
    }

    //2020.07.15
    processLogsData = async (dataSnapVal, _pname, _rank, _email) => {
        // alert(dataSnapVal);
        // let date = moment.unix(this.state.selectedRoomId / 1000); //room id = ticks
        // let date = moment.unix(this.state.selectedRoomId); //room id = ticks
        let date = moment(this.state.selectedDate); //room id = ticks
        // alert(date.format("hh:mm:ss A"));

        let dataKeys = _(dataSnapVal).keys().value();       //key = time
        let dataValues = _(dataSnapVal).values().value();   //values = log message
        let _logDataHtml = '';

        _logDataHtml += "<center><table cellpadding='15' border='1' bgcolor='#282c34'><tr><td colspan='2'>";
        _logDataHtml += "<table width='100%'><tr><td align='left'>"
            + "<font color='yellow'>" + _rank + ".</font> "
            + _pname + " (" + _email + ")</td>"
            + "<td align='right'>Room Code: " + this.state.selectedRoomCode + "</td>"
            + "<td align='right'>" + date.format("LL") + " (" + date.format("YYYY-MM-DD") + ")</td></tr></table>";
        _logDataHtml += "</td></tr><tr><td>Time</td><td>Log</td></tr>";


        //2020.12.09
        let _logArray = [];
        dataKeys.map(async (data, key) => {
            _logArray.push({ Time: data, Log: dataValues[key] });
        });
        _logArray.sort(function (a, b) {
            if (moment(a.Time) > moment(b.Time)) {
                return 1;
            }
            if (moment(a.Time) < moment(b.Time)) {
                return -1;
            }
            return 0;
        });
        _logArray.map(async (data, key) => {
            let hr = String(data.Time).substring(0, 2);
            let min = String(data.Time).substring(2, 4);
            let sec = String(data.Time).substring(4, 6);

            let dateTime = moment(this.state.selectedDate).format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
            _logDataHtml += "<tr><td>" + moment(dateTime).add(8, 'hours').format("hh:mm:ss A")
                + "</td><td>"
                + String(_logArray[key].Log).toString().replace(this.state.selectedRoomCode, "<font color='yellow'><u>" + this.state.selectedRoomCode + "</u></font>")
                + "</td></tr>";

            return null;
        });


        // var txt = '';
        // dataKeys.map(async (data, key) => {
        //     let hr = data.substring(0, 2);
        //     let min = data.substring(2, 4);
        //     let sec = data.substring(4, 6);

        //     // txt += data + '|' + hr + '|' + min + '|' + sec + '\n';

        //     let dateTime = date.format("YYYY-MM-DD") + " " + hr + ":" + min + ":" + sec;
        //     _logDataHtml += "<tr><td>" + moment(dateTime).add(8, 'hours').format("hh:mm:ss A")
        //         + "</td><td>"
        //         + dataValues[key].toString().replace(this.state.selectedRoomCode, "<font color='yellow'><u>" + this.state.selectedRoomCode + "</u></font>")
        //         + "</td></tr>";
        //     // + "</td><td>" + dataValues[key].toString() + "</td></tr>";

        //     // _logDataHtml += "<tr><td>" + date.format("hh:mm:ss A")
        //     //     + "</td><td>" + dataValues[key].toString() + "</td></tr>";

        //     return null;
        // });
        // alert(txt);

        _logDataHtml += "<tr><td colspan='2' align='right'><button id='closeLogsDetailUi' class='buttonStyleCloseUi'>Close</button></td></tr>"
        _logDataHtml += "</table></center><p style='height:5000px'>&nbsp;</p>";


        //2020.11.20
        let rankingData = this.state.FinalRankingList;
        rankingData[_rank - 1].IsWeb = String(_logDataHtml).includes('Platform');
        _logDataHtml = _logDataHtml.replace(new RegExp('Platform', 'g'), "<font color='yellow'>Platform</font>");


        this.setState({
            logDataDetails: _logDataHtml,
            FinalRankingList: rankingData,
        });

        // alert(_logDataHtml);
        window.scrollTo(0, 0);
    }

    closeLogsDetailUi = (e) => {
        let _id = e.target.id;
        if (_id != null && _id === 'closeLogsDetailUi') {
            this.setState({
                logDataDetails: '',
            }, () => {
                window.scrollTo(0, this.state.scrollPosition);
            });
        }
    }

    //2020.07.16 - Ranking-List-with-Quiz-Result
    getNameOfReport = () => {
        return "Ranking-List-with-Quiz-Result"
            + "_Std-" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Grade + "_"
            + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject + "_"
            + this.state.selectedRoomCode + "_" + this.state.selectedRoomId + "_("
            + moment().format('YYYY-MM-DD_HHmm') + ")";
    }

    //2021.03.08
    getOrganizer = (name, subject) => {
        name = String(name).toLowerCase();
        subject = String(subject).toLowerCase();
        let organizer = '';
        if (name.includes('全马线上考试'))
            organizer = '佳学教育 JiaXue Tuition Center';
        else if (subject.includes('colin'))
            organizer = "The Colin's Learning Centre";
        else if (subject.includes('abacus'))
            organizer = 'Smart Newton';
        else
            organizer = 'SIM';
        return organizer;
    }

    //2020.07.17 - Hidden Ranking List with Results - Ui
    processHiddenRankingListWithResults = async () => {

        let _space_1 = 11 + 2;  //6;
        let _space_2 = 8 + 2;   //3;

        let _qsQty = this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty;
        let _htmlStrings = "<table id='Ranking-List-with-Quiz-Result' padding={'10'}>";


        _htmlStrings += "<tr height='30'><td colspan='" + (_space_1 + _qsQty) + "'></td></tr>";
        _htmlStrings += "<tr><td colspan='" + (_space_1 + _qsQty) + "'><b>" + this.getNameOfReport() + "</b></td></tr>";
        _htmlStrings += "<tr height='30'><td colspan='" + (_space_1 + _qsQty) + "'></td></tr>";

        _htmlStrings += "<tr><td></td><td>Date</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + moment(this.state.selectedDate).format('dddd, MMMM Do, YYYY')
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Quiz</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>Live Quiz</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Room</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + this.state.selectedRoomCode + "&nbsp;"
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Room Id</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + this.state.selectedRoomId + "&nbsp;"
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Subject</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Grade</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b> Standard " + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Grade
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Question Quantity</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + _qsQty + "&nbsp;"
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Questions</td><td>:</td><td colspan='" + (_space_2 + _qsQty) + "'>"
            + "<b>" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Questions.replace(/;/g, ' | ')
            + "</b></td></tr>";
        _htmlStrings += "<tr height='30'><td colspan='" + (_space_1 + _qsQty) + "'></td></tr>";


        // _htmlStrings += "<tr>"
        //     + "<td align='right'><b>Rank</b></td><td><b>Name</b></td><td><b>Scores</b></td>"
        //     + "<td><b>Time&nbsp;Consumed</b></td><td><b>Email</b></td><td><b>Result</b></td>";
        _htmlStrings += "<tr>"
            + "<td align='right'><b>Rank</b></td> <td><b>Name</b></td> <td><b>Result</b></td>"
            // + "<td><b>Time&nbsp;Consumed</b></td> <td><b>Scores</b></td> <td><b>Email</b></td>"

            //2021.11.16
            + "<td><b>Time&nbsp;Consumed</b></td> <td><b>Scores</b></td>"
            + "<td><b>Gender</b></td> <td><b>Race</b></td> <td><b>Email</b></td>";

        //2021.04.20
        _htmlStrings += "<td><b>Contact</b></td>";

        // //2020.12.11
        // _htmlStrings += "<td><b>SIM</b></td> <td><b>State</b></td> <td><b>School</b></td>";
        // console.log('line = 3132');

        //2021.03.08
        _htmlStrings += "<td><b>"
            + this.getOrganizer(
                this.state.RoomDetailArrays[this.state.selectedRoomIndex].RoomTitle,
                this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject
            )
            + "</b></td> <td><b>State</b></td> <td><b>School</b></td>";

        // console.log('line = 3142');

        //2020.12.14
        // _htmlStrings += "<td><b>Guardian</b></td> <td><b>Contact</b></td>";

        //2021.04.20
        _htmlStrings += "<td><b>Guardian</b></td>";

        for (var i = 1; i < _qsQty + 1; i++) {
            _htmlStrings += "<td align='center'><b>Q" + i + "</b></td>";
        }
        _htmlStrings += "</tr>";

        let _UpdatedFinalRankingList = [];
        // let _RankingListResults = [];
        let _date = moment(this.state.selectedDate).format('YYYYMMDD');
        // console.log('line = 3158');

        //2021.09.13 - Load quiz questions.
        await this.LoadQuizQuestions();
        await this.DelayUntil(() => this.state.QuizQuestions.length > 0);
        // await this.Delay(1000);
        let roomData = this.state.RoomDetailArrays[this.state.selectedRoomIndex];
        let pickedQs = roomData.Questions.split(';');
        // pickedQs = pickedQs.filter((el) => { return (el !== null); });
        // console.log('line = 3175');

        await Promise.all(
            //Loop ranking list.
            this.state.FinalRankingList.map(async (data, key) => {

                // for (var num = 0; num < this.state.FinalRankingList.length; num++) {
                //     let data = this.state.FinalRankingList[num];
                //     let key = num;

                // //2021.09.13 - progression state.
                // if (key % 100 === 0) {
                //     ProgressionStateText = 'Almost done...(' + Math.round(((key + 1) / this.state.FinalRankingList.length) * 100)
                //         + '%) (' + (key + 1) + '/' + this.state.FinalRankingList.length + ')';
                //     // console.log(ProgressionStateText);
                //     this.forceUpdate();
                //     await this.Delay(500);
                // }
                // // this.setState({
                // //     ProgressionState: 'Almost done...(' + Math.round(((key + 1) / this.state.FinalRankingList.length) * 100)
                // //         + '%) (' + (key + 1) + '/' + this.state.FinalRankingList.length + ')',
                // // });

                let _uid = data.Uid;
                let _results = '';
                let _resultArrayInBit = [];
                // let hasResult = false;
                let _correctQty = 0;
                let isFetchNewPathDone = false;
                let _resultArray = [];

                // alert(this.state.selectedDate + "|" + _roomCode + "|" + _uid);

                //#region quiz result - no need as new method is used, get result by ASelects.
                // await this.personalResultPath(_date, this.state.selectedRoomId, _uid).once('value', snapshot => {
                //     _results = snapshot.val();
                //     // alert(_date + "|" + this.state.selectedRoomCode + "|" + _uid + "|" + _results);
                //     // if (snapshot.exists())
                //     isFetchNewPathDone = true;
                // });

                // if (!isFetchNewPathDone) {
                //     await this.personalResult_OldPath(_date, this.state.selectedRoomId, _uid).once('value', snapshot => {
                //         // if (snapshot.exists())
                //         _results = snapshot.val();
                //         // alert(data.PlayerName + "|" + _date + "|" + this.state.selectedRoomCode + "|" + _uid + "|" + _results);
                //     });
                // }

                // if (_results != null && _results.length > 0) {
                //     // hasResult = true;
                //     _resultArrayInBit = _results.split(':');
                // }
                // else {
                //     // _resultArrayInBit = new Array(this.state.QuizQuestions.length).fill('0');
                //     _resultArrayInBit = new Array(roomData.QnQty).fill('0');
                // }
                _resultArrayInBit = new Array(roomData.QnQty).fill('0');
                //#endregion


                //#region new method for calculating result. 2021.09.13
                // let selects = '';
                let selectedAnswers = [];
                // let retryAgain = false;
                // do {
                //     retryAgain = false;
                await this.personalASelectsPath(_date, this.state.selectedRoomId, _uid)
                    .once('value', snapshot => {
                        // if (snapshot.exists())
                        selectedAnswers = String(snapshot.val()).split(':');
                    });


                // //2021.11.15 - debugging.
                // if (data.Uid === 'pR6r0ysJvcZeWavyZ0uLzo35pE63') {
                //     let selectedAnswers_text = 'selectedAnswers_text\n';
                //     selectedAnswers.map((d, k) => {
                //         return selectedAnswers_text += (k + 1) + ' = ' + d + ',\n';
                //     });
                //     console.log(selectedAnswers_text);
                // }

                // selectedAnswers = selects.split(':');
                // } while (retryAgain);
                if (selectedAnswers.length > 0) {
                    for (var q = 0; q < _resultArrayInBit.length; q++) {
                        // let _finalResult = null;
                        let _selectedAnswer = '-1';
                        let _answer = '';
                        // let question = this.state.QuizQuestions[q];
                        let question = this.state.QuizQuestions[Number(pickedQs[q]) - 1];
                        // console.log(JSON.stringify(question));
                        if (question !== undefined) {
                            if (question.hasOwnProperty('SpecialMode')) {
                                if (question.SpecialMode.includes('FillInTheBlanks')) {
                                    let settings = String(question.SpecialMode).split(';');
                                    let startNo = Number(settings[1].split(',')[0]) - 1;
                                    // let endNo = Number(settings[1].split(',')[1]) - 1;
                                    // let index = key - startNo;
                                    let index = q - startNo;
                                    _answer = String(this.state.QuizQuestions[startNo].Answer).split(';')[index];
                                    // _finalResult = answer === String(String(this.state.QuizQuestions[startNo].Selection).split(';')[selectedAnswers[q]]).split(':')[0];
                                    _selectedAnswer = String(String(this.state.QuizQuestions[startNo].Selection).split(';')[Number(selectedAnswers[q])]).split(':')[0];

                                    // //2021.11.15 - debugging.
                                    // if (data.Uid === 'pR6r0ysJvcZeWavyZ0uLzo35pE63') {
                                    //     if (q > 30 && q <= 34)
                                    //         console.log(
                                    //             '(#' + (q + 1) + ')\nAns = ' + _answer + '\nSelected = ' + _selectedAnswer
                                    //             + '\nCorrect = ' + (_answer === _selectedAnswer ? 'yes' : 'no')
                                    //             + '\nkey = ' + key
                                    //             + '\nstartNo = ' + startNo
                                    //             + '\nindex = ' + index
                                    //         );
                                    // }
                                }
                                else {
                                    //Comprehension or Subjective.
                                    _answer = question.Answer;
                                    _selectedAnswer = String(String(question.Selection).split(';')[Number(selectedAnswers[q])]).split(':')[0];
                                }
                            }
                            else {
                                //normal question.
                                _answer = question.Answer;
                                _selectedAnswer = String(String(question.Selection).split(';')[Number(selectedAnswers[q])]).split(':')[0];
                            }
                            if (_answer === _selectedAnswer) {
                                _resultArrayInBit[q] = '1';
                            }
                            else {
                                _resultArrayInBit[q] = '0';
                            }
                            // console.log((q + 1) + ' = ' + _resultArrayInBit[q]);
                        }
                    }
                }
                //#endregion


                // let _rank = key.toString().includes('*') ? key.toString() : (key + 1).toString();

                // _htmlStrings += "<tr><td valign='top'>" + data.Rank + "</td><td valign='top'>" + data.PlayerName
                //     + "</td><td valign='top'>" + data.Scores + "</td><td valign='top'>"
                //     + this.convertTime(data.TimeElapsed) + " (" + data.TimeElapsed + ") " + "</td><td valign='top'>"
                //     + data.Email + "</td>";

                // if (hasResult) {
                for (var i = 0; i < _qsQty; i++) {
                    if (_resultArrayInBit[i] === '1')
                        _correctQty += 1;

                    let _result = _resultArrayInBit[i] === '1' ? '✔' : '❌';
                    // let _result = _resultArrayInBit[i] == '1' ? 1 : 0;
                    _resultArray.push({ result: _result });
                }
                // }
                // _htmlStrings += "<td valign='top'>" + _correctQty + "/" + _qsQty + " (Wrong:&nbsp;" + (_qsQty - _correctQty) + ")</td>";
                // for (var i = 0; i < _qsQty; i++) {
                //     let _result = (hasResult ? (_resultArrayInBit[i] == '1' ? '✔' : '❌') : '-');
                //     _htmlStrings += "<td align='center' valign='top'>" + _result + "</td>";

                //     //2020.07.18
                //     _resultArray.push({ _result });
                // }

                // _htmlStrings += "</tr>";

                //2020.07.18    //Storing results data in array for future reference.
                // _RankingListResults.push({
                //     // Rank: data.Rank,
                //     // PlayerName: data.PlayerName,
                //     // Scores: data.Scores,
                //     // TimeElapsed: data.TimeElapsed,
                //     // Email: data.Email,
                //     Uid: _uid,
                //     Results: _resultArray,
                // });


                //2020.12.11    //fetch data from FS > RegistrationData
                let fetch_FS_RegData_success = false;
                let _nationalState = '';
                let _school = '';
                let _isSimStudent = '';

                //2021.01.30
                let _profile = null;
                let _subject = String(this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject).toLowerCase();
                let _targetRegData = 'SIM';
                if (_subject.includes('colin'))
                    _targetRegData = 'COLIN';
                else if (_subject.includes('abacus'))
                    _targetRegData = 'SMARTNEWTON';

                //2020.12.14
                let _guardian = '';
                let _contact = '';

                //2021.11.16
                let _gender = '';
                let _race = '';

                // await this.props.dbFireStore.collection("RegistrationData")
                await this.props.dbFireStore.collection("LiveQuiz_RegData/" + _targetRegData + "/List")
                    .where('Uid', '==', data.Uid)
                    .get()
                    .then((querySnapshot) => {
                        let data = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                data.push(doc.data());
                            });
                        }
                        if (data.length > 0) {

                            _nationalState = data[0]['NationalState'];
                            _school = data[0]['School'];
                            _isSimStudent = data[0]['IsCenterStudent'];

                            //2020.12.14
                            _guardian = data[0]['Guardian'];
                            _contact = data[0]['Contact'];

                            //2021.11.16
                            _gender = data[0]['Gender'];
                            _race = data[0]['Race'];

                            fetch_FS_RegData_success = true;
                        }
                        // alert(JSON.stringify(data));
                    });

                //2021.01.30 - get from Profile (RTDB) if not found.
                if (_contact.length <= 0) {
                    await this.userProfilePath(data.Uid).once('value', snapshot => {
                        return _profile = snapshot.val();
                    });
                    // alert(JSON.stringify(_profile));
                    if (_profile !== null) {
                        _nationalState = _profile._13NationalState;
                        _school = _profile._10School;
                        _isSimStudent = _targetRegData === 'SIM' ? true : false;
                        _guardian = _profile._9Guardian;
                        _contact = _profile._8Contact;
                        fetch_FS_RegData_success = true;
                    }
                }

                //2020.07.18
                return _UpdatedFinalRankingList.push({
                    PlayerName: String(data.PlayerName),
                    Scores: Number(data.Scores),
                    TimeElapsed: Number(data.TimeElapsed),
                    ActualTimeElapsed: Number(data.ActualTimeElapsed),  //new 2020.10.19
                    IsCompleted: String(data.IsCompleted),
                    Uid: String(data.Uid),
                    Email: String(data.Email),
                    Version: Boolean(data.Version),
                    Duplicated: Boolean(data.Duplicated),
                    Qty: Number(data.Qty),
                    CorrectQty: _correctQty,
                    WrongQty: _qsQty - _correctQty,
                    Results: _resultArray,

                    //2020.12.11
                    IsSimStudent: fetch_FS_RegData_success ? _isSimStudent : '-',
                    State: fetch_FS_RegData_success ? _nationalState : '-',
                    School: fetch_FS_RegData_success ? _school : '-',

                    //2020.12.14
                    Guardian: fetch_FS_RegData_success ? (_guardian === undefined ? '' : (_guardian.length > 0 ? _guardian : '-')) : '-',
                    Contact: fetch_FS_RegData_success ? (_contact === undefined ? '' : (_contact.length > 0 ? _contact : '-')) : '-',

                    //2021.11.16
                    Gender: fetch_FS_RegData_success ? (_gender === undefined ? '' : (_gender.length > 0 ? _gender : '-')) : '-',
                    Race: fetch_FS_RegData_success ? (_race === undefined ? '' : (_race.length > 0 ? _race : '-')) : '-',
                });

                // return key;
                // }
            })
        );
        // .then((key) => {
        //     //2021.09.13 - progression state.
        //     ProgressionStateText = 'Almost done...(' + Math.round(((key + 1) / this.state.FinalRankingList.length) * 100)
        //         + '%) (' + (key + 1) + '/' + this.state.FinalRankingList.length + ')';
        // });
        // console.log(JSON.stringify(_UpdatedFinalRankingList));

        // //2021.09.13 - recount score/result
        // _UpdatedFinalRankingList = await this.ReCheckAndReCalculateScoreResult(_UpdatedFinalRankingList);

        //2021.09.13 - progression state.
        ProgressionStateText = 'Almost done...Sorting List...';

        //Sort List according to Scores & TimeElapsed in ASC
        _UpdatedFinalRankingList.sort(function (a, b) {
            if (parseInt(a.CorrectQty) > parseInt(b.CorrectQty)) {
                return -1;
            }
            if (parseInt(a.CorrectQty) < parseInt(b.CorrectQty)) {
                return 1;
            }
            if (parseFloat(a.TimeElapsed) < parseFloat(b.TimeElapsed)) {
                return -1;
            }
            if (parseFloat(a.TimeElapsed) > parseFloat(b.TimeElapsed)) {
                return 1;
            }
            if (a.PlayerName < b.PlayerName) { return -1; }
            if (a.PlayerName > b.PlayerName) { return 1; }
            return 0;
        });

        // return _htmlStrings;
        //     })
        // )
        //     .then(() => {
        _UpdatedFinalRankingList.map((data, key) => {
            // for (var kkk = 0; kkk < _UpdatedFinalRankingList.length; kkk++) {
            //     let data = _UpdatedFinalRankingList[kkk];
            //     let key = kkk;
            // _htmlStrings += "<tr><td valign='top'>" + (key + 1) + "</td><td valign='top'>" + data.PlayerName
            //     + "</td><td valign='top'>" + data.Scores + "</td><td valign='top'>"
            //     + this.convertTime(data.TimeElapsed) + " (" + data.TimeElapsed + ") " + "</td><td valign='top'>"
            //     + data.Email + "</td>";
            _htmlStrings += "<tr><td valign='top' style='background-color: " + (data.Version ? "transparent" : "lightgray") + "'>" + (key + 1) + "</td>";
            _htmlStrings += "<td valign='top'>" + data.PlayerName + "</td>";

            let _qsQty = data.CorrectQty + data.WrongQty;
            _htmlStrings += "<td valign='top'>" + data.CorrectQty + "/" + _qsQty + " (Wrong:&nbsp;" + data.WrongQty + ")</td>";

            _htmlStrings += "<td valign='top'>" + this.convertTime(data.TimeElapsed) + " (" + data.TimeElapsed + ") </td>";
            // _htmlStrings += "<td valign='top'>" + this.convertTime(data.ActualTimeElapsed) + " (" + data.ActualTimeElapsed + ") " + "</td>";

            _htmlStrings += "<td valign='top'>" + data.Scores.toFixed(2) + "</td>";

            //2021.11.16
            _htmlStrings += "<td valign='top'>" + data.Gender + "</td>";
            _htmlStrings += "<td valign='top'>" + data.Race + "</td>";

            _htmlStrings += "<td valign='top'>" + data.Email + "</td>";

            //2021.04.20
            _htmlStrings += "<td valign='top'>" + data.Contact + "</td>";

            //2020.12.11
            _htmlStrings += "<td valign='top'>" + data.IsSimStudent + "</td>";
            _htmlStrings += "<td valign='top'>" + data.State + "</td>";
            _htmlStrings += "<td valign='top'>" + data.School + "</td>";

            //2020.12.14
            _htmlStrings += "<td valign='top'>" + data.Guardian + "</td>";
            // _htmlStrings += "<td valign='top'>" + data.Contact + "</td>";    //2021.04.20 moved to center after Email.

            // let targetPlayerResults = this.state.RankingListResults.find(rlr => rlr.Uid === data.Uid).Results;
            for (var i = 0; i < _qsQty; i++) {
                let _result = (data.Results.length > 0 ? data.Results[i].result : '-');
                _htmlStrings += "<td align='center' valign='top'>" + _result + "</td>";
            }

            _htmlStrings += "</tr>";
            // }
            return null;
        });
        // })
        // .then(() => {
        _htmlStrings += "</table>";

        this.setState({
            FinalRankingList: _UpdatedFinalRankingList,
            // RankingListResults: _RankingListResults,
            Hidden_Ranking_List_With_Results: _htmlStrings,
            isFinalRankingListLoaded: true,
            isButtonEnabled: true,
            isAlmostDone: false,
        });
        // });

        // _htmlStrings += "</table>";

        // this.setState({
        //     Hidden_Ranking_List_With_Results: _htmlStrings,
        // });
        // );
        // }
    }

    convertTime = (_timeInSec) => {
        let minutes = (_timeInSec / 60).toFixed(3).split('.')[0];
        let seconds = (_timeInSec % 60).toFixed(0);
        return (minutes + ' m ' + (seconds.length < 2 ? '0' + seconds : seconds) + ' s');
    }

    //2020.07.18
    OpenResults = (_uid, _pname) => {
        this.setState({
            resultDataDetails: '',
            scrollPosition: window.pageYOffset,
        });

        // let date = moment.unix(this.state.selectedRoomId / 1000); //room id = ticks
        let date = moment(this.state.selectedDate); //room id = ticks
        let _resultDataHtml = '';
        let _qsResults = '';
        let _correctQty = 0;

        // let targetPlayerResults = this.state.RankingListResults.find(data => data.Uid == _uid).Results;

        //2020.12.11
        let targetPlayerResults = this.state.FinalRankingList.find(x => x.Uid === _uid).Results;

        if (targetPlayerResults.length > 0) {
            targetPlayerResults.map((data, key) => {
                _qsResults += "<tr><td align='right'>Q " + (key + 1) + "</td><td>"
                    + (data.result === '✔' ? '&nbsp;' + data.result : data.result) + "</td></tr>";
                // + JSON.stringify(data) + "</td></tr>";

                if (data.result === '✔')
                    _correctQty += 1;

                return null;
            });
        }

        _resultDataHtml += "<center><table cellpadding='15' border='1' bgcolor='#282c34' width='600'><tr><td colspan='3'>";
        _resultDataHtml += "<table width='100%' border='0'><tr>"
            + "<td align='center' colspan='2' height='45'><font color='yellow'>" + _pname + "</font></td></tr><tr>"
            + "<td align='left' width='50%'>Room Code: " + this.state.selectedRoomCode + "</td>"
            + "<td align='right' width='50%'>" + date.format("LL") + " (" + date.format("YYYY-MM-DD") + ")</td></tr><tr>"
            + "<td align='left'>Standard " + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Grade + "</td>"
            + "<td align='right'>" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].Subject + "</td></tr>"
            + "<tr><td align='center' colspan='2'>" + this.state.RoomDetailArrays[this.state.selectedRoomIndex].RoomTitle + "</td></tr>"
            + "<tr><td align='right'><font color='magenta'>Correct (" + _correctQty + ")</font>&nbsp;</td>"
            + "<td align='left'>&nbsp;<font color='red'>Wrong (" + (targetPlayerResults.length - _correctQty) + ")</font></td></tr>"
            + "</table>";
        _resultDataHtml += "</td></tr><tr><td align='right' width='50%'>Question</td>"
            + "<td width='50%'>Result</td></tr>";

        _resultDataHtml += _qsResults;

        _resultDataHtml += "<tr><td colspan='2' align='right'><button id='closeResultDataDetailUi' class='buttonStyleCloseUi'>Close</button></td></tr>"
        _resultDataHtml += "</table></center><p style='height:5000px'>&nbsp;</p>";

        this.setState({
            resultDataDetails: _resultDataHtml,
        });

        window.scrollTo(0, 0);
    }

    //2020.07.18
    closeResultDataDetailUi = (e) => {
        let _id = e.target.id;
        if (_id != null && _id === 'closeResultDataDetailUi') {
            this.setState({
                resultDataDetails: '',
            }, () => {
                window.scrollTo(0, this.state.scrollPosition);
            });
        }
        // else if (_id != null && _id === 'openQuestionDetail') {
        //     this.fetchQuestion(this.state.selectedQuestionId);
        // }
    }

    //2021.01.29
    BackToMenu = () => {
        // this.props.SetStage(this.props.StageMode.Begin);
        sessionStorage.setItem('redirectRequired', this.props.StageMode.Begin);
        window.location.reload();
    }

    //2021.03.01
    // SaveRoomListToFile = () => {
    SetRoomListTable = () => {
        let roomArrays = this.state.RoomDetailArrays;
        if (roomArrays.length > 0) {

            var htmls = "<table id='Room-List-table' padding={'10'}><tr>"
                + "<td>#</td><td>Room Code</td><td>Subject</td>"
                + "<td>Grade</td><td>Room</td><td>Question</td>"
                + "<td>Duration</td>"
                + "</tr>";
            roomArrays.map((data, key) => {
                if (data !== null)
                    htmls += "<tr>"
                        + "<td>" + (key + 1) + "</td><td>" + data.RoomCode + "</td><td>" + data.Subject + "</td>"
                        + "<td>" + data.Grade + "</td><td>" + data.RoomTitle + "</td><td>" + data.QnQty + "</td>"
                        + "<td>" + this.GetDurationInText(data.Duration) + "</td>"
                        + "</tr>";
                return null;
            });
            htmls += "</table>";

            this.setState({
                Hidden_RoomList_table: htmls,
            });

        }
        else {
            // alert('There is no room info to be save.');
        }
    }

    //2021.03.01
    getNameOfRoomList = () => {
        return "RoomList_" + this.state.liveQuizDate;
    }

    //2021.09.13 - Load quiz questions.
    LoadQuizQuestions = async () => {
        if (this.state.RoomDetailArrays.length <= 0 || this.state.selectedRoomIndex < 0)
            return null;

        let roomDetail = this.state.RoomDetailArrays[this.state.selectedRoomIndex];

        let _questions = [];
        let retryAgain = false;

        do {
            retryAgain = false;

            //2021.09.30 - ravamped.
            if (roomDetail.QuestionSetUniqueId !== '') {
                //fetch from new RTDB location.
                await this.getRoomQuestionPath_QuestionSet_V1(roomDetail.QuestionSetUniqueId)
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            // let questions = snapshot.val();
                            let questions = _(snapshot.val()).values().value();
                            // console.log(questions.length + '\n\n' + JSON.stringify(questions));

                            if (questions.hasOwnProperty('ExtraContent')) {
                                delete questions.ExtraContent;
                            }
                            questions = questions.filter((el) => { return (el !== null); });    //remove null or empty item.

                            _questions = questions;
                            // console.log(questions.length + '\n\n' + JSON.stringify(questions));
                        }
                    })
                    .catch(() => {
                        retryAgain = true;
                    });
            }
            else {
                //2021.09.30 === older method/questions fetch location before 2021.09.30

                await this.questionSetPath(this.state.selectedRoomIndex)
                    .once('value', snapshot => {
                        if (snapshot.exists()) {
                            // let questions = snapshot.val();
                            let questions = _(snapshot.val()).values().value();
                            // console.log(questions.length + '\n\n' + JSON.stringify(questions));

                            // let countIsQs = 0;
                            // // let countIsNotQs = 0;
                            // questions.map((data, key) => {
                            //     if (typeof (data) === 'object')
                            //         countIsQs++;
                            //     // if (typeof (data) === 'string')
                            //     //     countIsNotQs++;
                            // });
                            // // console.log('qs = ' + countIsQs);   // + '\ntext = ' + countIsNotQs);

                            questions = questions.filter((el) => { return (typeof (el) === 'object'); });

                            // if (countIsQs === this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty) {
                            _questions = questions;
                            // console.log(questions.length + '\n\n' + JSON.stringify(questions));
                            // }
                        }
                    })
                    .catch(() => {
                        retryAgain = true;
                    });
            }

        } while (retryAgain);

        this.setState({
            QuizQuestions: _questions,
        }, () => {
            // console.log(JSON.stringify(this.state.QuizQuestions));
        });
    }

    // //2021.09.13 - recount score/result
    // ReCheckAndReCalculateScoreResult = async (_UpdatedFinalRankingList) => {
    //     //Load Quiz Questions.
    //     await this.LoadQuizQuestions();

    //     //ReCalculate.
    //     for (var i = 0; i < _UpdatedFinalRankingList.length; i++) {

    //     }

    //     return _UpdatedFinalRankingList;
    // }

    render = () => {
        // var QsUiTopPos = _QsUiTopPos;
        return (
            <>
                {/* <div
                    style={{
                        position: "absolute",
                        overflow: "hidden",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        top: 0,
                        justifyContent: "center"
                    }}
                >
                    <img src={quizBg2} alt="Live Quiz" width={window.innerWidth} height={window.innerHeight} />
                </div> */}
                {/* <img src={quizBg2} style={{ position: 'absolute', top: 0, left: 0, zIndex: -1, width: window.innerWidth, height: window.innerHeight }} /> */}

                {
                    this.state.logDataDetails.length > 0 ?
                        <div
                            style={styleJSX.logsDetailUi}
                            onClick={this.closeLogsDetailUi}
                            dangerouslySetInnerHTML={{ __html: this.state.logDataDetails }}
                        />
                        : null
                }
                {
                    this.state.resultDataDetails.length > 0 ?
                        <div
                            style={styleJSX.logsDetailUi}
                            onClick={this.closeResultDataDetailUi}
                            dangerouslySetInnerHTML={{ __html: this.state.resultDataDetails }}
                        />
                        : null
                }
                {
                    this.state.isShowQuestionDetailHtml ?
                        <div
                            style={styleJSX.qsDetailUi}
                            onClick={this.closeQsDetailUi}
                            dangerouslySetInnerHTML={{ __html: this.state.singleQuestionDetailHtml }}
                        />
                        : null
                }
                <div style={styleJSX.contentStyle}>
                    <br></br>

                    <p>
                        <table border={0}>
                            <tr>
                                <td valign='middle'><div style={{ textAlign: 'center' }}>Calender</div></td>
                                <td>&nbsp;&nbsp;</td>
                                <td valign='middle'><font size={5}>Live Quiz : Data Browsing : Simple Ui</font>
                                    {/* &nbsp;&nbsp;
                                [{"JPE:" + this.state.JoinedPlayerEmails.length + "/JPA:" + this.state.JoinedPlayerArrays.length + "/RLA:" +
                                        this.state.RankingListArrays.length + "/FRL:" + this.state.FinalRankingList.length}]&nbsp;&nbsp;
                                        ]{this.state.selectedRoomId + "|" + this.state.prevSelectedRoomId}]&nbsp;&nbsp;
                                        [{this.state.dummy}] */}
                                </td>
                            </tr>
                            <tr>
                                <td valign='top'>
                                    <table border='0'>
                                        <tr><td width='350'>
                                            <Calendar
                                                onChange={this.onCalenderChange}
                                                value={this.state.selectedDate}
                                            />
                                        </td></tr>
                                        <tr><td>&nbsp;</td></tr>
                                        {
                                            this.state.RoomDetailArrays.length > 0 ?
                                                <>
                                                    <tr>
                                                        <td align="right" width='350'>
                                                            {/* <Button
                                                                variant='contained' color='primary'
                                                                style={styleJSX.buttonStyleNormal}
                                                                onClick={this.SaveRoomListToFile}
                                                            >Save Room List to File</Button> */}
                                                            <ReactHTMLTableToExcel
                                                                //2021.03.01
                                                                className="buttonStyleDownloadExcel"
                                                                table="Room-List-table"
                                                                filename={this.getNameOfRoomList()}
                                                                sheet="Sheet"
                                                                buttonText="Save Room List to File&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
                                                            <div
                                                                id='Room-List-table-hidden'
                                                                hidden={true}
                                                                dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomList_table }}
                                                            />
                                                            <br /><br />
                                                            <ReactHTMLTableToExcel
                                                                //2021.07.29
                                                                className="buttonStyleDownloadExcelSimple"
                                                                table="Room-List-TimeTable"
                                                                filename={this.GetNameOfRoomListTimeTable()}
                                                                sheet="Sheet"
                                                                buttonText="Save Room List TimeTable" />
                                                            <button
                                                                className='btn btn-primary'
                                                                style={{ width: '100%' }}
                                                                onClick={this.PreviewRoomListTimeTable}
                                                            >Preview Room List TimeTable</button>
                                                            <div
                                                                id='Room-List-TimeTable-hidden'
                                                                hidden={true}
                                                                dangerouslySetInnerHTML={{ __html: this.state.Hidden_RoomList_TimeTable }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr><td>&nbsp;</td></tr>
                                                </>
                                                : null
                                        }
                                        {
                                            this.state.RoomDetailArrays.length > 0 && this.state.selectedRoomIndex > -1
                                                && (this.state.isJoinedPlayersLoaded || this.state.isRankingListLoaded || this.state.isFinalRankingListLoaded)
                                                ?
                                                uiModeState !== UiMode.Begin || uiModeState !== UiMode.ShowRoomList ?
                                                    <>
                                                        {/* <tr><td align='right'> */}
                                                        {/* <button
                                                                style={styleJSX.buttonStyleNormal}
                                                                // style={uiModeState === UiMode.ShowRankingList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                                onClick={this.downloadFile}
                                                            >Download List</button> */}
                                                        {/* <Button
                                                                variant='contained' color='primary'
                                                                style={styleJSX.buttonStyleNormal}
                                                                // style={uiModeState === UiMode.ShowRankingList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                                onClick={this.downloadFile}
                                                            >Download List<br></br>As JSON</Button>
                                                        </td></tr> */}

                                                        <tr><td align='right'>
                                                            <div>
                                                                <ReactHTMLTableToExcel
                                                                    //2020.07.16
                                                                    className="buttonStyleDownloadExcel"
                                                                    table="Ranking-List-with-Quiz-Result"
                                                                    filename={this.getNameOfReport()}
                                                                    sheet="Sheet"
                                                                    buttonText="DOWNLOAD REPORT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; AS EXCEL &nbsp;&nbsp;&nbsp;&nbsp;" />
                                                            </div>
                                                        </td></tr>

                                                        <tr><td height={15}></td></tr>

                                                        <tr><td align='right'>
                                                            {/* <button
                                                                style={styleJSX.buttonStyleNormal}
                                                                // style={uiModeState === UiMode.ShowRankingList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                                onClick={this.PrintDiv}
                                                            // onClick={() => window.print()}
                                                            >Print List</button> */}
                                                            <Button
                                                                variant='contained' color='primary'
                                                                style={styleJSX.buttonStyleNormal}
                                                                // style={uiModeState === UiMode.ShowRankingList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                                onClick={this.PrintDiv}
                                                            // onClick={() => window.print()}
                                                            >Print List</Button>
                                                        </td></tr>

                                                        {/* <tr><td height={15}></td></tr> */}

                                                        {/* <tr><td align='right'>
                                                            <div>
                                                                <ReactHTMLTableToExcel
                                                                    //2020.07.16
                                                                    className="buttonStyleDownloadExcel"
                                                                    table="Ranking-List-with-Quiz-Result"
                                                                    filename={this.getNameOfReport()}
                                                                    sheet="Sheet"
                                                                    buttonText="DOWNLOAD REPORT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; AS EXCEL &nbsp;&nbsp;&nbsp;&nbsp;" />
                                                            </div>
                                                        </td></tr> */}
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </table>
                                </td>
                                <td>&nbsp;&nbsp;</td>
                                <td valign='top'>
                                    <table style={styleJSX.tableStyle}>
                                        <tr>
                                            <td style={styleJSX.tableTD}>
                                                DATE &#91; {this.state.selectedDate.toLocaleDateString()} &#93;
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={styleJSX.tableTD}>
                                                {/* <button
                                                style={uiModeState === UiMode.ShowRoomList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                onClick={() => this.LoadRoomListAndDetails(UiMode.ShowRoomList)}
                                            >Rooms Detail</button> */}
                                                <Button
                                                    variant='contained' color='primary'
                                                    disabled={!this.state.isButtonEnabled}
                                                    style={uiModeState === UiMode.ShowRoomList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                    onClick={() => this.LoadRoomListAndDetails(UiMode.ShowRoomList)}
                                                // onClick={() => this.LoadRoomListAndDetails()}
                                                >Rooms Detail</Button>
                                                &nbsp;&nbsp;
                                                {/* <button
                                                // style={styleJSX.buttonStyleNormal}
                                                style={uiModeState === UiMode.ShowJoinedPlayers ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                onClick={() => this.LoadRoomListAndJoinedPlayers()}
                                            >Joined Players</button> */}
                                                {
                                                    this.state.liveQuizDate <= this.state.versionDivisionDate ?
                                                        <>
                                                            <Button
                                                                variant='contained' color='primary'
                                                                disabled={!this.state.isButtonEnabled}
                                                                style={uiModeState === UiMode.ShowJoinedPlayers ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                                // onClick={() => this.LoadRoomListAndJoinedPlayers()}
                                                                onClick={() => this.LoadRoomList(UiMode.ShowJoinedPlayers)}
                                                            >Joined Players</Button>
                                                            &nbsp;&nbsp;
                                                        </>
                                                        : null
                                                }
                                                {/* &nbsp;&nbsp; */}
                                                {/* <button
                                                // style={styleJSX.buttonStyleNormal}
                                                style={uiModeState === UiMode.ShowRankingList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                onClick={() => this.LoadRoomListForRankingList()}
                                            >Ranking List</button> */}
                                                {
                                                    this.state.liveQuizDate <= this.state.versionDivisionDate ?
                                                        <>
                                                            <Button
                                                                variant='contained' color='primary'
                                                                disabled={!this.state.isButtonEnabled}
                                                                style={uiModeState === UiMode.ShowRankingList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                                // onClick={() => this.LoadRoomListForRankingList()}
                                                                onClick={() => this.LoadRoomList(UiMode.ShowRankingList)}
                                                            >Ranking List</Button>
                                                            &nbsp;&nbsp;
                                                        </>
                                                        : null
                                                }
                                                {/* &nbsp;&nbsp; */}
                                                <Button
                                                    variant='contained' color='primary'
                                                    disabled={!this.state.isButtonEnabled}
                                                    style={uiModeState === UiMode.ShowFinalRankingList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                    // onClick={() => this.LoadRoomListForFinalRankingList()}
                                                    onClick={() => this.LoadRoomList(UiMode.ShowFinalRankingList)}
                                                >Final Ranking List</Button>
                                                &nbsp;&nbsp;
                                                {/* <button
                                                style={styleJSX.buttonStyleNormal}
                                                onClick={() => window.location.reload()}
                                            >Reload Page Or Exit</button> */}
                                                <Button
                                                    variant='contained' color='secondary'
                                                    disabled={!this.state.isButtonEnabled}
                                                    style={styleJSX.buttonStyleNormal}
                                                    onClick={this.BackToMenu}
                                                >Back to Home</Button>
                                            </td>
                                        </tr>


                                        {
                                            // uiModeState === UiMode.ShowRoomList || uiModeState === UiMode.ShowJoinedPlayers || uiModeState === UiMode.ShowRankingList ?
                                            uiModeState !== UiMode.Begin ?
                                                //Rooms' List.
                                                this.state.isRoomListLoaded ?
                                                    <>
                                                        <tr><td style={styleJSX.tableTD}>Room List</td></tr>

                                                        {/* <tr>
                                                            <td style={styleJSX.tableTD}>
                                                                <div className='row'>
                                                                    <div className='col'>
                                                                        Room List
                                                                    </div>
                                                                </div>

                                                                <div className='row' style={{ marginTop: 15 }}>
                                                                    <div className='col'>

                                                                        <div className='row' style={{ maxWidth: 400, alignItems: 'center' }}>
                                                                            <div className='col-4' style={{ fontSize: 18, padding: 0, paddingLeft: 15 }}>Auto Refresh</div>
                                                                            <div className='col-4' style={{ padding: 0 }}>
                                                                                <Picker
                                                                                    selectedValue={this.state.autoRefreshTimerValue}
                                                                                    style={styleJSX.pickerStyleAutoRefresh}
                                                                                    onValueChange={(itemValue, itemIndex) =>
                                                                                        this.SetAutoRefreshTimerValue(itemValue)
                                                                                    }>
                                                                                    {
                                                                                        [10, 15, 20, 25, 30, 40, 50, 60].map((data, key) => {
                                                                                            return (
                                                                                                <Picker.Item label={data + ' seconds'} value={data} />
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Picker>
                                                                            </div>
                                                                            <div className='col-1' style={{ fontSize: 18, padding: 0 }}>({this.state.autoRefreshTimerValue - this.state.autoRefreshElapsedTime})</div>
                                                                            <div className='col-2' style={{ paddingLeft: 10 }}>
                                                                                <Switch
                                                                                    // trackColor={{ false: "#767577", true: "#81b0ff" }}
                                                                                    // thumbColor={this.state.toggleAutoRefresh ? "#f5dd4b" : "#f4f3f4"}
                                                                                    // ios_backgroundColor="#3e3e3e"
                                                                                    onValueChange={this.ToggleAutoRefresh}
                                                                                    value={this.state.toggleAutoRefresh}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </td>
                                                        </tr> */}

                                                        <tr>
                                                            <td style={styleJSX.tableTD}>
                                                                {
                                                                    this.state.RoomDetailArrays.length > 0 ?
                                                                        this.state.RoomDetailArrays.map((data, index) => {
                                                                            if (data !== null)
                                                                                return (
                                                                                    <>
                                                                                        {/* <button
                                                                                    // style={styleJSX.buttonStyleNormal}
                                                                                    // style={this.state.showRoomDetailsList ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                                                    style={index === this.state.selectedRoomIndex ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal}
                                                                                    onClick={() => this.SelectRoom(data.RoomId)}
                                                                                    id={data.RoomId}
                                                                                >{data.RoomTitle}</button>&nbsp;&nbsp; */}
                                                                                        <Button
                                                                                            variant='contained' color='primary'
                                                                                            disabled={!this.state.isButtonEnabled}
                                                                                            style={
                                                                                                index === this.state.selectedRoomIndex ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal
                                                                                            }
                                                                                            // style={
                                                                                            //     index === this.state.selectedRoomIndex ? styleJSX.buttonStyleSelected : styleJSX.buttonStyleNormal,
                                                                                            //     data.RoomTitle.length > 20 ? { fontSize: 15, height: 65 } : { fontSize: 15, height: 65 }
                                                                                            // }
                                                                                            onClick={() => this.SelectRoom(data.RoomId)}
                                                                                            id={data.RoomId}
                                                                                        >{data.RoomTitle}<br />{data.RoomCode}</Button>&nbsp;&nbsp;
                                                                                        {
                                                                                            ((index + 1) % 5) === 0 ?
                                                                                                <><p height={25}></p></>
                                                                                                : null
                                                                                        }
                                                                                    </>
                                                                                );
                                                                            else
                                                                                return null;
                                                                        })
                                                                        :
                                                                        <font style={styleJSX.tableTDwhite}>No Room is created for this day.</font>
                                                                }
                                                                &nbsp;
                                                            </td>
                                                        </tr>

                                                        {
                                                            //Room's Details.
                                                            this.state.isShowRoomDetails ?
                                                                this.state.isRoomDetailsLoaded ?
                                                                    <tr>
                                                                        <td style={styleJSX.tableTDwhite}>
                                                                            <table>
                                                                                {this.state.RoomDetails}
                                                                                {/* <table>
                                                                            <tr><td valign={'top'}>Selected Questions :</td></tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <table><tr>{this.state.RoomDetailArrays[this.state.selectedRoomIndex].Questions}</tr></table>
                                                                                    <table><tr>{
                                                                                        this.state.RoomDetailArrays[this.state.selectedRoomIndex].Questions
                                                                                            .split(';').map((data, key) => {
                                                                                                key > 0 && key % 5 === 0 ?
                                                                                                <td>{data}</td></tr><tr>
                                                                                                :
                                                                                                <td>{data}</td>
                                                                                            })
                                                                                    }</tr></table>
                                                                                </td>
                                                                            </tr>
                                                                        </table> */}
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    null
                                                                // <>
                                                                //     <tr>
                                                                //         <td>
                                                                //             <ActivityIndicator size="large" color="#fff" />
                                                                //         </td>
                                                                //     </tr>
                                                                // </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            //Joined Players List.
                                                            this.state.isShowJoinedPlayers ?
                                                                this.state.isJoinedPlayersLoaded ?
                                                                    <tr>
                                                                        <td style={styleJSX.tableTDwhite}>
                                                                            <div id='Joined-Player-List'>
                                                                                <table>
                                                                                    <tr>
                                                                                        <td colSpan='11' style={{ color: 'yellow', }}>
                                                                                            <table width={'100%'} border={0}>
                                                                                                <tr>
                                                                                                    <td valign={'middle'}>
                                                                                                        <div>
                                                                                                            Joined Player List
                                                                                                            <font size={3} color={'#ffffff'} > (Total Student: {this.state.JoinedPlayerArrays.length} persons)</font>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td align={'right'} valign={'middle'}>
                                                                                                        <div style={{
                                                                                                            width: 185, height: 25, backgroundColor: '#123456',
                                                                                                            fontSize: 12, color: '#ffffff',
                                                                                                            textAlign: 'left', padding: 5,
                                                                                                        }}
                                                                                                        >* Student with older app version</div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr style={{ color: 'white', fontWeight: 'normal', backgroundColor: 'magenta' }}>
                                                                                        <td valign='bottom' style={{ paddingLeft: 15, }}>Rank</td>
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Name</td>
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Scores</td>
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Time<br />Consumed</td>
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Is Quiz<br />Completed</td>
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Email</td>
                                                                                    </tr>
                                                                                    {
                                                                                        this.state.JoinedPlayerArrays.map((data, index) => {
                                                                                            return (
                                                                                                <tr style={{ backgroundColor: (data.Version ? 'transparent' : '#123456') }}>
                                                                                                    <td valign='top' style={{ paddingLeft: 15, }}>{index + 1}{data.Version ? '' : ' *'}</td>
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.PlayerName}</td>
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.Scores}</td>
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.TimeElapsed}</td>
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.IsCompleted === 'YES' ? data.IsCompleted : <font color={'magenta'}>{data.IsCompleted}</font>} </td>
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.Email}</td>
                                                                                                    {/* <td>
                                                                                            <button
                                                                                                // style={styleJSX.buttonStyleNormal}
                                                                                                onClick={() => this.fetchEmail(data.Uid)}
                                                                                            >Email</button>
                                                                                        </td> */}
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    // null
                                                                    <>
                                                                        <tr>
                                                                            <td height={70}>
                                                                                <ActivityIndicator size="large" color="#fff" />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            //Ranking List.
                                                            this.state.isShowRankingList ?
                                                                this.state.isRankingListLoaded ?
                                                                    <tr>
                                                                        <td style={styleJSX.tableTDwhite}>
                                                                            <div id='Ranking-List'>
                                                                                <table>
                                                                                    <tr>
                                                                                        <td colSpan='11' style={{ color: 'yellow' }}>
                                                                                            {/* Ranking List */}
                                                                                            <table width={'100%'} border={0}>
                                                                                                <tr>
                                                                                                    <td valign={'middle'}>
                                                                                                        {/* Ranking List */}
                                                                                                        <div>
                                                                                                            Ranking List
                                                                                                            <font size={3} color={'#ffffff'} > (Total Student: {this.state.RankingListArrays.length} persons)</font>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td align={'right'} valign={'middle'}>
                                                                                                        <div style={{
                                                                                                            width: 185, height: 25, backgroundColor: '#123456',
                                                                                                            fontSize: 12, color: '#ffffff',
                                                                                                            textAlign: 'left', padding: 5,
                                                                                                        }}
                                                                                                        >* Student with older app version</div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr style={{ color: 'white', fontWeight: 'normal', backgroundColor: 'magenta' }}>
                                                                                        <td valign='bottom' style={{ paddingLeft: 15, }}>Rank</td>
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Name</td>
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Scores</td>
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Time<br />Consumed</td>
                                                                                        {/* <td width={50}>&nbsp;</td>
                                                                                <td valign='bottom'>Is Quiz<br />Completed</td> */}
                                                                                        <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Email</td>
                                                                                        {/* <td width={50}>&nbsp;</td>
                                                                                        <td valign='bottom'>Duplicated<br></br>Record ({this.state.dupeQtyForFRList})</td> */}
                                                                                    </tr>
                                                                                    {
                                                                                        this.state.RankingListArrays.map((data, index) => {
                                                                                            return (
                                                                                                <tr style={{ backgroundColor: (data.Version ? 'transparent' : '#123456') }}>
                                                                                                    <td valign='top' style={{ paddingLeft: 15, }}>{index + 1}{data.Version ? '' : ' *'}</td>
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.PlayerName}</td>
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.Scores}</td>
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.TimeElapsed}</td>
                                                                                                    {/* <td>&nbsp;</td>
                                                                                            <td>{data.IsCompleted === 'YES' ? data.IsCompleted : <font color={'gray'}>{data.IsCompleted}</font>} </td> */}
                                                                                                    <td>&nbsp;</td>
                                                                                                    <td valign='top'>{data.Email}</td>
                                                                                                    {/* <td>&nbsp;</td>
                                                                                                    <td valign='top' align='center'
                                                                                                        style={{ backgroundColor: (data.Version ? data.Duplicated ? 'gray' : 'transparent' : '#123456') }}
                                                                                                    >{data.Duplicated ? '☆' : ''}</td> */}
                                                                                                    {/* <td>
                                                                                            <button
                                                                                                // style={styleJSX.buttonStyleNormal}
                                                                                                onClick={() => this.fetchEmail(data.Uid)}
                                                                                            >Email</button>
                                                                                        </td> */}
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    // null
                                                                    <>
                                                                        <tr>
                                                                            <td height={70}>
                                                                                <ActivityIndicator size="large" color="#fff" />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            //Final Ranking List.
                                                            this.state.RoomDetailArrays.length > 0 && this.state.isShowFinalRankingList ?
                                                                this.state.isFinalRankingListLoaded ?
                                                                    <>
                                                                        <tr>
                                                                            <td style={styleJSX.tableTD}>
                                                                                <div className='row' style={{ maxWidth: 400, alignItems: 'center', marginTop: 15 }}>
                                                                                    <div className='col-4' style={{ fontSize: 18, padding: 0, paddingLeft: 15 }}>Auto Refresh</div>
                                                                                    <div className='col-4' style={{ padding: 0 }}>
                                                                                        <Picker
                                                                                            selectedValue={this.state.autoRefreshTimerValue}
                                                                                            style={styleJSX.pickerStyleAutoRefresh}
                                                                                            onValueChange={(itemValue, itemIndex) =>
                                                                                                this.SetAutoRefreshTimerValue(itemValue)
                                                                                            }>
                                                                                            {
                                                                                                [10, 15, 20, 25, 30, 40, 50, 60].map((data, key) => {
                                                                                                    return (
                                                                                                        <Picker.Item label={data + ' seconds'} value={data} />
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Picker>
                                                                                    </div>
                                                                                    <div className='col-1' style={{ fontSize: 18, padding: 0 }}>({this.state.autoRefreshTimerValue - this.state.autoRefreshElapsedTime})</div>
                                                                                    <div className='col-2' style={{ paddingLeft: 10 }}>
                                                                                        <Switch
                                                                                            // trackColor={{ false: "#767577", true: "#81b0ff" }}
                                                                                            // thumbColor={this.state.toggleAutoRefresh ? "#f5dd4b" : "#f4f3f4"}
                                                                                            // ios_backgroundColor="#3e3e3e"
                                                                                            onValueChange={this.ToggleAutoRefresh}
                                                                                            value={this.state.toggleAutoRefresh}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td style={styleJSX.tableTDwhite}>
                                                                                <div id='Final-Ranking-List'>

                                                                                    <table>
                                                                                        {/* id='Ranking-List-with-Quiz-Result' */}
                                                                                        <tr>
                                                                                            <td colSpan='15' style={{ color: 'yellow' }}>
                                                                                                {/* Final Ranking List */}
                                                                                                <table width={'100%'} border={0} >
                                                                                                    <tr>
                                                                                                        <td valign={'middle'}>
                                                                                                            {/* Final Ranking List */}
                                                                                                            <div>
                                                                                                                Final Ranking List
                                                                                                                <font size={3} color={'#ffffff'} > (Total Student: {this.state.FinalRankingList.length} persons)</font>
                                                                                                                <a href="#Final-Ranking-List"><font size={1} color={'yellow'} > <u><i>focus table</i></u></font></a>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td align={'right'} valign={'middle'}>
                                                                                                            <div style={{
                                                                                                                width: 285, height: 25, backgroundColor: '#123456',
                                                                                                                fontSize: 12, color: '#ffffff',
                                                                                                                textAlign: 'left', padding: 5,
                                                                                                            }}
                                                                                                            >* Student with older app version than {this.state.versionDivision}</div>
                                                                                                            {
                                                                                                                this.state.liveQuizDate <= this.state.versionDivisionDate ?
                                                                                                                    <div style={{
                                                                                                                        width: 185, height: 25, backgroundColor: 'gray',
                                                                                                                        fontSize: 12, color: '#ffffff',
                                                                                                                        textAlign: 'left', padding: 5,
                                                                                                                    }}
                                                                                                                    >* Student with duplicated Uid</div>
                                                                                                                    : null
                                                                                                            }
                                                                                                        </td>
                                                                                                        {/* {
                                                                                                            this.state.liveQuizDate <= this.state.versionDivisionDate ?
                                                                                                                <>
                                                                                                                    <td align={'right'} valign={'middle'}>
                                                                                                                        <div style={{
                                                                                                                            width: 185, height: 25, backgroundColor: '#123456',
                                                                                                                            fontSize: 12, color: '#ffffff',
                                                                                                                            textAlign: 'left', padding: 5,
                                                                                                                        }}
                                                                                                                        >* Student with older app version</div>
                                                                                                                        <div style={{
                                                                                                                            width: 185, height: 25, backgroundColor: 'gray',
                                                                                                                            fontSize: 12, color: '#ffffff',
                                                                                                                            textAlign: 'left', padding: 5,
                                                                                                                        }}
                                                                                                                        >* Student with duplicated Uid</div>
                                                                                                                    </td>
                                                                                                                </>
                                                                                                                : null
                                                                                                        } */}
                                                                                                    </tr>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr style={{ color: 'white', fontWeight: 'normal', backgroundColor: 'magenta' }}>
                                                                                            <td valign='bottom' style={{ paddingLeft: 15, }}>Rank</td>
                                                                                            <td width={15}>&nbsp;</td>
                                                                                            <td valign='bottom'>Name</td>
                                                                                            <td width={15}>&nbsp;</td>
                                                                                            <td valign='bottom'>Result</td>
                                                                                            <td width={15}>&nbsp;</td>
                                                                                            <td valign='bottom' width={175}>Time Consumed</td>
                                                                                            {/* <td width={50}>&nbsp;</td>
                                                                                <td valign='bottom'>Is Quiz<br />Completed</td> */}
                                                                                            <td width={15}>&nbsp;</td>
                                                                                            <td valign='bottom'>Scores</td>
                                                                                            {/* <td width={15}>&nbsp;</td>
                                                                                            <td valign='bottom'>Contact</td> */}
                                                                                            <td width={15}>&nbsp;</td>
                                                                                            <td valign='bottom'>Email</td>
                                                                                            {
                                                                                                this.state.liveQuizDate <= this.state.versionDivisionDate ?
                                                                                                    <>
                                                                                                        <td width={15}>&nbsp;</td>
                                                                                                        <td valign='bottom'>Duplicated<br></br>Uid ({this.state.dupeQtyForFRList})</td>
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                this.state.liveQuizDate > this.state.versionDivisionDate ?
                                                                                                    <>
                                                                                                        <td width={15}>&nbsp;</td>
                                                                                                        <td valign='bottom' align='center'>Logs</td>
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                this.state.liveQuizDate > this.state.versionDivisionDate ?
                                                                                                    <>
                                                                                                        {/* <td width={50}>&nbsp;</td> */}
                                                                                                        <td valign='bottom' align='center'>Results</td>
                                                                                                    </>
                                                                                                    : null
                                                                                            }
                                                                                        </tr>
                                                                                        {
                                                                                            this.state.FinalRankingList.map((data, index) => {
                                                                                                return (
                                                                                                    // <tr style={{ backgroundColor: (data.Version ? data.Duplicated ? 'gray' : 'transparent' : '#123456') }}>
                                                                                                    // <tr style={{ backgroundColor: (data.Version ? (data.IsWeb ? 'transparent' : '#123456') : '#123456') }}>
                                                                                                    <tr style={{ backgroundColor: (data.Version ? 'transparent' : '#123456') }}>
                                                                                                        <td valign='top' align='center' style={{ paddingLeft: 15, }}>{index + 1}{data.Version ? '' : ' *'}</td>
                                                                                                        <td>&nbsp;</td>
                                                                                                        <td valign='top'>{data.PlayerName}</td>
                                                                                                        <td>&nbsp;</td>
                                                                                                        {/* <td valign='top'>{data.Scores}</td> */}
                                                                                                        <td valign='top'>{data.CorrectQty}/{this.state.RoomDetailArrays[this.state.selectedRoomIndex].QnQty}&nbsp;(<font color='red'><b>{data.WrongQty}</b></font>)</td>
                                                                                                        <td>&nbsp;</td>
                                                                                                        <td valign='top'>
                                                                                                            {
                                                                                                                // moment(this.state.selectedDate) < moment('20201019') ?
                                                                                                                //     this.convertTime(data.TimeElapsed) + " (" + data.TimeElapsed + ") " //older pattern
                                                                                                                //     :
                                                                                                                //     this.convertTime(data.ActualTimeElapsed) + " (" + data.ActualTimeElapsed + ") " //new pattern on 2020.10.19+

                                                                                                                //direct use    //2021.01.19
                                                                                                                String(this.convertTime(data.TimeElapsed) + " (" + data.TimeElapsed + ") ")
                                                                                                            }
                                                                                                        </td>
                                                                                                        {/* <td>&nbsp;</td>
                                                                                            <td>{data.IsCompleted === 'YES' ? data.IsCompleted : <font color={'gray'}>{data.IsCompleted}</font>} </td> */}
                                                                                                        <td>&nbsp;</td>
                                                                                                        <td valign='top'>{Number(data.Scores)}</td>
                                                                                                        {/* <td>&nbsp;</td>
                                                                                                        <td valign='top'>{data.Contact}</td> */}
                                                                                                        <td>&nbsp;</td>
                                                                                                        {/* <td valign='top'>{data.Email}</td> */}
                                                                                                        <td valign='top'>{String(data.Email).length > 20 ? String(data.Email).substring(0, 20) + '...' : data.Email}</td>
                                                                                                        {
                                                                                                            this.state.liveQuizDate <= this.state.versionDivisionDate ?
                                                                                                                <>
                                                                                                                    <td>&nbsp;</td>
                                                                                                                    <td valign='top' align='center'
                                                                                                                        style={{ backgroundColor: (data.Version ? data.Duplicated ? 'gray' : 'transparent' : '#123456') }}
                                                                                                                    >{data.Duplicated ? '☆' : ''}</td>
                                                                                                                </>
                                                                                                                : null
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.liveQuizDate > this.state.versionDivisionDate ?
                                                                                                                <>
                                                                                                                    <td width={50}>&nbsp;</td>
                                                                                                                    <td valign='top'>
                                                                                                                        <Button
                                                                                                                            variant='contained' color='primary'
                                                                                                                            style={styleJSX.buttonStyleLog}
                                                                                                                            onClick={() => this.OpenLogs(data.Uid, data.PlayerName, index + 1, data.Email)}
                                                                                                                            disabled={this.state.isFinalRankingListLoaded === false}
                                                                                                                        >...</Button>
                                                                                                                    </td>
                                                                                                                </>
                                                                                                                : null
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.liveQuizDate > this.state.versionDivisionDate ?
                                                                                                                <>
                                                                                                                    {/* <td width={50}>&nbsp;</td> */}
                                                                                                                    <td valign='top'>
                                                                                                                        <Button
                                                                                                                            variant='contained' color='primary'
                                                                                                                            style={styleJSX.buttonStyleLog}
                                                                                                                            onClick={() => this.OpenResults(data.Uid, data.PlayerName)}
                                                                                                                            disabled={this.state.isFinalRankingListLoaded === false}
                                                                                                                        >...</Button>
                                                                                                                    </td>
                                                                                                                </>
                                                                                                                : null
                                                                                                        }
                                                                                                    </tr>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </table>
                                                                                    <div
                                                                                        id='Ranking-List-with-Quiz-Result_hidden'
                                                                                        hidden={true}
                                                                                        dangerouslySetInnerHTML={{ __html: this.state.Hidden_Ranking_List_With_Results }}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                    :
                                                                    // null
                                                                    <>
                                                                        <tr>
                                                                            <td height={70}>
                                                                                <ActivityIndicator size="large" color="#fff" />
                                                                            </td>
                                                                        </tr>
                                                                        <p style={{ fontSize: 20, color: 'white', width: '100%', textAlign: 'center', marginTop: 15, }}><div dangerouslySetInnerHTML={{ __html: ProgressionStateText }} /></p>
                                                                        {
                                                                            // this.state.isAlmostDone ?
                                                                            //     <p style={{ fontSize: 20, color: 'white', width: '100%', textAlign: 'center', marginTop: 15, }}>Almost done... just a little bit more...<br></br>Sorting list right now...</p>
                                                                            //     :
                                                                            //     <p style={{ fontSize: 20, color: 'white', width: '100%', textAlign: 'center', marginTop: 15, }}><div dangerouslySetInnerHTML={{ __html: this.state.ProgressionState }} /></p>
                                                                        }
                                                                    </>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <tr>
                                                            <td style={styleJSX.tableTD}>
                                                                <ActivityIndicator size="large" color="#fff" />
                                                            </td>
                                                        </tr>
                                                    </>
                                                :
                                                null
                                        }

                                    </table>
                                </td>
                            </tr>
                        </table>
                    </p>

                    {/* <p>End of list</p> */}
                </div>

            </>
        )
    }
}

export default QuizDataBrowsing

const styleJSX = {
    tableStyle: {
        color: 'yellow',
        fontSize: 20,
        // width: 1000,
        // backgroundColor: '#2e2e2e',
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        // padding: 50,
        // opacity: 0.5,
    },
    tableTD: {
        padding: 10,
    },
    tableTDwhite: {
        padding: 10,
        color: 'white',
    },
    contentStyle: {
        // backgroundColor: '#000',
        backgroundColor: 'transparent',
        // width: '100vh',
        height: '2000vh',
        // margin: 50,
    },
    buttonStyleLog: {
        width: 80, //185,
        height: 25,
        borderRadius: 5,    //15,
        fontSize: 15,
    },
    buttonStyleNormal: {
        width: 215, //185,
        // height: 65,
        height: 85,
        borderRadius: 5,    //15,
        fontSize: 15,
    },
    buttonStyleSelected: {
        width: 215, //185,
        // height: 65,
        height: 85,
        borderRadius: 5,    //15,
        color: '#fff',
        backgroundColor: 'red',
        fontSize: 15,
    },
    qsDetailUi: {
        position: 'absolute',
        zIndex: '10',
        // color: 'yellow',
        fontSize: 20,
        width: '100%',
        // height: '100%',
        // backgroundColor: '#5d5d5d',
        backgroundColor: '#282c34',
        padding: 50,
        // justifyContent: 'center',
        // alignItems: 'center',
        // top: _QsUiTopPos,
    },
    qsDetailTable: {
        width: '100%',
    },
    logsDetailUi: {
        position: 'absolute',
        zIndex: '10',
        fontSize: 20,
        width: '100%',
        // height: '100%',
        // backgroundColor: 'transparent',    
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 50,
    },
    pickerStyle: {
        height: 30,
        width: 200,
        fontSize: 18,
        fontWeight: 'bold',
        // alignSelf: 'flex-start',
    },
    pickerStyleAutoRefresh: {
        height: 30,
        width: 120,
        fontSize: 18,
        fontWeight: 'bold',
        // alignSelf: 'flex-start',
    },
}

const UiMode = {
    Begin: 'begin',
    ShowRoomList: 'showRoomDetailsList',
    ShowJoinedPlayers: 'showJoinedPlayers',
    ShowRankingList: 'showRankingList',
    ShowFinalRankingList: 'showFinalRankingList'
}
let uiModeState = UiMode.Begin;
// PkQuizRankingList.js

import React, { Component } from 'react';
// import ItemInfo from './ItemInfo';
import _ from 'lodash';
import moment from 'moment';

let rankingNodes;
let app;
// let rankingList = [];
class PkQuizRankingLive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Rankings: [],
            RankingList: [],
            isloaded: false,
            todayDate: 0,
        };
        // let app = this.props.db.database().ref('pkquiz-ranking/' + this.props.roomid);
        // app = this.props.db.database().ref('pkquiz-ranking-live/217879');
        app = this.props.db.database().ref(this.props.isTest + 'pkquiz-ranking-live/' + this.props.roomid);
        // app.on('value', snapshot => {
        //     this.processData(snapshot.val());
        // });
    }

    componentDidMount() {
        this.setState({
            todayDate: moment(new Date()).format('YYYYMMDD'),
        });
        app.on('value', snapshot => {
            this.processData(snapshot.val());
        });
    }

    componentWillUnmount() {
        app.off('value');
    }

    getName(_value) {
        return _value.split(';')[2];    //Uid : Scores;Time;Name;TRUE;
    }

    processData(values) {
        let ranksVal = values;
        // let courses = _(coursesVal)
        //     .keys()
        //     .map(courseKey => {
        //         let cloned = _.clone(coursesVal[courseKey]);
        //         cloned.key = courseKey;
        //         return cloned;
        //     })
        //     .value();
        let rankKeys = _(ranksVal).keys().value();
        let rankValues = _(ranksVal).values().value();
        let ranksList = [];
        let rankingList = [];
        rankKeys.map((data, key) => {
            // ranksList.push({ key: data, score: rankValues[key].split(';')[0], time: rankValues[key].split(';')[1], uid: rankValues[key].split(';')[2], isDone: rankValues[key].split(';')[3] });
            //// rankingList.push({ name: data, score: rankValues[key].split(';')[0], time: rankValues[key].split(';')[1], uid: rankValues[key].split(';')[2], isDone: rankValues[key].split(';')[3] });
            ranksList.push({ key: rankValues[key].split(';')[2], score: rankValues[key].split(';')[0], time: rankValues[key].split(';')[1], uid: data, isDone: rankValues[key].split(';')[3] });
            return null;
        });

        //sort by score, then by time (do not change sort formula).
        ranksList.sort(function (a, b) {
            if (a.score === b.score) {
                if (a.time < b.time) {
                    return a.time - b.time;
                }
            }
            else {
                return b.score - a.score;
            }
            return null;
        });

        //set ranking list
        // let textValues = "";
        ranksList.map((data, key) => {
            rankingList.push({ rank: (key + 1).toString(), pname: data.key, score: data.score, time: data.time, uid: data.uid });
            // ranksList.push({ key: data, score: rankValues[key].split(';')[0], time: rankValues[key].split(';')[1], uid: rankValues[key].split(';')[2], isDone: rankValues[key].split(';')[3] });
            // rankingList.push({ name: data, score: rankValues[key].split(';')[0], time: rankValues[key].split(';')[1], uid: rankValues[key].split(';')[2], isDone: rankValues[key].split(';')[3] });
            return null;
        });

        //save sorted list.
        this.setState({
            Rankings: ranksList,
            RankingList: rankingList,
        });

        //draw ui.
        if (this.props.stageMode !== 'htmlRanking') {
            rankingNodes = this.state.Rankings.map((data, key) => {

                return (
                    <div className="card">
                        <div className="card-content">
                            <table style={styleJSX.tableStyle}>
                                {/* <ItemInfo itemKey={data.key} itemData={data.score} /> */}
                                <tr>
                                    <td style={{ width: '5%' }}>{key + 1}.</td>
                                    <td style={{ width: '45%' }}>{unescape(data.key)}</td>
                                    <td style={{ width: '15%' }}>{data.score}</td>
                                    <td style={{ width: '35%' }}>{data.isDone === 'TRUE' ? '(Completed)' : '(In Progress...)'}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                )
            });

            //show ui.
            this.setState({
                isloaded: true
            });
        }
        else {
            //HtmlRanking Live Page - LiveView
            //But do nothing here.
        }

        //Push back players list to App.js
        // if (this.props.stageMode == 'htmlRanking') {
        //     this.props.callback(rankingList);
        // }

        //update live-ranking on firebase
        this.UpdateLiveRankingOnFirebase();

        if (this.state.todayDate <= 20200630) {
            this.UpdatePersonalRankingOnFirebase();
        }
    }

    UpdateLiveRankingOnFirebase() {
        this.state.Rankings.map((data, key) => {
            this.props.db.database().ref(this.props.isTest + 'pkquiz-ranking/' + this.props.roomid + '/' + (key + 1).toString())
                .set(
                    "" + unescape(data.key.toString()) + ";" + data.score.toString() + ";" + data.time.toString() + ";" + data.uid.toString() + ";"
                );
            return null;
        });
    }

    UpdatePersonalRankingOnFirebase() {
        this.state.RankingList.map((data, key) => {
            this.props.db.database().ref(this.props.isTest + 'pkquiz-personal-data/' + data.uid + '/' + this.props.roomid)
                .set(
                    //"scores;timeSpent;rankPos;"
                    "" + data.score + ";" + data.time + ";" + data.rank + ";"
                );
            return null;
        });
    }

    render() {
        if (this.props.stageMode !== 'htmlRanking') {
            return (
                <div>
                    <p>Live Quiz Ranking List</p>
                    <p>
                        {
                            this.state.isloaded ? rankingNodes : null
                        }
                    </p>
                    <p>End of list</p>
                </div>
            )
        }
        else {
            return (
                <body>
                    <div class="main-wrapper current-ranking">
                        <div class="bg-image">
                            <div class="page-content h-100 d-inline-block" style={styleJSX.joinedStyle}>
                                <div class="page-wrapper container-fluid h-100 d-inline-block">
                                    <div class="row">
                                        <div class="columns col-lg-2 col-md-2 col-12">
                                            <div class="title">Room Code <br></br> {this.state.roomCode} </div>
                                        </div>
                                        <div class="columns col-lg-8 col-md-8 col-12 text-center">
                                            <h1>Current Ranking</h1>
                                        </div>
                                        <div class="columns col-lg-2 col-md-2 col-12">
                                            <div class="countdown">
                                                {/* 00:00 */}
                                                {this.state.countdownText}
                                            </div>
                                        </div>

                                        {
                                            this.state.isPlayerListLoaded === true
                                                ?
                                                <>
                                                    <div class="columns col-lg-12">
                                                        <div class="top-player-ranking">
                                                            <div class="wrapper">
                                                                <div class="top-ranking top">
                                                                    <div class="row">
                                                                        <div class="columns col-lg-6">1. {this.state.Rankings.length > 0 ? this.state.Rankings[0].pname : ''}</div>
                                                                        <div class="columns col-lg-6 text-right score">{this.state.Rankings.length > 0 ? this.state.Rankings[0].score : '0'} pts</div>
                                                                    </div>
                                                                </div>
                                                                <div class="top-ranking">
                                                                    <div class="row">
                                                                        <div class="columns col-lg-6">2. {this.state.Rankings.length > 1 ? this.state.Rankings[1].pname : ''}</div>
                                                                        <div class="columns col-lg-6 text-right score">{this.state.Rankings.length > 1 ? this.state.Rankings[1].score : '0'} pts</div>
                                                                    </div>
                                                                </div>
                                                                <div class="top-ranking">
                                                                    <div class="row">
                                                                        <div class="columns col-lg-6">3. {this.state.Rankings.length > 2 ? this.state.Rankings[2].pname : ''}</div>
                                                                        <div class="columns col-lg-6 text-right score">{this.state.Rankings.length > 2 ? this.state.Rankings[2].score : '0'} pts</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="columns col-lg-12">
                                                        <div class="ranking-player">
                                                            <div class="row">

                                                                {
                                                                    this.state.Rankings.length > 3 ?
                                                                        this.state.Rankings.map((data, key) => {
                                                                            if (key > 2) {
                                                                                return (
                                                                                    <div class="columns col-lg-3 col-sm-4 col-6">{key + 1}. {data.pname}</div>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })
                                                                        : null
                                                                }

                                                                {/* <div class="columns col-lg-3 col-sm-4 col-6">4. LIM JIE YEE</div>
                                <div class="columns col-lg-3 col-sm-4 col-6">5. LIM JIE YEE</div>
                                <div class="columns col-lg-3 col-sm-4 col-6">6. LIM JIE YEE</div>
                                <div class="columns col-lg-3 col-sm-4 col-6">7. LIM JIE YEE</div>
                                <div class="columns col-lg-3 col-sm-4 col-6">8. LIM JIE YEE</div>
                                <div class="columns col-lg-3 col-sm-4 col-6">9. LIM JIE YEE</div>
                                <div class="columns col-lg-3 col-sm-4 col-6">10. LIM JIE YEE</div>
                                <div class="columns col-lg-3 col-sm-4 col-6">11. LIM JIE YEE</div> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row footer">
                                                        <div class="columns col-lg-6 col-sm-6 col-6 text-left">
                                                            <img src="images/footer-left.png" alt="" />
                                                        </div>
                                                        <div class="columns col-lg-6 col-sm-6 col-6 text-right">
                                                            <img src="images/footer-right.png" alt="" />
                                                        </div>
                                                    </div>

                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            )
        }
    }
}

export default PkQuizRankingLive

const styleJSX = {
    tableStyle: {
        backgroundColor: '#000',
        color: 'yellow',
        fontSize: 20,
        width: 600,
        'text-align': 'left',
        paddingLeft: 5,
    },
    // nameCellStyle: {
    //     width: '75%'
    // },
    // scoreCellStyle: {
    //     width: '25%'
    // }
}
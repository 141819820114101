// PkQuizRankingList.js

import React, { Component } from 'react';
// import ItemInfo from './ItemInfo';
import _ from 'lodash';
let rankingNodes;
let app;
// let textValues;
class PkQuizRankingList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Rankings: [],
            isloaded: false
        };
        // let app = this.props.db.database().ref('pkquiz-ranking/' + this.props.roomid);
        // app = this.props.db.database().ref('pkquiz-ranking-live/217879');
        // app = this.props.db.database().ref(this.props.isTest + 'pkquiz-ranking/' + this.props.roomid);
        // app.on('value', snapshot => {
        //     this.processData(snapshot.val());
        // });

        //New Pattern = 2020.06.30
        app = this.props.db.database().ref(this.props.isTest + 'pkquiz-ranking-live/' + this.props.roomid);
    }

    componentDidMount() {
        app.once('value', snapshot => {
            this.processData(snapshot.val());
        });
    }

    componentWillUnmount() {
        app.off('value');
    }

    getName(_value) {
        return _value.split(';')[2];    //Uid : Scores;Time;Name;TRUE;
    }

    processData(values) {
        let ranksVal = values;
        // let courses = _(coursesVal)
        //     .keys()
        //     .map(courseKey => {
        //         let cloned = _.clone(coursesVal[courseKey]);
        //         cloned.key = courseKey;
        //         return cloned;
        //     })
        //     .value();
        let rankKeys = _(ranksVal).keys().value();
        var rankValues = _(ranksVal).values().value();
        let ranksList = [];
        // let ranksListSorted = [];
        var textValues;

        // rankKeys.map((data, key) => {
        //     // ranksList.push({ debug: rankValues[key] });
        //     // Jay Chuah;2100;6.9;DQJGzxZZQnaP9aLGcqdJ6FP03JD3;
        //     if (key > 0) {
        //         textValues = (rankValues[key] + "").split(';');
        //         // ranksList.push({ rank: key.toString(), pname: textValues[0], score: textValues[1], time: textValues[2], uid: textValues[3] });
        //         //// ranksList.push({ rank: data, pname: rankValues[key].split(';')[0], score: rankValues[key].split(';')[1], uid: rankValues[key].split(';')[2] });
        //         ranksList.push({ rank: key.toString(), pname: textValues[0], score: textValues[1], time: textValues[2], uid: textValues[3] });
        //     }
        // });

        //New Pattern = 2020.06.30
        rankKeys.map((data, key) => {
            // uid , score , time , name , is completed
            if (key > 0) {
                textValues = (rankValues[key] + "").split(';');
                ranksList.push({
                    key: data,
                    rank: key,
                    score: textValues[0],
                    time: textValues[1],
                    uid: data,
                    pname: textValues[2]
                });
            }
            return null;
        });

        //New Pattern = 2020.06.30
        //sort by score, then by time (do not change sort formula).
        ranksList.sort(function (a, b) {
            if (a.score === b.score) {
                if (a.time < b.time) {
                    return a.time - b.time;
                }
            }
            else {
                return b.score - a.score;
            }
            return null;
        });

        ranksList.map((data, key) => {
            // uid , score , time , name , is completed
            return ranksList[key].rank = (key + 1).toString();
        });

        //save sorted list.
        this.setState({
            Rankings: ranksList
        });

        //draw ui.
        rankingNodes = this.state.Rankings.map((data, key) => {
            if (this.props.stageMode !== 'htmlResult') {
                return (
                    <div className="card">
                        <div className="card-content">
                            <table style={styleJSX.tableStyle}>
                                <tr id={key}>
                                    <td style={{ width: '15%' }}>{data.rank}.</td>
                                    <td style={{ width: '65%' }}>{unescape(data.pname)}</td>
                                    <td style={{ width: '20%' }}>{data.score}</td>
                                    {/* <td style={{ width: '15%' }}>{data.debug}</td> */}
                                </tr>
                            </table>
                        </div>
                    </div >
                )
            }
            else {
                //HtmlRanking Result Page - LiveView
                //But do nothing here.
            }
            return null;
        });

        //show ui.
        this.setState({
            isloaded: true
        });

        //Push back players list to App.js
        //

        //update personal-ranking on firebase
        // this.UpdatePersonalRankingOnFirebase();
    }

    // UpdatePersonalRankingOnFirebase() {
    //     this.state.Rankings.map((data, key) => {
    //         this.props.db.database().ref(this.props.isTest + 'pkquiz-personal-data/' + data.uid + '/' + this.props.roomid)
    //             .set(
    //                 //"scores;timeSpent;rankPos;"
    //                 "" + data.score + ";" + data.time + ";" + data.rank + ";"
    //             )
    //     });
    // }

    render() {
        if (this.props.stageMode !== 'htmlRanking') {
            return (
                <div>
                    <p>Live Quiz<br></br>Final Ranking</p>
                    <p>
                        {
                            this.state.isloaded ? rankingNodes : null
                        }
                    </p>
                    <p>End of list</p>
                </div>
            )
        }
    }
}

export default PkQuizRankingList

const styleJSX = {
    tableStyle: {
        backgroundColor: '#000',
        color: 'yellow',
        fontSize: 20,
        width: 600,
        'text-align': 'left',
        paddingLeft: 5,
    },
    // nameCellStyle: {
    //     width: '75%'
    // },
    // scoreCellStyle: {
    //     width: '25%'
    // }
}